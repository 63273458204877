import { createSlice, createSelector } from "@reduxjs/toolkit";

import { apiRequestStarted } from "./apiActions";

const slice = createSlice({
  name: "general",
  initialState: {
    teams: [],
  },
  reducers: {
    teamsLoaded: (state, { payload }) => {
      state.teams = payload;
    },
  },
});

const { teamsLoaded } = slice.actions;

export const loadTeams = (contractId, callback) =>
  apiRequestStarted({
    url: `/api/contracts/team/${contractId}`,
    onSuccessCallback: callback,
    onSuccess: teamsLoaded.type,
  });

export const saveTeams = (contractId, data, callback) =>
  apiRequestStarted({
    url: `/api/contracts/saveteam/${contractId}`,
    method: "post",
    data,
    onSuccessCallback: callback,
  });

export const getUserOpitons = (callback) =>
  apiRequestStarted({
    url: `/api/userinfo/users`,
    onSuccessCallback: callback,
  });

export const getItems = (items) =>
  items.map((a) => ({
    ...a,
    name: trimVal(a.name),
    officeNumber: trimVal(a.officeNumber),
    faxNumber: trimVal(a.faxNumber),
  }));

const trimVal = (val) => (val ? val.trim() : val);

export default slice.reducer;
