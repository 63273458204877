import { API_ROOT } from "../api_endpoints";
import { acquireTokenPopupAndCallAPI } from "../authentication";

const GOT_RECYCLES = "GOT_RECYCLES";
const GOT_RECYCLEPLANS = "GOT_RECYCLEPLANS";

const gotRecycles = Recycles => ({
  type: GOT_RECYCLES,
  Recycles
});

export const getRecycles = contractId => async dispatch => {
  try {
    const response = await acquireTokenPopupAndCallAPI(token =>
      fetch(`${API_ROOT.recycles}/${contractId}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
    );
    const recycles = await response.json();

    dispatch(gotRecycles(recycles));
  } catch (error) {
    console.error(error);
  }
};

export const addRecycle = (RecycleObj) => async dispatch => {
  try {
    await acquireTokenPopupAndCallAPI(token =>
      fetch(`${API_ROOT.recycle}`, {
        method: "POST",
        body: JSON.stringify(RecycleObj),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        }
      })
    );
    dispatch(getRecycles(RecycleObj.contractId));
  } catch (error) {
    console.error(error);
  }
};

export const editRecycle = (RecycleId, patchObj) => async dispatch => {
  try {
    await acquireTokenPopupAndCallAPI(token =>
      fetch(`${API_ROOT.recycle}`, {
        method: "PUT",
        body: JSON.stringify(patchObj),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        }
      })
    );
    dispatch(getRecycles(patchObj.contractId));
  } catch (error) {
    console.error(error);
  }
};

const gotRecyclePlans = RecyclePlans => ({
  type: GOT_RECYCLEPLANS,
  RecyclePlans
});

export const getRecyclePlans = contractId => async dispatch => {
  try {
    const response = await acquireTokenPopupAndCallAPI(token =>
      fetch(`${API_ROOT.recyclePlans}/${contractId}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
    );
    const recycleplans = await response.json();

    dispatch(gotRecyclePlans(recycleplans));
  } catch (error) {
    console.error(error);
  }
};

export const addRecyclePlan = (RecyclePlanObj) => async dispatch => {
  try {
    await acquireTokenPopupAndCallAPI(token =>
      fetch(`${API_ROOT.recyclePlan}`, {
        method: "POST",
        body: JSON.stringify(RecyclePlanObj),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        }
      })
    );
    dispatch(getRecyclePlans(RecyclePlanObj.contractId));
  } catch (error) {
    console.error(error);
  }
};

export const editRecyclePlan = (RecyclePlanId, patchObj) => async dispatch => {
  try {
    await acquireTokenPopupAndCallAPI(token =>
      fetch(`${API_ROOT.recyclePlan}`, {
        method: "PUT",
        body: JSON.stringify(patchObj),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        }
      })
    );
    dispatch(getRecyclePlans(patchObj.contractId));
  } catch (error) {
    console.error(error);
  }
};

const initialState = {
  Recycles: [],
  RecyclePlans: [],
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GOT_RECYCLES:
      const { Recycles } = action;
      return { ...state, Recycles };
    case GOT_RECYCLEPLANS:
      const { RecyclePlans } = action;
      return { ...state, RecyclePlans };
    default:
      return state;
  }
}
