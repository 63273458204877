import { createSlice } from '@reduxjs/toolkit';
import { fetchWithToken } from '../authentication';
import {
  getPaymentsByContractIdUrl,
  getPaymentByPaymentIdUrl,
  addPaymentUrl,
  editPaymentByPaymentIdUrl,
  postPaymentByPaymentIdUrl,
  finalizePaymentByPaymentIdUrl,
  editPaymentBondChargeCodeByPaymentIdUrl,
  addClassifiedPaymentUrl,
  editClassifiedPaymentUrl,
  addWorkOrderPaymentUrl,
  editWorkOrderPaymentUrl,
  addUnclassifiedPaymentUrl,
  editUnclassifiedPaymentUrl,
  addPerformbondPaymentUrl,
  editPerformbondPaymentUrl,
  addNetcostPaymentUrl,
  editNetcostPaymentUrl,
  addChangeorderPaymentUrl,
  editChangeorderPaymentUrl,
  addFieldorderPaymentUrl,
  editFieldorderPaymentUrl,
  addSupplementalPaymentUrl,
  editSupplementalPaymentUrl,
  updateRetainageUrl,
  updateFinancialHistoryUrl,
  getClassifiedPaymentInfoUrl,
  getUnclassifiedPaymentInfoUrl,
  getPerformbondPaymentInfoUrl,
  getFieldorderPaymentInfoUrl,
  getNetcostPaymentInfoUrl,
  getSupplementalPaymentInfoUrl,
  getChangeorderPaymentInfoUrl,
  getWorkorderPaymentsUrl,
  unpostPaymentByPaymentIdUrl
} from '../api_endpoints';
import { updatedContractFinancialHistory, getSingleContract} from './contract';

const paymentSlice = createSlice({
  name: 'payments',
  initialState: {
    payments: [],
    selectedPayment: null,
    selectedRecentlyUpdated: false, // selectedPayment recently changed and route may need to be synchronized
    addPaymentStatus: 'IDLE',
    addPaymentError: null,
    classifiedPayments: [],
    classifiedEntries: [],
    lumpsumPayments: [],
    lumpsumEntries: [],
    performbondPayments: [],
    performbondEntries: [],
    fieldorderPayments: [],
    fieldorderEntries: [],
    netcostPayments: [],
    netcostEntries: [],
    supplementalPayments: [],
    supplementalEntries: [],
    changeorderPayments: [],
    changeorderEntries: [],
    workorderPayments: []
  },
  reducers: {
    gotPayments(state, action) {
      state.payments = action.payload;
    },
    gotSelectedPayment(state, action) {
      state.selectedPayment = action.payload;
    },
    addedSelectedPayment(state, action) {
      state.addPaymentStatus = 'SUCCESS';
      state.addPaymentError = null;
      state.selectedPayment = action.payload;
      state.selectedRecentlyUpdated = true;
    },
    clearSelectedPayment(state) {
      state.selectedPayment = null;
    },
    clearSelectedRecentlyUpdated(state) {
      state.selectedRecentlyUpdated = false;
    },
    clearAllPaymentData(state) {
      state.payments = [];
      state.selectedPayment = null;
      state.selectedRecentlyUpdated = false;
      state.addPaymentStatus = 'IDLE';
      state.addPaymentError = null;
    },
    gotClassifiedPaymentInfo(state, action) {
      const { classifiedPayments, classifiedEntries, chargecodeEntries} = action.payload;
      state.classifiedPayments = classifiedPayments;
      state.classifiedEntries = classifiedEntries;
      state.chargecodeEntries = chargecodeEntries;
    },
    clearClassifiedPaymentInfo(state) {
      state.classifiedPayments = [];
      state.classifiedEntries = [];
    },
    gotUnclassifiedPaymentInfo(state, action) {
      const { lumpsumPayments, lumpsumEntries } = action.payload;
      state.lumpsumPayments = lumpsumPayments;
      state.lumpsumEntries = lumpsumEntries;
    },
    clearUnclassifiedPaymentInfo(state) {
      state.lumpsumPayments = [];
      state.lumpsumEntries = [];
    },
    gotPerformbondPaymentInfo(state, action) {
      const { performbondPayments, performbondEntries } = action.payload;
      state.performbondPayments = performbondPayments;
      state.performbondEntries = performbondEntries;
    },
    clearPerformbondPaymentInfo(state) {
      state.performbondPayments = [];
      state.performbondEntries = [];
    },
    gotFieldorderPaymentInfo(state, action) {
      const { fieldorderPayments, fieldorderEntries } = action.payload;
      state.fieldorderPayments = fieldorderPayments;
      state.fieldorderEntries = fieldorderEntries;
    },
    clearFieldorderPaymentInfo(state) {
      state.fieldorderPayments = [];
      state.fieldorderEntries = [];
    },
    gotNetcostPaymentInfo(state, action) {
      const { netcostPayments, netcostEntries } = action.payload;
      state.netcostPayments = netcostPayments;
      state.netcostEntries = netcostEntries;
    },
    clearNetcostPaymentInfo(state) {
      state.netcostPayments = [];
      state.netcostEntries = [];
    },
    gotSupplementalPaymentInfo(state, action) {
      const { supplementalPayments, supplementalEntries } = action.payload;
      state.supplementalPayments = supplementalPayments;
      state.supplementalEntries = supplementalEntries;
    },
    clearSupplementalPaymentInfo(state) {
      state.supplementalPayments = [];
      state.supplementalEntries = [];
    },
    gotChangeorderPaymentInfo(state, action) {
      const { changeorderPayments, changeorderEntries } = action.payload;
      state.changeorderPayments = changeorderPayments;
      state.changeorderEntries = changeorderEntries;
    },
    clearChangeorderPaymentInfo(state) {
      state.changeorderPayments = [];
      state.changeorderEntries = [];
    },
    addedClassifiedPayment(state, action) {
      const newClassifiedPayment = action.payload;
      const classifiedPayments = state.classifiedPayments;
      if (Array.isArray(classifiedPayments)) {
        classifiedPayments.push(newClassifiedPayment);
      }
    },
    updatedClassifiedPayment(state, action) {
      const changedClassifiedPayment = action.payload;
      const classifiedPayments = state.classifiedPayments;
      if (Array.isArray(classifiedPayments)) {
        const foundIndex = classifiedPayments.findIndex(({ classifiedPaymentId }) => (
          classifiedPaymentId === changedClassifiedPayment.classifiedPaymentId
        ));
        if (foundIndex >= 0) {
          classifiedPayments[foundIndex] = changedClassifiedPayment;
        }
      }
    },
    gotWorkorderPayments(state, action) {
      state.workorderPayments = action.payload;
    },
    clearWorkorderPayments(state) {
      state.workorderPayments = [];
    },
    addedWorkOrderPayment(state, action) {
      const newWorkOrderPayment = action.payload;
      const workOrderPayments = state.workorderPayments;
      if (Array.isArray(workOrderPayments)) {
        workOrderPayments.push(newWorkOrderPayment);
      }
    },
    updatedWorkOrderPayment(state, action) {
      const changedWorkOrderPayment = action.payload;
      const workOrderPayments = state.workorderPayments;
      if (Array.isArray(workOrderPayments)) {
        const foundIndex = workOrderPayments.findIndex(({ workorderDetailPaymentId }) => (
          workorderDetailPaymentId === changedWorkOrderPayment.workorderDetailPaymentId
        ));
        if (foundIndex >= 0) {
          workOrderPayments[foundIndex] = changedWorkOrderPayment;
        }
      }
    },
    addedUnclassifiedPayment(state, action) {
      const newUnclassifiedPayment = action.payload;
      const unclassifiedPayments = state.lumpsumPayments;
      if (Array.isArray(unclassifiedPayments)) {
        unclassifiedPayments.push(newUnclassifiedPayment);
      }
    },
    updatedUnclassifiedPayment(state, action) {
      const changedUnclassifiedPayment = action.payload;
      const unclassifiedPayments = state.lumpsumPayments;
      if (Array.isArray(unclassifiedPayments)) {
        const foundIndex = unclassifiedPayments.findIndex(({ lumpsumPaymentId }) => (
          lumpsumPaymentId === changedUnclassifiedPayment.lumpsumPaymentId
        ));
        if (foundIndex >= 0) {
          unclassifiedPayments[foundIndex] = changedUnclassifiedPayment;
        }
      }
    },
    addedPerformbondPayment(state, action) {
      const newPerformbondPayment = action.payload;
      const performbondPayments = state.performbondPayments;
      if (Array.isArray(performbondPayments)) {
        performbondPayments.push(newPerformbondPayment);
      }
    },
    updatedPerformbondPayment(state, action) {
      const changedPerformbondPayment = action.payload;
      const performbondPayments = state.performbondPayments;
      if (Array.isArray(performbondPayments)) {
        const foundIndex = performbondPayments.findIndex(({ performbondPaymentId }) => (
          performbondPaymentId === changedPerformbondPayment.performbondPaymentId
        ));
        if (foundIndex >= 0) {
          performbondPayments[foundIndex] = changedPerformbondPayment;
        }
      }
    },
    addedNetcostPayment(state, action) {
      const newNetcostPayment = action.payload;
      const netcostPayments = state.netcostPayments;
      if (Array.isArray(netcostPayments)) {
        netcostPayments.push(newNetcostPayment);
      }
    },
    updatedNetcostPayment(state, action) {
      const changedNetcostPayment = action.payload;
      const netcostPayments = state.netcostPayments;
      if (Array.isArray(netcostPayments)) {
        const foundIndex = netcostPayments.findIndex(({ netcostPaymentId }) => (
          netcostPaymentId === changedNetcostPayment.netcostPaymentId
        ));
        if (foundIndex >= 0) {
          netcostPayments[foundIndex] = changedNetcostPayment;
        }
      }
    },
    addedChangeorderPayment(state, action) {
      const newChangeorderPayment = action.payload;
      const changeorderPayments = state.changeorderPayments;
      if (Array.isArray(changeorderPayments)) {
        changeorderPayments.push(newChangeorderPayment);
      }
    },
    updatedChangeorderPayment(state, action) {
      const changedChangeorderPayment = action.payload;
      const changeorderPayments = state.changeorderPayments;
      if (Array.isArray(changeorderPayments)) {
        const foundIndex = changeorderPayments.findIndex(({ changeorderPaymentId }) => (
          changeorderPaymentId === changedChangeorderPayment.changeorderPaymentId
        ));
        if (foundIndex >= 0) {
          changeorderPayments[foundIndex] = changedChangeorderPayment;
        }
      }
    },
    addedFieldorderPayment(state, action) {
      const newFieldorderPayment = action.payload;
      const fieldorderPayments = state.fieldorderPayments;
      if (Array.isArray(fieldorderPayments)) {
        fieldorderPayments.push(newFieldorderPayment);
      }
    },
    updatedFieldorderPayment(state, action) {
      const changedFieldorderPayment = action.payload;
      const fieldorderPayments = state.fieldorderPayments;
      if (Array.isArray(fieldorderPayments)) {
        const foundIndex = fieldorderPayments.findIndex(({ fieldorderPaymentId }) => (
          fieldorderPaymentId === changedFieldorderPayment.fieldorderPaymentId
        ));
        if (foundIndex >= 0) {
          fieldorderPayments[foundIndex] = changedFieldorderPayment;
        }
      }
    },
    addedSupplementalPayment(state, action) {
      const newSupplementalPayment = action.payload;
      const supplementalPayments = state.supplementalPayments;
      if (Array.isArray(supplementalPayments)) {
        supplementalPayments.push(newSupplementalPayment);
      }
    },
    updatedSupplementalPayment(state, action) {
      const changedSupplementalPayment = action.payload;
      const supplementalPayments = state.supplementalPayments;
      if (Array.isArray(supplementalPayments)) {
        const foundIndex = supplementalPayments.findIndex(({ supplementPaymentId }) => (
          supplementPaymentId === changedSupplementalPayment.supplementPaymentId
        ));
        if (foundIndex >= 0) {
          supplementalPayments[foundIndex] = changedSupplementalPayment;
        }
      }
    },
    updatedRetainage(state, action) {
      state.selectedPayment = action.payload;
    },
    updatedFinancialHistory(state, action) {
      state.selectedPayment.associatedContract = action.payload;
    },
    setAddPaymentError(state, action) {
      state.addPaymentStatus = 'FAILURE';
      state.addPaymentError = action.payload;
    },
    clearAddPaymentStatus(state) {
      state.addPaymentStatus = 'IDLE';
      state.addPaymentError = null;
    },
    setAddPaymentInProgress(state) {
      state.addPaymentStatus = 'IN_PROGRESS';
      state.addPaymentError = null;
    }
  }
});

const { actions, reducer } = paymentSlice;

export const getPayments = (contractId) => async (dispatch) => {
  const response = await fetchWithToken(getPaymentsByContractIdUrl(contractId));
  const data = await response.json();
  dispatch(actions.gotPayments(data));
}

export const getSelectedPayment = (paymentId) => async (dispatch) => {
  const response = await fetchWithToken(getPaymentByPaymentIdUrl(paymentId));
  const data = await response.json();
  dispatch(actions.gotSelectedPayment(data));
}

export const addPayment = (dto) => async (dispatch) => {
  dispatch(actions.setAddPaymentInProgress());
  const response = await fetchWithToken(
    addPaymentUrl,
    {
      method: 'POST',
      body: JSON.stringify(dto),
      headers: {
        'Content-Type': 'application/json'
      }
    }
  );

  if (response.status === 400) {
    const message = await response.text();
    dispatch(actions.setAddPaymentError(message));
    return;
  }

  const data = await response.json();
  dispatch(actions.addedSelectedPayment(data));
}

export const editPayment = (paymentId, dto) => async (dispatch) => {
  const response = await fetchWithToken(
    editPaymentByPaymentIdUrl(paymentId),
    {
      method: 'PATCH',
      body: JSON.stringify(dto),
      headers: {
        'Content-Type': 'application/json'
      }
    }
  );
  const data = await response.json();
  dispatch(actions.gotSelectedPayment(data));
}

export const postPayment = (paymentId, dto) => async (dispatch) => {
  const response = await fetchWithToken(
    postPaymentByPaymentIdUrl(paymentId),
    {
      method: 'PATCH',
      body: JSON.stringify(dto),
      headers: {
        'Content-Type': 'application/json'
      }
    }
  );
  const data = await response.json();
  dispatch(actions.gotSelectedPayment(data));
}

export const finalizePayment = (paymentId) => async (dispatch) => {
  const response = await fetchWithToken(
    finalizePaymentByPaymentIdUrl(paymentId),
    {
      method: 'PATCH'
    }
  );
  const data = await response.json();
  dispatch(actions.gotSelectedPayment(data));
}

export const UnpostPayment = (paymentId) => async (dispatch) => {
  const response = await fetchWithToken(
    unpostPaymentByPaymentIdUrl(paymentId),
    {
      method: 'PATCH'
    }
  );
  const data = await response.json();
  dispatch(actions.gotSelectedPayment(data));
}

export const editPaymentBondChargeCode = (paymentId, dto) => async (dispatch) => {
  const response = await fetchWithToken(
    editPaymentBondChargeCodeByPaymentIdUrl(paymentId),
    {
      method: 'PATCH',
      body: JSON.stringify(dto),
      headers: {
        'Content-Type': 'application/json'
      }
    }
  );
  const data = await response.json();
  dispatch(actions.gotSelectedPayment(data));
}

export const addClassifiedPayment = (dto) => async (dispatch) => {
  const response = await fetchWithToken(
    addClassifiedPaymentUrl,
    {
      method: 'POST',
      body: JSON.stringify(dto),
      headers: {
        'Content-Type': 'application/json'
      }
    }
  );
  const data = await response.json();
  dispatch(actions.addedClassifiedPayment(data));
}

export const editClassifiedPayment = (dto, classifiedPaymentId) => async (dispatch) => {
  const response = await fetchWithToken(
    editClassifiedPaymentUrl(classifiedPaymentId),
    {
      method: 'PATCH',
      body: JSON.stringify(dto),
      headers: {
        'Content-Type': 'application/json'
      }
    }
  );
  const data = await response.json();
  dispatch(actions.updatedClassifiedPayment(data));
}

export const addWorkOrderPayment = (dto) => async (dispatch) => {
  const response = await fetchWithToken(
    addWorkOrderPaymentUrl,
    {
      method: 'POST',
      body: JSON.stringify(dto),
      headers: {
        'Content-Type': 'application/json'
      }
    }
  );
  const data = await response.json();
  dispatch(actions.addedWorkOrderPayment(data));
}

export const editWorkOrderPayment = (dto, workOrderPaymentId) => async (dispatch) => {
  const response = await fetchWithToken(
    editWorkOrderPaymentUrl(workOrderPaymentId),
    {
      method: 'PATCH',
      body: JSON.stringify(dto),
      headers: {
        'Content-Type': 'application/json'
      }
    }
  );
  const data = await response.json();
  dispatch(actions.updatedWorkOrderPayment(data));
}

export const addUnclassifiedPayment = (dto) => async (dispatch) => {
  const response = await fetchWithToken(
    addUnclassifiedPaymentUrl,
    {
      method: 'POST',
      body: JSON.stringify(dto),
      headers: {
        'Content-Type': 'application/json'
      }
    }
  );
  const data = await response.json();
  dispatch(actions.addedUnclassifiedPayment(data));
}

export const editUnclassifiedPayment = (dto, unclassifiedPaymentId) => async (dispatch) => {
  const response = await fetchWithToken(
    editUnclassifiedPaymentUrl(unclassifiedPaymentId),
    {
      method: 'PATCH',
      body: JSON.stringify(dto),
      headers: {
        'Content-Type': 'application/json'
      }
    }
  );
  const data = await response.json();
  dispatch(actions.updatedUnclassifiedPayment(data));
}

export const addPerformbondPayment = (dto) => async (dispatch) => {
  const response = await fetchWithToken(
    addPerformbondPaymentUrl,
    {
      method: 'POST',
      body: JSON.stringify(dto),
      headers: {
        'Content-Type': 'application/json'
      }
    }
  );
  const data = await response.json();
  dispatch(actions.addedPerformbondPayment(data));
}

export const editPerformbondPayment = (dto, performbondPaymentId) => async (dispatch) => {
  const response = await fetchWithToken(
    editPerformbondPaymentUrl(performbondPaymentId),
    {
      method: 'PATCH',
      body: JSON.stringify(dto),
      headers: {
        'Content-Type': 'application/json'
      }
    }
  );
  const data = await response.json();
  dispatch(actions.updatedPerformbondPayment(data));
}

export const addNetcostPayment = (dto, contractId) => async (dispatch) => {
  const response = await fetchWithToken(
    addNetcostPaymentUrl,
    {
      method: 'POST',
      body: JSON.stringify(dto),
      headers: {
        'Content-Type': 'application/json'
      }
    }
  );
  const data = await response.json();
  dispatch(actions.addedNetcostPayment(data));
  dispatch(getSingleContract(contractId));
}

export const editNetcostPayment = (dto, netcostPaymentId, contractId) => async (dispatch) => {
  const response = await fetchWithToken(
    editNetcostPaymentUrl(netcostPaymentId),
    {
      method: 'PATCH',
      body: JSON.stringify(dto),
      headers: {
        'Content-Type': 'application/json'
      }
    }
  );
  const data = await response.json();
  dispatch(actions.updatedNetcostPayment(data));
  dispatch(getSingleContract(contractId));
}

export const addChangeorderPayment = (dto) => async (dispatch) => {
  const response = await fetchWithToken(
    addChangeorderPaymentUrl,
    {
      method: 'POST',
      body: JSON.stringify(dto),
      headers: {
        'Content-Type': 'application/json'
      }
    }
  );
  const data = await response.json();
  dispatch(actions.addedChangeorderPayment(data));
}

export const editChangeorderPayment = (dto, changeorderPaymentId) => async (dispatch) => {
  const response = await fetchWithToken(
    editChangeorderPaymentUrl(changeorderPaymentId),
    {
      method: 'PATCH',
      body: JSON.stringify(dto),
      headers: {
        'Content-Type': 'application/json'
      }
    }
  );
  const data = await response.json();
  dispatch(actions.updatedChangeorderPayment(data));
}

export const addFieldorderPayment = (dto) => async (dispatch) => {
  const response = await fetchWithToken(
    addFieldorderPaymentUrl,
    {
      method: 'POST',
      body: JSON.stringify(dto),
      headers: {
        'Content-Type': 'application/json'
      }
    }
  );
  const data = await response.json();
  dispatch(actions.addedFieldorderPayment(data));
}

export const editFieldorderPayment = (dto, fieldorderPaymentId) => async (dispatch) => {
  const response = await fetchWithToken(
    editFieldorderPaymentUrl(fieldorderPaymentId),
    {
      method: 'PATCH',
      body: JSON.stringify(dto),
      headers: {
        'Content-Type': 'application/json'
      }
    }
  );
  const data = await response.json();
  dispatch(actions.updatedFieldorderPayment(data));
}

export const addSupplementalPayment = (dto) => async (dispatch) => {
  const response = await fetchWithToken(
    addSupplementalPaymentUrl,
    {
      method: 'POST',
      body: JSON.stringify(dto),
      headers: {
        'Content-Type': 'application/json'
      }
    }
  );
  const data = await response.json();
  dispatch(actions.addedSupplementalPayment(data));
}

export const editSupplementalPayment = (dto, supplementalPaymentId) => async (dispatch) => {
  const response = await fetchWithToken(
    editSupplementalPaymentUrl(supplementalPaymentId),
    {
      method: 'PATCH',
      body: JSON.stringify(dto),
      headers: {
        'Content-Type': 'application/json'
      }
    }
  );
  const data = await response.json();
  dispatch(actions.updatedSupplementalPayment(data));
}

export const updateRetainage = (dto, paymentId) => async (dispatch) => {
  const response = await fetchWithToken(
    updateRetainageUrl(paymentId),
    {
      method: 'PATCH',
      body: JSON.stringify(dto),
      headers: {
        'Content-Type': 'application/json'
      }
    }
  );
  const data = await response.json();
  dispatch(actions.updatedRetainage(data));
}

export const updateFinancialHistory = (dto, contractId) => async (dispatch) => {
  const response = await fetchWithToken(
    updateFinancialHistoryUrl(contractId),
    {
      method: 'PATCH',
      body: JSON.stringify(dto),
      headers: {
        'Content-Type': 'application/json'
      }
    }
  );
  const data = await response.json();
  dispatch(actions.updatedFinancialHistory(data));
  const updatedValues = {
    sbcmplt: data.sbcmplt,
    fnlrem1: data.fnlrem1,
    fnlrem2: data.fnlrem2,
    fnlrem3: data.fnlrem3
  };
  dispatch(updatedContractFinancialHistory(contractId, updatedValues));
}

export const getClassifiedPaymentInfo = paymentId => async (dispatch) => {
  const response = await fetchWithToken(getClassifiedPaymentInfoUrl(paymentId));
  const data = await response.json();
  dispatch(actions.gotClassifiedPaymentInfo(data));
}

export const getUnclassifiedPaymentInfo = paymentId => async (dispatch) => {
  const response = await fetchWithToken(getUnclassifiedPaymentInfoUrl(paymentId));
  const data = await response.json();
  dispatch(actions.gotUnclassifiedPaymentInfo(data));
}

export const getPerformbondPaymentInfo = paymentId => async (dispatch) => {
  const response = await fetchWithToken(getPerformbondPaymentInfoUrl(paymentId));
  const data = await response.json();
  dispatch(actions.gotPerformbondPaymentInfo(data));
}

export const getFieldorderPaymentInfo = paymentId => async (dispatch) => {
  const response = await fetchWithToken(getFieldorderPaymentInfoUrl(paymentId));
  const data = await response.json();
  dispatch(actions.gotFieldorderPaymentInfo(data));
}

export const getNetcostPaymentInfo = paymentId => async (dispatch) => {
  const response = await fetchWithToken(getNetcostPaymentInfoUrl(paymentId));
  const data = await response.json();
  dispatch(actions.gotNetcostPaymentInfo(data));
}

export const getSupplementalPaymentInfo = paymentId => async (dispatch) => {
  const response = await fetchWithToken(getSupplementalPaymentInfoUrl(paymentId));
  const data = await response.json();
  dispatch(actions.gotSupplementalPaymentInfo(data));
}

export const getChangeorderPaymentInfo = paymentId => async (dispatch) => {
  const response = await fetchWithToken(getChangeorderPaymentInfoUrl(paymentId));
  const data = await response.json();
  dispatch(actions.gotChangeorderPaymentInfo(data));
}

export const getWorkorderPayments = paymentId => async (dispatch) => {
  const response = await fetchWithToken(getWorkorderPaymentsUrl(paymentId));
  const data = await response.json();
  dispatch(actions.gotWorkorderPayments(data));
}

export const {
  clearSelectedPayment, clearSelectedRecentlyUpdated, clearAddPaymentStatus, clearAllPaymentData,
  clearClassifiedPaymentInfo, clearUnclassifiedPaymentInfo, clearPerformbondPaymentInfo, clearFieldorderPaymentInfo, clearNetcostPaymentInfo,
  clearSupplementalPaymentInfo, clearChangeorderPaymentInfo, clearWorkorderPayments
} = actions;

export default reducer;
