import { fetchWithToken } from '../authentication';
import { searchSetAsides } from '../api_endpoints';

const START_GETTING_SET_ASIDE_ENTRIES = 'START_GETTING_SET_ASIDE_ENTRIES';
const STOP_GETTING_SET_ASIDE_ENTRIES = 'STOP_GETTING_SET_ASIDE_ENTRIES';
const GOT_SET_ASIDE_ENTRIES = 'GOT_SET_ASIDE_ENTRIES';
const RESET_SET_ASIDE_ENTRIES = 'RESET_SET_ASIDE_ENTRIES';

const startGettingSetAsideEntries = () => ({
  type: START_GETTING_SET_ASIDE_ENTRIES
});

const stopGettingSetAsideEntries = () => ({
  type: STOP_GETTING_SET_ASIDE_ENTRIES
});

const gotSetAsideEntries = (entries, prevSearchTerms) => ({
  type: GOT_SET_ASIDE_ENTRIES,
  entries,
  prevSearchTerms
});

export const resetSetAsideEntries = () => ({
  type: RESET_SET_ASIDE_ENTRIES
});

export const getSetAsideEntries = (name, ein, active) => async (dispatch) => {
  let response = null;
  try {
    dispatch(startGettingSetAsideEntries());
    response = await fetchWithToken(searchSetAsides(name, ein, active));
  }
  catch (e) {
    console.error(`Network error during API call to search for Set Aside vendors: "${e.message}"`);
    dispatch(stopGettingSetAsideEntries());
    return;
  }
  if (!response.ok) {
    console.error(`Server reports failure in response to API call to search for Set Aside vendors.`);
    dispatch(stopGettingSetAsideEntries());
    return;
  }
  let jsonDecodedData = null;
  try {
    jsonDecodedData = await response.json();
  }
  catch (e) {
    console.error(`Failed to deserialize vendor Set Aside data returned from API: "${e.message}"`);
    dispatch(stopGettingSetAsideEntries());
    return;
  }
  dispatch(gotSetAsideEntries(jsonDecodedData, {
    name,
    ein,
    active
  }));
  dispatch(stopGettingSetAsideEntries());
};

const initialState = {
  gettingEntries: false,
  prevSearchTerms: null,
  entries: []
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case START_GETTING_SET_ASIDE_ENTRIES:
      return { ...state, gettingEntries: true };
    case STOP_GETTING_SET_ASIDE_ENTRIES:
      return { ...state, gettingEntries: false };
    case GOT_SET_ASIDE_ENTRIES: {
      const { entries, prevSearchTerms } = action;
      return { ...state, entries, prevSearchTerms };
    }
    case RESET_SET_ASIDE_ENTRIES:
      return { ...state, entries: [], prevSearchTerms: null };
    default:
      return state;
  }
}
