import { API_ROOT, getEditPerfBondUrl } from "../api_endpoints";
import { acquireTokenPopupAndCallAPI, fetchWithToken } from "../authentication";

const GOT_SINGLE_CONTRACT = "GOT_SINGLE_CONTRACT";
const GOT_CONTRACTS = "GOT_CONTRACTS";
const GOT_SINGLE_CONTRACT_CONTRACTVENDOR_PAYMENTS =
  "GOT_SINGLE_CONTRACT_CONTRACTVENDOR_PAYMENTS";
const GOT_SINGLE_CONTRACT_CLASSIFIED_DETAILS =
  "GOT_SINGLE_CONTRACT_CLASSIFIED_DETAILS";
const GOT_SINGLE_CONTRACT_CLASSIFIED_DETAILWO =
  "GOT_SINGLE_CONTRACT_CLASSIFIED_DETAILWO";
const GOT_SINGLE_CONTRACT_CLASSIFIEDWO_NETCOST =
  "GOT_SINGLE_CONTRACT_CLASSIFIEDWO_NETCOST";
const GOT_FINANCIAL_SUMMARY = "GOT_FINANCIAL_SUMMARY";
const GOT_NETCOST_DETAILS = "GOT_NETCOST_DETAILS";
const START_GET_SINGLE_CONTRACT = "START_GET_SINGLE_CONTRACT";
const UNEXPECTED_STOP_GET_SINGLE_CONTRACT =
  "UNEXPECTED_STOP_GET_SINGLE_CONTRACT";
const UPDATED_PERF_BOND = "UPDATED_PERF_BOND";
const UPDATED_CONTRACT_FINANCIAL_HISTORY = "UPDATED_CONTRACT_FINANCIAL_HISTORY";

const GOT_NETCOST_PAYMENT_WO = "GOT_NETCOST_PAYMENT_WO";
const GOT_CLASSIFIED_PAYMENT_WO = "GOT_CLASSIFIED_PAYMENT_WO";
const GOT_NETCOST_PAYMENT = "GOT_NETCOST_PAYMENT";
const GOT_CLASSIFIED_PAYMENT = "GOT_CLASSIFIED_PAYMENT";
const GOT_UNCLASSIFIED_PAYMENT = "GOT_UNCLASSIFIED_PAYMENT";

const gotSingleContract = (
  singleContractMain,
  singleContractSummary,
  singleContractContractVendors,
  singleContractClassifieds,
  singleContractUnclassifieds,
  singleContractClassifiedWOs,
  singleContractNetcosts,
  singleContractChargeCode,
  singleContractPotentialDelay,
  singleContractRCPotentialDelay,
  singleContractPDPSStaffCostActualBudget,				
  singleContractSchedules,	
) => ({
  type: GOT_SINGLE_CONTRACT,
  singleContractMain,
  singleContractSummary,
  singleContractContractVendors,
  singleContractClassifieds,
  singleContractUnclassifieds,
  singleContractClassifiedWOs,
  singleContractNetcosts,
  singleContractChargeCode,
  singleContractPotentialDelay,
  singleContractRCPotentialDelay,								
  singleContractPDPSStaffCostActualBudget, 
  singleContractSchedules,
  singleContractLoading: false,
});

const gotContracts = (contracts) => ({
  type: GOT_CONTRACTS,
  contracts,
});

const gotSingleContractContractVendorPayments = (
  singleContractContractVendorPayments
) => ({
  type: GOT_SINGLE_CONTRACT_CONTRACTVENDOR_PAYMENTS,
  singleContractContractVendorPayments,
});
const gotSingleContractClassifiedDetails = (
  singleContractClassifiedDetails
) => ({
  type: GOT_SINGLE_CONTRACT_CLASSIFIED_DETAILS,
  singleContractClassifiedDetails,
});
const gotSingleContractClassifiedDetailWO = (
  singleContractClassifiedDetailWO
) => ({
  type: GOT_SINGLE_CONTRACT_CLASSIFIED_DETAILWO,
  singleContractClassifiedDetailWO,
});
const gotSingleContractClassifiedWONetCost = (
  singleContractClassifiedWONetCost
) => ({
  type: GOT_SINGLE_CONTRACT_CLASSIFIEDWO_NETCOST,
  singleContractClassifiedWONetCost,
});
const gotSingleContractNetcostDetails = (singleContractNetcostDetails) => ({
  type: GOT_NETCOST_DETAILS,
  singleContractNetcostDetails,
});

const gotFinancialSummary = (financialSummary) => ({
  type: GOT_FINANCIAL_SUMMARY,
  financialSummary,
});

const startGetSingleContract = () => ({
  type: START_GET_SINGLE_CONTRACT,
  singleContractLoading: true,
});

const unexpectedStopGetSingleContract = () => ({
  type: UNEXPECTED_STOP_GET_SINGLE_CONTRACT,
  singleContractLoading: false,
});

const updatedPerfBond = (id, values) => ({
  type: UPDATED_PERF_BOND,
  id,
  values,
});

export const updatedContractFinancialHistory = (contractId, values) => ({
  type: UPDATED_CONTRACT_FINANCIAL_HISTORY,
  contractId,
  values
});

export const getSingleContract = (id) => async (dispatch) => {
  try {
    const responses = await acquireTokenPopupAndCallAPI(async (token) => {
      const mainResPromise = fetch(`${API_ROOT.contract}/${id}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const summaryResPromise = fetch(`${API_ROOT.financialSummary}/${id}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const contractvendorsResPromise = fetch(
        `${API_ROOT.contractvendors}/${id}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const classifiedsResPromise = fetch(`${API_ROOT.classifieds}/${id}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const unclassifiedsResPromise = fetch(`${API_ROOT.unclassifieds}/${id}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const classifiedwosResPromise = fetch(`${API_ROOT.classifiedWOs}/${id}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const netcostsResPromise = fetch(`${API_ROOT.netcosts}/${id}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const chargeCodesResPromise = fetch(
        `${API_ROOT.chargeCodesByContract}/${id}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const potentialdelayResPromise = fetch(
        `${API_ROOT.potentialdelays}/${id}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const rcpotentialdelayResPromise = fetch(
        `${API_ROOT.rcpotentialdelays}/${id}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const pdpsstaffcostactualbudgetResPromise = fetch(
        `${API_ROOT.pdpsstaffcostactualbudget}/${id}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const schedulesResPromise = fetch(`${API_ROOT.schedules}/${id}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      dispatch(startGetSingleContract());
      return Promise.all([
        mainResPromise,
        summaryResPromise,
        contractvendorsResPromise,
        classifiedsResPromise,
        unclassifiedsResPromise,
        classifiedwosResPromise,
        netcostsResPromise,
        chargeCodesResPromise,
        potentialdelayResPromise,
        rcpotentialdelayResPromise,				
        pdpsstaffcostactualbudgetResPromise,	
        schedulesResPromise,			   
      ]);
    });
    const [
      mainObj,
      summaryObj,
      contractvendorsObj,
      classifiedsObj,
      unclassifiedsObj,
      classifiedswoObj,
      netcostObj,
      chargeCodesObj,
      potentialdelayObj,
      rcpotentialdelayObj,		
      pdpsstaffcostactualbudgetObj,		
      scheduleObj,		  
    ] = await Promise.all(responses.map((res) => res.json()));
    dispatch(
      gotSingleContract(
        mainObj,
        summaryObj,
        contractvendorsObj,
        classifiedsObj,
        unclassifiedsObj,
        classifiedswoObj,
        netcostObj,
        chargeCodesObj,
        potentialdelayObj,
        rcpotentialdelayObj,
        pdpsstaffcostactualbudgetObj,		   
        scheduleObj,
      )
    );
  } catch (error) {
    dispatch(unexpectedStopGetSingleContract());
    console.error(error);
  }
};

export const getContracts = () => async (dispatch) => {
  try {
    const response = await acquireTokenPopupAndCallAPI((token) =>
      fetch(API_ROOT.contract, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
    );
    const contracts = await response.json();
    contracts.sort((a, b) => (a.contractNo > b.contractNo) ? 1 : -1)
    dispatch(gotContracts(contracts));
  } catch (error) {
    console.error(error);
  }
};

export const getContractVendorPayments = (id) => async (dispatch) => {
  try {
    const response = await acquireTokenPopupAndCallAPI((token) =>
      fetch(`${API_ROOT.contractvendorpayments}/${id}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
    );
    const singleContractContractVendorPayments = await response.json();
    dispatch(
      gotSingleContractContractVendorPayments(
        singleContractContractVendorPayments
      )
    );
  } catch (error) {
    console.error(error);
  }
};

export const getClassifiedDetails = (id) => async (dispatch) => {
  try {
    const response = await acquireTokenPopupAndCallAPI((token) =>
      fetch(`${API_ROOT.classifiedDetails}/${id}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
    );
    const singleContractClassifiedDetails = await response.json();
    dispatch(
      gotSingleContractClassifiedDetails(singleContractClassifiedDetails)
    );
  } catch (error) {
    console.error(error);
  }
};

export const getClassifiedDetailWO = (id) => async (dispatch) => {
  try {
    const response = await acquireTokenPopupAndCallAPI((token) =>
      fetch(`${API_ROOT.classifiedDetailWO}/${id}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
    );
    const singleContractClassifiedDetailWO = await response.json();
    dispatch(
      gotSingleContractClassifiedDetailWO(singleContractClassifiedDetailWO)
    );
  } catch (error) {
    console.error(error);
  }
};

export const getClassifiedWONetCost = (id) => async (dispatch) => {
  try {
    const response = await acquireTokenPopupAndCallAPI((token) =>
      fetch(`${API_ROOT.classifiedWOByNetCost}/${id}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
    );
    const singleContractClassifiedWONetCost = await response.json();
    dispatch(
      gotSingleContractClassifiedWONetCost(singleContractClassifiedWONetCost)
    );
  } catch (error) {
    console.error(error);
  }
};

export const getNetcostDetails = (id) => async (dispatch) => {
  try {
    const response = await acquireTokenPopupAndCallAPI((token) =>
      fetch(`${API_ROOT.netcostDetails}/${id}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
    );
    const singleContractNetcostDetails = await response.json();
    dispatch(gotSingleContractNetcostDetails(singleContractNetcostDetails));
  } catch (error) {
    console.error(error);
  }
};

//get net cost payments by WO
export const getNetcostPaymentsbyWo = (classifiedWoId) => async dispatch => {
  try {
    const response = await acquireTokenPopupAndCallAPI(token =>
      fetch(`${API_ROOT.netcostPaymentByClassifiedWo}/${classifiedWoId}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
    );
    const netcostpaymentswo = await response.json();
    dispatch(gotNetcostPaymentsWo(netcostpaymentswo));
  } catch (error) {
    console.error(error);
  }
};

const gotNetcostPaymentsWo = netcostPaymentsWo => ({
  type: GOT_NETCOST_PAYMENT_WO,
  netcostPaymentsWo
});
//

//get classified payments by WO
export const getClassifiedPaymentsbyWo = (classifiedWoId) => async dispatch => {
  try {
    const response = await acquireTokenPopupAndCallAPI(token =>
      fetch(`${API_ROOT.classifiedPaymentByClassifiedWo}/${classifiedWoId}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
    );
    const classifiedpaymentswo = await response.json();
    dispatch(gotClassifiedPaymentsWo(classifiedpaymentswo));
  } catch (error) {
    console.error(error);
  }
};

const gotClassifiedPaymentsWo = classifiedPaymentsWo => ({
  type: GOT_CLASSIFIED_PAYMENT_WO,
  classifiedPaymentsWo
});
//
//get net cost payments by contractId
export const getNetcostPaymentsbyContractId = (contractId) => async dispatch => {
  try {
    const response = await acquireTokenPopupAndCallAPI(token =>
      fetch(`${API_ROOT.netcostPaymentByContractId}/${contractId}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
    );
    const netcostpayments = await response.json();
    dispatch(gotNetcostPayments(netcostpayments));
  } catch (error) {
    console.error(error);
  }
};

const gotNetcostPayments = netcostPayments => ({
  type: GOT_NETCOST_PAYMENT,
  netcostPayments
});
//

//get classified payments by contractId
export const getClassifiedPaymentsContractId = (contractId) => async dispatch => {
  try {
    const response = await acquireTokenPopupAndCallAPI(token =>
      fetch(`${API_ROOT.classifiedPaymentByClassifiedWo}/${contractId}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
    );
    const classifiedpayments = await response.json();
    dispatch(gotClassifiedPayments(classifiedpayments));
  } catch (error) {
    console.error(error);
  }
};

const gotClassifiedPayments = classifiedPayments => ({
  type: GOT_CLASSIFIED_PAYMENT,
  classifiedPayments
});
//


//get unclassified payments by contractId
export const getUnclassifiedPaymentsContractId = (contractId) => async dispatch => {
  try {
    const response = await acquireTokenPopupAndCallAPI(token =>
      fetch(`${API_ROOT.unclassifiedPaymentByContractId}/${contractId}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
    );
    const unclassifiedpayments = await response.json();
    dispatch(gotUnclassifiedPayments(unclassifiedpayments));
  } catch (error) {
    console.error(error);
  }
};

const gotUnclassifiedPayments = unclassifiedPayments => ({
  type: GOT_UNCLASSIFIED_PAYMENT,
  unclassifiedPayments
});
//
/*
  Shape of auth obj:
  {
    id, authClass, authExwk, authNc,
    authSupplement, authWo,
  }
  authUnclass and authRisk are categories that exist
  in the database but should not be used because
  of business logic.
*/
export const addAuthorizations = (authObj, contractId) => async (dispatch) => {
  try {
    await acquireTokenPopupAndCallAPI((token) =>
      fetch(API_ROOT.reauthorizations, {
        method: "POST",
        body: JSON.stringify(authObj),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
    );
    dispatch(getSingleContract(contractId));
  } catch (error) {
    console.error(error);
  }
};


export const editAuthorizations = (authObj, contractId, id, type) => async (dispatch) => {
  try {
    await acquireTokenPopupAndCallAPI((token) =>
      //fetch(`${API_ROOT.reauthorizations/?id=${id}`,{
        fetch(`${API_ROOT.reauthorizations}/${type}/?contractId=${contractId}&&id=${id}`, {        
        method: "PATCH",
        body: JSON.stringify(authObj),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
    );
    dispatch(getSingleContract(contractId));
  } catch (error) {
    console.error(error);
  }
};

export const deleteAuthorization = (contractId, id, type) => async (dispatch) => { // Delete by Type
  try {
    await acquireTokenPopupAndCallAPI(token =>
      fetch(`${API_ROOT.reauthorizations}/${type}/?contractId=${contractId}&&id=${id}`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
    );
    dispatch(getSingleContract(contractId));
  } catch (error) {
    console.error(error);
  }
};

export const addContract = (contractObj) => async (dispatch) => {
  try {
    const response = await acquireTokenPopupAndCallAPI((token) =>
      fetch(API_ROOT.contract, {
        method: "POST",
        body: JSON.stringify(contractObj),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
    );
    const addedContractObj = await response.json();
    dispatch(getSingleContract(addedContractObj.id));
    dispatch(getContracts());
  } catch (error) {
    console.error(error);
  }
};

export const addFileStorageLibrary = (contractNo, facilityId) => async (dispatch) => {
  try {
    const response = await acquireTokenPopupAndCallAPI((token) => (
      fetch(
        `${API_ROOT.azureFileStorageCreateLib}/?contractNo=${contractNo}&facilityId=${facilityId}`,
        {
          method: 'POST',
            headers: {
              Authorization: `Bearer ${token}`,
            }
        }
      )
    ));
    const addedContractObj = await response.json();
    //dispatch(getSingleContract(addedContractObj.id));
    //dispatch(getContracts());
  } catch (error) {
    console.error(error);
  }
};

export const editContract =
  (contractObj, id, capTrakEntries, chargeCodeEntries) => async (dispatch) => {
    await acquireTokenPopupAndCallAPI(async (token) => {
      const editContractReq = fetch(
        `${API_ROOT.editContractStatus}/?id=${id}`,
        {
          method: "PATCH",
          body: JSON.stringify(contractObj),
          headers: {
            "Content-Type": "application/json-patch+json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const requestPromiseList = [editContractReq];
      if (capTrakEntries !== undefined) {
        const capTrakAdditionReqs = capTrakEntries.additions.map((entry) =>
          fetch(API_ROOT.captrakIds, {
            method: "POST",
            body: JSON.stringify(entry),
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          })
        );
        const capTrakEditReqs = capTrakEntries.edits.map((editObj) =>
          fetch(`${API_ROOT.captrakIds}/?id=${editObj.id}`, {
            method: "PATCH",
            body: JSON.stringify(editObj.patchObj),
            headers: {
              "Content-Type": "application/json-patch+json",
              Authorization: `Bearer ${token}`,
            },
          })
        );
        const capTrakDeletionReqs = capTrakEntries.deletions.map((captrakId) =>
          fetch(`${API_ROOT.captrakIds}/${captrakId}`, {
            method: "DELETE",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
        );
        requestPromiseList.push(
          Promise.all(capTrakAdditionReqs),
          Promise.all(capTrakEditReqs),
          Promise.all(capTrakDeletionReqs)
        );
      }
      if (chargeCodeEntries !== undefined) {
        const chargeCodeAdditionReqs = chargeCodeEntries.additions.map(
          (entry) =>
            fetch(API_ROOT.chargeCodes, {
              method: "POST",
              body: JSON.stringify(entry),
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            })
        );
        const chargeCodeEditReqs = chargeCodeEntries.edits.map((editObj) =>
          fetch(`${API_ROOT.chargeCodes}/?id=${editObj.id}`, {
            method: "PATCH",
            body: JSON.stringify(editObj.patchObj),
            headers: {
              "Content-Type": "application/json-patch+json",
              Authorization: `Bearer ${token}`,
            },
          })
        );
        const chargeCodeDeletionReqs = chargeCodeEntries.deletions.map(
          (chargeCodeId) =>
            fetch(`${API_ROOT.chargeCodes}/${chargeCodeId}`, {
              method: "DELETE",
              headers: {
                Authorization: `Bearer ${token}`,
              },
            })
        );
        requestPromiseList.push(
          Promise.all(chargeCodeAdditionReqs),
          Promise.all(chargeCodeEditReqs),
          Promise.all(chargeCodeDeletionReqs)
        );
      }
      try {
        await Promise.all(requestPromiseList);
        dispatch(getSingleContract(id));
      } catch (error) {
        console.error(error);
      }
    });
  };

export const bulkEditMilestones =
  (contractId, additions, edits, deletions) => async (dispatch) => {
    await acquireTokenPopupAndCallAPI(async (token) => {
      const additionReqs = additions.map((entry) =>
        fetch(API_ROOT.milestones, {
          method: "POST",
          body: JSON.stringify(entry),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
      );
      const editReqs = edits.map((editObj) =>
        fetch(`${API_ROOT.milestones}/?id=${editObj.id}`, {
          method: "PATCH",
          body: JSON.stringify(editObj.patchObj),
          headers: {
            "Content-Type": "application/json-patch+json",
            Authorization: `Bearer ${token}`,
          },
        })
      );
      const deletionReqs = deletions.map((milestoneId) =>
        fetch(`${API_ROOT.milestones}/${milestoneId}`, {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
      );
      try {
        await Promise.all([
          Promise.all(additionReqs),
          Promise.all(editReqs),
          Promise.all(deletionReqs),
        ]);
        dispatch(getSingleContract(contractId));
      } catch (error) {
        console.error(error);
      }
    });
  };

  export const bulkEditAudits =
  (contractId, additions, edits, deletions) => async (dispatch) => {
    await acquireTokenPopupAndCallAPI(async (token) => {
      const additionReqs = additions.map((entry) =>
        fetch(API_ROOT.audits, {
          method: "POST",
          body: JSON.stringify(entry),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
      );
      const editReqs = edits.map((editObj) =>
        fetch(`${API_ROOT.audits}/?id=${editObj.id}`, {
          method: "PATCH",
          body: JSON.stringify(editObj.patchObj),
          headers: {
            "Content-Type": "application/json-patch+json",
            Authorization: `Bearer ${token}`,
          },
        })
      );
      const deletionReqs = deletions.map((auditId) =>
        fetch(`${API_ROOT.audits}/${auditId}`, {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
      );
      try {
        await Promise.all([
          Promise.all(additionReqs),
          Promise.all(editReqs),
          Promise.all(deletionReqs),
        ]);
        dispatch(getSingleContract(contractId));
      } catch (error) {
        console.error(error);
      }
    });
  };

export const bulkEditCaptrakIds =
  (contractId, additions, edits, deletions) => async (dispatch) => {
    await acquireTokenPopupAndCallAPI(async (token) => {
      const additionReqs = additions.map((entry) =>
        fetch(API_ROOT.captrakIds, {
          method: "POST",
          body: JSON.stringify(entry),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
      );
      const editReqs = edits.map((editObj) =>
        fetch(`${API_ROOT.captrakIds}/?id=${editObj.id}`, {
          method: "PATCH",
          body: JSON.stringify(editObj.patchObj),
          headers: {
            "Content-Type": "application/json-patch+json",
            Authorization: `Bearer ${token}`,
          },
        })
      );
      const deletionReqs = deletions.map((captrakId) =>
        fetch(`${API_ROOT.captrakIds}/${captrakId}`, {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
      );
      try {
        await Promise.all([
          Promise.all(additionReqs),
          Promise.all(editReqs),
          Promise.all(deletionReqs),
        ]);
        dispatch(getSingleContract(contractId));
      } catch (error) {
        console.error(error);
      }
    });
  };

export const bulkEditChargeCodes =
  (contractId, additions, edits, deletions) => async (dispatch) => {
    await acquireTokenPopupAndCallAPI(async (token) => {
      const additionReqs = additions.map((entry) =>
        fetch(API_ROOT.chargeCodes, {
          method: "POST",
          body: JSON.stringify(entry),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
      );
      const editReqs = edits.map((editObj) =>
        fetch(`${API_ROOT.chargeCodes}/?id=${editObj.id}`, {
          method: "PATCH",
          body: JSON.stringify(editObj.patchObj),
          headers: {
            "Content-Type": "application/json-patch+json",
            Authorization: `Bearer ${token}`,
          },
        })
      );
      const deletionReqs = deletions.map((chargeCodeId) =>
        fetch(`${API_ROOT.chargeCodes}/${chargeCodeId}`, {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
      );
      try {
        await Promise.all([
          Promise.all(additionReqs),
          Promise.all(editReqs),
          Promise.all(deletionReqs),
        ]);
        //dispatch(getSingleContract(contractId));
      } catch (error) {
        console.error(error);
      }
    });
  };

export const bulkEditUnclassified =
  (contractId, additions, edits) => async (dispatch) => {
    await acquireTokenPopupAndCallAPI(async (token) => {
      const additionReqs = additions.map((entry) =>
        fetch(API_ROOT.unclassified, {
          method: "POST",
          body: JSON.stringify(entry),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
      );
      const editReqs = edits.map((editObj) =>
        fetch(`${API_ROOT.unclassified}/?id=${editObj.id}`, {
          method: "PATCH",
          body: JSON.stringify(editObj.patchObj),
          headers: {
            "Content-Type": "application/json-patch+json",
            Authorization: `Bearer ${token}`,
          },
        })
      );
      try {
        await Promise.all([Promise.all(additionReqs), Promise.all(editReqs)]);
        dispatch(getSingleContract(contractId));
      } catch (error) {
        console.error(error);
      }
    });
  };

export const editContractVendorItem =
  (contractId, edits) => async (dispatch) => {
    await acquireTokenPopupAndCallAPI(async (token) => {
      const editReqs = fetch(
        `${API_ROOT.contractvendor}/?id=${edits.contractVendorId}`,
        {
          method: "PATCH",
          body: JSON.stringify(edits.patchObj),
          headers: {
            "Content-Type": "application/json-patch+json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      try {
        await Promise.all([editReqs]);
        dispatch(getSingleContract(contractId));
      } catch (error) {
        console.error(error);
      }
    });
  };

export const addContractVendorItem =
  (contractId, additions) => async (dispatch) => {
    await acquireTokenPopupAndCallAPI(async (token) => {
      const additionReqs = fetch(API_ROOT.contractvendor, {
        method: "POST",
        body: JSON.stringify(additions.editableFields),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      try {
        await Promise.all([additionReqs]);
        dispatch(getSingleContract(contractId));
      } catch (error) {
        console.error(error);
      }
    });
  };

export const editClassifiedItem = (contractId, edits) => async (dispatch) => {
  await acquireTokenPopupAndCallAPI(async (token) => {
    const editReqs = fetch(`${API_ROOT.classified}/?id=${edits.classifiedId}`, {
      method: "PATCH",
      body: JSON.stringify(edits.patchObj),
      headers: {
        "Content-Type": "application/json-patch+json",
        Authorization: `Bearer ${token}`,
      },
    });
    try {
      await Promise.all([editReqs]);
      dispatch(getSingleContract(contractId));
    } catch (error) {
      console.error(error);
    }
  });
};

export const addClassifiedItem =
  (contractId, additions) => async (dispatch) => {
    await acquireTokenPopupAndCallAPI(async (token) => {
      const additionReqs = fetch(API_ROOT.classified, {
        method: "POST",
        body: JSON.stringify(additions.editableFields),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      try {
        await Promise.all([additionReqs]);
        dispatch(getSingleContract(contractId));
      } catch (error) {
        console.error(error);
      }
    });
  };

export const editClassifiedWOItem = (contractId, edits) => async (dispatch) => {
  await acquireTokenPopupAndCallAPI(async (token) => {
    const editReqs = fetch(
      `${API_ROOT.classifiedWO}/?id=${edits.classifiedWoId}`,
      {
        method: "PATCH",
        body: JSON.stringify(edits.patchObj),
        headers: {
          "Content-Type": "application/json-patch+json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    try {
      await Promise.all([editReqs]);
      dispatch(getSingleContract(contractId));
    } catch (error) {
      console.error(error);
    }
  });
};

export const addClassifiedWOItem =
  (contractId, additions) => async (dispatch) => {
    await acquireTokenPopupAndCallAPI(async (token) => {
      const additionReqs = fetch(API_ROOT.classifiedWO, {
        method: "POST",
        body: JSON.stringify(additions.editableFields),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      try {
        await Promise.all([additionReqs]);
        dispatch(getSingleContract(contractId));
      } catch (error) {
        console.error(error);
      }
    });
  };

export const editUnclassifiedItem = (contractId, edits) => async (dispatch) => {
  await acquireTokenPopupAndCallAPI(async (token) => {
    const editReqs = fetch(`${API_ROOT.unclassified}/?id=${edits.lumpsumId}`, {
      method: "PATCH",
      body: JSON.stringify(edits.patchObj),
      headers: {
        "Content-Type": "application/json-patch+json",
        Authorization: `Bearer ${token}`,
      },
    });
    try {
      await Promise.all([editReqs]);
      dispatch(getSingleContract(contractId));
    } catch (error) {
      console.error(error);
    }
  });
};

export const addUnclassifiedItem =
  (contractId, additions) => async (dispatch) => {
    await acquireTokenPopupAndCallAPI(async (token) => {
      const additionReqs = fetch(API_ROOT.unclassified, {
        method: "POST",
        body: JSON.stringify(additions.editableFields),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      try {
        await Promise.all([additionReqs]);
        dispatch(getSingleContract(contractId));
      } catch (error) {
        console.error(error);
      }
    });
  };

export const addContractVendorItemRecords =
  (contractId, additions) => async (dispatch) => {
    await acquireTokenPopupAndCallAPI(async (token) => {
      const additionReqs = fetch(API_ROOT.contractvendorpayment, {
        method: "POST",
        body: JSON.stringify(additions.editableFields),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      try {
        await Promise.all([additionReqs]);
        dispatch(getSingleContract(contractId));
        dispatch(
          getContractVendorPayments(additions.editableFields.contractVendorId)
        );
      } catch (error) {
        console.error(error);
      }
    });
  };

export const editContractVendorItemRecords =
  (contractId, edits) => async (dispatch) => {
    await acquireTokenPopupAndCallAPI(async (token) => {
      const editReqs = fetch(
        `${API_ROOT.contractvendorpayment}/?id=${edits.contractVendorPaymentId}`,
        {
          method: "PATCH",
          body: JSON.stringify(edits.patchObj),
          headers: {
            "Content-Type": "application/json-patch+json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      try {
        await Promise.all([editReqs]);
        dispatch(getSingleContract(contractId));
        dispatch(getContractVendorPayments(edits.contractVendorId));
      } catch (error) {
        console.error(error);
      }
    });
  };

export const editClassifiedItemRecords =
  (contractId, edits) => async (dispatch) => {
    await acquireTokenPopupAndCallAPI(async (token) => {
      const editReqs = fetch(
        `${API_ROOT.classifiedDetail}/?id=${edits.classifiedDetailId}`,
        {
          method: "PATCH",
          body: JSON.stringify(edits.patchObj),
          headers: {
            "Content-Type": "application/json-patch+json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      try {
        await Promise.all([editReqs]);
        dispatch(getSingleContract(contractId));
        dispatch(getClassifiedDetails(edits.classifiedId));
      } catch (error) {
        console.error(error);
      }
    });
  };

export const editNetcostItem = (contractId, edits) => async (dispatch) => {
  await acquireTokenPopupAndCallAPI(async (token) => {
    const editReqs = fetch(`${API_ROOT.netcost}/?id=${edits.netcostId}`, {
      method: "PATCH",
      body: JSON.stringify(edits.patchObj),
      headers: {
        "Content-Type": "application/json-patch+json",
        Authorization: `Bearer ${token}`,
      },
    });
    try {
      await Promise.all([editReqs]);
      dispatch(getSingleContract(contractId));
    } catch (error) {
      console.error(error);
    }
  });
};

export const addNetcostItem = (contractId, additions) => async (dispatch) => {
  await acquireTokenPopupAndCallAPI(async (token) => {
    const additionReqs = fetch(API_ROOT.netcost, {
      method: "POST",
      body: JSON.stringify(additions.editableFields),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    try {
      await Promise.all([additionReqs]);
      dispatch(getSingleContract(contractId));
    } catch (error) {
      console.error(error);
    }
  });
};

export const addClassifiedItemRecords =
  (contractId, additions) => async (dispatch) => {
    await acquireTokenPopupAndCallAPI(async (token) => {
      const additionReqs = fetch(API_ROOT.classifiedDetail, {
        method: "POST",
        body: JSON.stringify(additions.editableFields),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      try {
        await Promise.all([additionReqs]);
        dispatch(getSingleContract(contractId));
        dispatch(getClassifiedDetails(additions.editableFields.classifiedId));
      } catch (error) {
        console.error(error);
      }
    });
  };

export const addNetcostDetailItem =
  (contractId, additions) => async (dispatch) => {
    await acquireTokenPopupAndCallAPI(async (token) => {
      const additionReqs = fetch(API_ROOT.netcostDetail, {
        method: "POST",
        body: JSON.stringify(additions.editableFields),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      try {
        await Promise.all([additionReqs]);
        dispatch(getSingleContract(contractId));
        dispatch(getNetcostDetails(additions.editableFields.netcostId));
      } catch (error) {
        console.error(error);
      }
    });
  };

export const editNetcostDetailItem =
  (contractId, edits) => async (dispatch) => {
    await acquireTokenPopupAndCallAPI(async (token) => {
      const editReqs = fetch(
        `${API_ROOT.netcostDetail}/?id=${edits.netcostDetailId}`,
        {
          method: "PATCH",
          body: JSON.stringify(edits.patchObj),
          headers: {
            "Content-Type": "application/json-patch+json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      try {
        await Promise.all([editReqs]);
        dispatch(getSingleContract(contractId));
        dispatch(getNetcostDetails(edits.netcostId));
      } catch (error) {
        console.error(error);
      }
    });
  };

export const getFinancialSummary = (id) => async (dispatch) => {
  const response = await acquireTokenPopupAndCallAPI((token) =>
    fetch(`${API_ROOT.financialSummary}/${id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
  );
  const financialSummaryObj = await response.json();
  dispatch(gotFinancialSummary(financialSummaryObj));
};

export const updatePerfBond = (id, values) => async (dispatch) => {
  const response = await fetchWithToken(getEditPerfBondUrl(id), {
    method: "PATCH",
    body: JSON.stringify(values),
    headers: {
      "Content-Type": "application/json",
    },
  });
  const updatedValues = await response.json();
  dispatch(updatedPerfBond(id, updatedValues));
};

export const bulkEditPotentialDelay =
  (contractId, additions, edits) => async (dispatch) => {
    await acquireTokenPopupAndCallAPI(async (token) => {
      const additionReqs = additions.map((entry) =>
        fetch(API_ROOT.potentialdelay, {
          method: "POST",
          body: JSON.stringify(entry),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
      );
      const editReqs = edits.map((editObj) =>
        fetch(`${API_ROOT.potentialdelay}/?id=${editObj.id}`, {
          method: "PATCH",
          body: JSON.stringify(editObj.patchObj),
          headers: {
            "Content-Type": "application/json-patch+json",
            Authorization: `Bearer ${token}`,
          },
        })
      );
      try {
        await Promise.all([Promise.all(additionReqs), Promise.all(editReqs)]);
        dispatch(getSingleContract(contractId));
      } catch (error) {
        console.error(error);
      }
    });
  };

export const editPotentialDelay = (contractId, edits) => async (dispatch) => {
  await acquireTokenPopupAndCallAPI(async (token) => {
    const editReqs = fetch(
      `${API_ROOT.potentialdelay}/?id=${edits.potentialdelayId}`,
      {
        method: "PATCH",
        body: JSON.stringify(edits.patchObj),
        headers: {
          "Content-Type": "application/json-patch+json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    try {
      await Promise.all([editReqs]);
      dispatch(getSingleContract(contractId));
    } catch (error) {
      console.error(error);
    }
  });
};

export const addPotentialDelay =
  (contractId, additions) => async (dispatch) => {
    await acquireTokenPopupAndCallAPI(async (token) => {
      const additionReqs = fetch(API_ROOT.potentialdelay, {
        method: "POST",
        body: JSON.stringify(additions.editableFields),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      try {
        await Promise.all([additionReqs]);
        dispatch(getSingleContract(contractId));
      } catch (error) {
        console.error(error);
      }
    });
  };

  export const deletePotentialDelay = (contractId, potentialdelayId) => async dispatch => {
    try {
      await acquireTokenPopupAndCallAPI(token =>
        fetch(`${API_ROOT.potentialdelay}/${potentialdelayId}`, {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`
          }
        })
      );
      dispatch(getSingleContract(contractId));
    } catch (error) {
      console.error(error);
    }
  };
  
  export const getUnclassifiedDeductions = (id) => async (dispatch) => {
    try {
      const response = await acquireTokenPopupAndCallAPI((token) =>
        fetch(`${API_ROOT.unclassifiedDeductions}/${id}`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
      );
      const lumpsumDeductions = await response.json();
      //dispatch(gotFacilityDetail(facilityDetail));
      return lumpsumDeductions;
    } catch (error) {
      console.error(error);
    }
  };

  export const editScheduleItem = (contractId, edits) => async (dispatch) => {
    await acquireTokenPopupAndCallAPI(async (token) => {
      const editReqs = fetch(`${API_ROOT.schedule}/?id=${edits.scheduleId}`, {
        method: "PATCH",
        body: JSON.stringify(edits.patchObj),
        headers: {
          "Content-Type": "application/json-patch+json",
          Authorization: `Bearer ${token}`,
        },
      });
      try {
        await Promise.all([editReqs]);
        dispatch(getSingleContract(contractId));
      } catch (error) {
        console.error(error);
      }
    });
  };
  
  export const addScheduleItem = (contractId, additions) => async (dispatch) => {
    await acquireTokenPopupAndCallAPI(async (token) => {
      const additionReqs = fetch(API_ROOT.schedule, {
        method: "POST",
        body: JSON.stringify(additions.editableFields),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      try {
        await Promise.all([additionReqs]);
        dispatch(getSingleContract(contractId));
      } catch (error) {
        console.error(error);
      }
    });
  };

const initialState = {
  singleContractLoading: false,
  singleContractMain: null,
  singleContractSummary: null,
  singleContractContractVendors: null,
  singleContractClassifieds: null,
  singleContractUnclassifieds: null,
  singleContractClassifiedWOs: null,
  singleContractChargeCode: null,
  singleContractContractVendorPayments: [],
  singleContractClassifiedDetails: [],
  singleContractClassifiedDetailWO: [],
  singleContractClassifiedWONetCost: [],
  singleContractNetcosts: null,
  singleContractPotentialDelay: null,
  singleContractRCPotentialDelay: null,	
  singleContractPDPSStaffCostActualBudget: null,		  
  singleContractSchedules: null,						   
  contracts: [],
  netcostPaymentsWo: [],
  classifiedPaymentsWo: [],
  netcostPayments: [],
  classifiedPayments: [],
  unclassifiedPayments: [],
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GOT_CONTRACTS: {
      const { contracts } = action;
      return { ...state, contracts };
    }
    case START_GET_SINGLE_CONTRACT: {
      const { singleContractLoading } = action;
      return {
        ...state,
        singleContractLoading,
      };
    }
    case GOT_SINGLE_CONTRACT: {
      const {
        singleContractLoading,
        singleContractMain,
        singleContractSummary,
        singleContractContractVendors,
        singleContractClassifieds,
        singleContractClassifiedWOs,
        singleContractNetcosts,
        singleContractUnclassifieds,
        singleContractChargeCode,
        singleContractPotentialDelay,        
        singleContractRCPotentialDelay,	
        singleContractPDPSStaffCostActualBudget,
        singleContractSchedules,
      } = action;
      return {
        ...state,
        singleContractLoading,
        singleContractMain,
        singleContractSummary,
        singleContractContractVendors,
        singleContractClassifieds,
        singleContractClassifiedWOs,
        singleContractUnclassifieds,
        singleContractNetcosts,
        singleContractChargeCode,
        singleContractPotentialDelay,        
        singleContractRCPotentialDelay,
        singleContractPDPSStaffCostActualBudget,
        singleContractSchedules,
      };
    }
    case GOT_SINGLE_CONTRACT_CONTRACTVENDOR_PAYMENTS: {
      const { singleContractContractVendorPayments } = action;
      return { ...state, singleContractContractVendorPayments };
    }
    case GOT_SINGLE_CONTRACT_CLASSIFIED_DETAILS: {
      const { singleContractClassifiedDetails } = action;
      return { ...state, singleContractClassifiedDetails };
    }
    case GOT_SINGLE_CONTRACT_CLASSIFIED_DETAILWO: {
      const { singleContractClassifiedDetailWO } = action;
      return { ...state, singleContractClassifiedDetailWO };
    }
    case GOT_SINGLE_CONTRACT_CLASSIFIEDWO_NETCOST: {
      const { singleContractClassifiedWONetCost } = action;
      return { ...state, singleContractClassifiedWONetCost };
    }
    case GOT_NETCOST_DETAILS: {
      const { singleContractNetcostDetails } = action;
      return { ...state, singleContractNetcostDetails };
    }
    case GOT_FINANCIAL_SUMMARY: {
      const { financialSummary } = action;
      return { ...state, singleContractSummary: financialSummary };
    }
    case UPDATED_PERF_BOND: {
      const { bondamt, bondate } = action.values;
      const origFinancialSummary = state.singleContractSummary;
      if (
        origFinancialSummary === null ||
        action.id !== String(origFinancialSummary.id)
      ) {
        // Double-checking id here because it is unlikely but possible for a different contract to be selected in the
        // time that it takes for the performance bond information to be updated.
        return state;
      }
      const newFinancialSummary = { ...origFinancialSummary, bondamt, bondate };
      return { ...state, singleContractSummary: newFinancialSummary };
    }
    case UPDATED_CONTRACT_FINANCIAL_HISTORY: {
      const { singleContractMain } = state;
      if (singleContractMain === null) {
        return state;
      }
      const { contractId, values } = action;
      if (singleContractMain.id !== contractId) {
        return state;
      }
      const newSingleContractMain = { ...singleContractMain, ...values };
      return { ...state, singleContractMain: newSingleContractMain }
    }    
    case GOT_NETCOST_PAYMENT_WO:
      const { netcostPaymentsWo } = action;
      return { ...state, netcostPaymentsWo };      
    case GOT_CLASSIFIED_PAYMENT_WO:
      const { classifiedPaymentsWo } = action;
      return { ...state, classifiedPaymentsWo };

      case GOT_NETCOST_PAYMENT:
        const { netcostPayments } = action;
        return { ...state, netcostPayments };     
    case GOT_CLASSIFIED_PAYMENT:
      const { classifiedPayments } = action;
      return { ...state, classifiedPayments };  
    case GOT_UNCLASSIFIED_PAYMENT:
      const { unclassifiedPayments } = action;
      return { ...state, unclassifiedPayments };

    default:
      return state;
  }
}
