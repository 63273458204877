import { referenceDataByCategory } from '../api_endpoints';
import { acquireTokenPopupAndCallAPI } from '../authentication';

const GOT_ETHNICITIES = 'GOT_ETHNICITIES';

const gotEthnicities = (ethnicities) => ({
  type: GOT_ETHNICITIES,
  ethnicities
});

export const getEthnicities = () => async (dispatch) => {
  try {
    const response = await acquireTokenPopupAndCallAPI((token) => (
      fetch(
        `${referenceDataByCategory('Ethnicity')}`,
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
    ));
    const ethnicities = await response.json();
    dispatch(gotEthnicities(ethnicities));
  }
  catch (error) {
    console.error(error);
  }
};


const initialState = {
  ethnicities: [],
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GOT_ETHNICITIES: {
      const { ethnicities } = action;
      return { ...state, ethnicities };
    }
    default:
      return state;
  }
}
