import { API_ROOT } from "../api_endpoints";
import { acquireTokenPopupAndCallAPI } from "../authentication";
import { getSingleContract } from "./contract";

const GOT_EXTRAWORKWIPS = "GOT_EXTRAWORKWIPS";

const gotExtraWorkWIPs = ExtraWorkWIPs => ({
  type: GOT_EXTRAWORKWIPS,
  ExtraWorkWIPs
});

export const getExtraWorkWIPs = contractId => async dispatch => {
  try {
    const response = await acquireTokenPopupAndCallAPI(token =>
      fetch(`${API_ROOT.extraworkwips}/${contractId}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
    );
    const extraworkwips = await response.json();

    dispatch(gotExtraWorkWIPs(extraworkwips));
  } catch (error) {
    console.error(error);
  }
};

export const addExtraWorkWIP = (ExtraWorkWIPObj) => async dispatch => {
  try {
    await acquireTokenPopupAndCallAPI(token =>
      fetch(`${API_ROOT.extraworkwip}`, {
        method: "POST",
        body: JSON.stringify(ExtraWorkWIPObj),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        }
      })
    );
    dispatch(getExtraWorkWIPs(ExtraWorkWIPObj.contractId));
    dispatch(getSingleContract(ExtraWorkWIPObj.contractId));
  } catch (error) {
    console.error(error);
  }
};

export const editExtraWorkWIP = (ExtraWorkWIPId, patchObj) => async dispatch => {
  try {
    await acquireTokenPopupAndCallAPI(token =>
      fetch(`${API_ROOT.extraworkwip}`, {
        method: "PUT",
        body: JSON.stringify(patchObj),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        }
      })
    );
    dispatch(getExtraWorkWIPs(patchObj.contractId));
    dispatch(getSingleContract(patchObj.contractId));
  } catch (error) {
    console.error(error);
  }
};

const initialState = {
  ExtraWorkWIPs: [],
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GOT_EXTRAWORKWIPS:
      const { ExtraWorkWIPs } = action;
      return { ...state, ExtraWorkWIPs };
    default:
      return state;
  }
}
