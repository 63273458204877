import * as apiActions from "../store/apiActions";
import axios from "axios";
import { acquireTokenPopupAndCallAPI } from "../authentication";
import { toast } from "react-toastify";
const api =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    next(action);
    const { type, payload } = action;
    if (type === apiActions.apiRequestStarted.type) {
      const {
        url,
        data: item,
        method,
        onSuccess,
        onSuccessCallback,
        onSuccessMessage,
        onPrepare,
        successPayload,
        onError,
      } = payload;

      if (onPrepare) {
        dispatch({ type: onPrepare });
      }
      acquireTokenPopupAndCallAPI(async (token) => {
        try {
          const { data } = await axios.request({
            url,
            data: item,
            headers: {
              Authorization: `Bearer ${token}`,
            },
            method,
          });

          // console.log(data);

          if ("ok" in data && !data.ok) {
            const { errorMessage } = data;
            handleError(errorMessage, onError);
            return;
          }

          dispatch(apiActions.apiRequestSuccess(data));

          if (onSuccessCallback) {
            onSuccessCallback(data);
          }

          if (onSuccess) {
            dispatch({
              type: onSuccess,
              payload: successPayload ? successPayload : data,
            });
          }

          //if (onSuccessMessage) toast.success(onSuccessMessage);
        } catch (error) {
          const { message } = error;
          handleError(message, onError);
        }
      });
    }

    const handleError = (message, onError) => {
      dispatch(apiActions.apiRequestFailed(message));
      toast.error(message);
      if (onError) {
        dispatch({
          type: onError,
          payload: message,
        });
      }
    };
  };

export default api;
