import { API_ROOT } from "../api_endpoints";
import { acquireTokenPopupAndCallAPI } from "../authentication";

const GOT_CONTRACT_GOALS = "GOT_CONTRACT_GOALS";

const gotGoals = Goals => ({
  type: GOT_CONTRACT_GOALS,
  Goals
});

export const getGoals = contractId => async dispatch => {
  try {
    const response = await acquireTokenPopupAndCallAPI(token =>
      fetch(`${API_ROOT.goals}/${contractId}`, {

        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
    );
    const goals = await response.json();

    dispatch(gotGoals(goals));
  } catch (error) {
    console.error(error);
  }
};

export const editGoals = (contractId, patchObj) => async dispatch => {
  try {
    await acquireTokenPopupAndCallAPI(token =>
      fetch(`${API_ROOT.goals}/${contractId}`, {
        method: "PATCH",
        body: JSON.stringify(patchObj.patchObj),
        headers: {
          "Content-Type": "application/json-patch+json",
          Authorization: `Bearer ${token}`
        }
      })
    );
    dispatch(getGoals(contractId));
  } catch (error) {
    console.error(error);
  }
};

const initialState = {
  Goals: [],
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GOT_CONTRACT_GOALS: {
      const { Goals } = action;
      return { ...state, Goals };
    }
    default:
      return state;
  }
}
