import { API_ROOT } from "../api_endpoints";
import { acquireTokenPopupAndCallAPI } from '../authentication';

const GOT_FIELD_ORDERS = "GOT_FIELD_ORDERS";
const GOT_SINGLE_FIELD_ORDER = "GOT_SINGLE_FIELD_ORDER";
const GOT_ACTVITY_TASKS = "GOT_ACTVITY_TASKS";
const GOT_SINGLE_ACTIVITY_TASK = "GOT_SINGLE_ACTIVITY_TASK";
const GOT_SINGLE_ACTIVITY_TASK_ERROR = 'GOT_SINGLE_ACTIVITY_TASK_ERROR';
const CLEAR_SINGLE_ACTIVITY_TASK_ERROR = 'CLEAR_SINGLE_ACTIVITY_TASK_ERROR';

const gotFieldOrders = fieldOrders => ({
  type: GOT_FIELD_ORDERS,
  fieldOrders
});

const gotSingleFieldOrder = singleFieldOrder => ({
  type: GOT_SINGLE_FIELD_ORDER,
  singleFieldOrder
});

const gotActivityTasks = activityTasks => ({
  type: GOT_ACTVITY_TASKS,
  activityTasks
});

const gotSingleActivityTask = singleActivityTask => ({
  type: GOT_SINGLE_ACTIVITY_TASK,
  singleActivityTask
});

const gotSingleActivityTaskError = (message) => ({
  type: GOT_SINGLE_ACTIVITY_TASK_ERROR,
  message,
});

export const clearSingleActivityTaskError = () => ({
  type: CLEAR_SINGLE_ACTIVITY_TASK_ERROR,
})

export const getFieldOrders = contractId => async dispatch => {
  try {
    const response = await acquireTokenPopupAndCallAPI((token) => (
      fetch(
        `${API_ROOT.fieldorder}/ByContract/${contractId}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
    ));
    const fieldOrders = await response.json();
    dispatch(gotFieldOrders(fieldOrders));
  } catch (error) {
    console.log("error getting field order");
    console.log(contractId);
    console.error(error);
  }
};

export const getSingleFieldOrder = fieldOrderId => async dispatch => {
  try {
    const response = await acquireTokenPopupAndCallAPI((token) => (
      fetch(`${API_ROOT.fieldOrders}/${fieldOrderId}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
    ))
    const singleFieldOrder = await response.json();
    dispatch(gotSingleFieldOrder(singleFieldOrder));
  } catch (error) {
    console.error(error);
  }
};

export const editFieldOrder = (fieldOrderId, patchObj) => async dispatch => {
  try {
    await acquireTokenPopupAndCallAPI((token) => (
      fetch(`${API_ROOT.fieldOrders}/?id=${fieldOrderId}`, {
        method: "PATCH",
        body: JSON.stringify(patchObj),
        headers: {
          "Content-Type": "application/json-patch+json",
          Authorization: `Bearer ${token}`,
        }
      })
    ));
    dispatch(getSingleFieldOrder(fieldOrderId));
  } catch (error) {
    console.error(error);
  }
};

export const getActivityTasks = fieldOrderId => async dispatch => {
  try {
    const response = await acquireTokenPopupAndCallAPI((token) => (
      fetch(
        `${API_ROOT.activityTasks}/ByFieldorder/${fieldOrderId}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
    ));
    const activityTasks = await response.json();
    dispatch(gotActivityTasks(activityTasks));
  } catch (error) {
    console.error(error);
  }
};

export const getSingleActivityTask = activityTaskId => async dispatch => {
  try {
    const response = await acquireTokenPopupAndCallAPI((token) => (
      fetch(
        `${API_ROOT.activityTasks}/${activityTaskId}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
    ));
    const singleActivityTask = await response.json();
    dispatch(gotSingleActivityTask(singleActivityTask));
  } catch (error) {
    console.error(error);
  }
};

export const editActivityTask = (taskId, patchObj) => async dispatch => {
  try {
    dispatch(clearSingleActivityTaskError());
    const response = await acquireTokenPopupAndCallAPI((token) => (
      fetch(`${API_ROOT.activityTasks}/?id=${taskId}`, {
        method: "PATCH",
        body: JSON.stringify(patchObj),
        headers: {
          "Content-Type": "application/json-patch+json",
          Authorization: `Bearer ${token}`,
        }
      })
    ));
    const { status } = response;
    if (status === 400) {
      const errorMsg = await response.text();
      dispatch(gotSingleActivityTaskError(errorMsg));
    }
    else {
      dispatch(getSingleActivityTask(taskId));
    }
  } catch (error) {
    console.error(error);
  }
};

export const addFieldOrder = (contractId, fieldOrderObj) => async dispatch => {
  fieldOrderObj.contractId = contractId;
  try {
    await acquireTokenPopupAndCallAPI((token) => (
      fetch(`${API_ROOT.fieldOrders}`, {
        method: "POST",
        body: JSON.stringify(fieldOrderObj),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        }
      })
    ));
    dispatch(getFieldOrders(contractId));
  } catch (error) {
    console.error(error);
  }
};

export const addActivityTask = (
  fieldOrderId,
  activityTaskObj
) => async dispatch => {
  activityTaskObj.fieldOrderId = fieldOrderId;
  try {
    dispatch(clearSingleActivityTaskError());
    const response = await acquireTokenPopupAndCallAPI((token) => (
      fetch(`${API_ROOT.activityTasks}`, {
        method: "POST",
        body: JSON.stringify(activityTaskObj),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        }
      })
    ));
    const { status } = response;
    if (status === 400) {
      const errorMsg = await response.text();
      dispatch(gotSingleActivityTaskError(errorMsg));
    }
    else {
      dispatch(getActivityTasks(fieldOrderId));
    }
  } catch (error) {
    console.error(error);
  }
};


const initialState = {
  fieldOrders: [],
  singleFieldOrder: null,
  activityTasks: [],
  singleActivityTask: null,
  singleActivityTaskError: null,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GOT_FIELD_ORDERS: {
      const { fieldOrders } = action;
      return { ...state, fieldOrders };
    }
    case GOT_SINGLE_FIELD_ORDER: {
      const { singleFieldOrder } = action;
      return { ...state, singleFieldOrder };
    }
    case GOT_ACTVITY_TASKS: {
      const { activityTasks } = action;
      return { ...state, activityTasks };
    }
    case GOT_SINGLE_ACTIVITY_TASK: {
      const { singleActivityTask } = action;
      return { ...state, singleActivityTask };
    }
    case GOT_SINGLE_ACTIVITY_TASK_ERROR: {
      const { message } = action;
      return { ...state, singleActivityTaskError: message }
    }
    case CLEAR_SINGLE_ACTIVITY_TASK_ERROR: {
      return { ...state, singleActivityTaskError: null }
    }

    default:
      return state;
  }
}
