import { createSelector, createSlice } from "@reduxjs/toolkit";
import { apiRequestStarted } from "./apiActions";

const slice = createSlice({
  name: "meur",
  initialState: {
    list: [],
    template: {},
    referenceList: [],
    classList: [],
  },
  reducers: {
    itemsLoaded: (
      state,
      { payload: { list, entity, referenceList, classList } }
    ) => {
      state.list.length = 0;
      state.list.push(...list);
      state.template = entity;
      state.referenceList = referenceList;
      state.classList = classList.map((c) => ({
        id: parseInt(c.title),
        description: c.ext1,
      }));
    },

    itemUpdated: ({ list }, { payload }) => {
      const index = list.findIndex((a) => a.id === payload.id);
      if (index >= 0) {
        list[index] = payload;
      }
    },

    itemDeleted: (state, { payload }) => {
      state.list = state.list.filter((a) => a.id !== payload.id);
    },

    itemAdded: (state, { payload }) => {
      state.list.unshift(payload);
    },
  },
});

export default slice.reducer;

const { itemsLoaded, itemUpdated, itemAdded, itemDeleted } = slice.actions;

export const loadItems = (contractId) =>
  apiRequestStarted({
    url: "/api/meur/" + contractId,
    onSuccess: itemsLoaded.type,
  });

export const saveItem = ({ data, onSuccessCallback, onSuccessMessage }) =>
  apiRequestStarted({
    url: "/api/meur/" + data.id,
    method: "patch",
    data,
    onSuccess: itemUpdated.type,
    onSuccessCallback,
    onSuccessMessage,
  });

export const deleteItem = (id) =>
  apiRequestStarted({
    url: "/api/meur/" + id,
    method: "delete",
    onSuccess: itemDeleted.type,
  });

export const addItem = ({ data, onSuccessCallback, onSuccessMessage }) =>
  apiRequestStarted({
    url: "/api/meur/",
    method: "post",
    data,
    onSuccess: itemAdded.type,
    onSuccessCallback,
    onSuccessMessage,
  });

export const getItemById = (id) =>
  createSelector(
    (state) => state.meurs,
    ({ list, template }) => {
      if (id > 0 && list.length > 0) {
        return list.filter((a) => a.id === id)[0];
      }
      return template;
    }
  );

export const getReferenceList = createSelector(
  (state) => state.meurs,
  ({ referenceList }) => referenceList
);

export const getClassificationList = createSelector(
  (state) => state.meurs,
  ({ classList }) => classList
);

export const getContractId = createSelector(
  (state) => state.contract.singleContractMain,
  (main) => (main ? main.id : 0)
);

export const getIsArchived = createSelector(
  (state) => state.contract.singleContractMain,
  (main) => (main ? main.org === "D" : true)
);

export const getUserRoles = createSelector(
  (state) => state.user.me,
  (me) => (me && me.roles ? me.roles : {})
);

const getReferenceLabel = (referenceList, id) => {
  if (id) {
    const reference = referenceList.filter((a) => a.id === id)[0];

    if (reference) {
      return reference.id + " " + reference.description;
    }
  }
};

export const getItems = createSelector(
  (state) => state.meurs,
  ({ list: meurs, referenceList, classList }) => {
    return meurs.map((m) => {
      return {
        ...m,
        trade: getReferenceLabel(referenceList, m.trade),
        class: getReferenceLabel(classList, m.class),
        period: formateDate(m.period),
        todateMonority: getMonorityPercentage(m),
        todateFemale: getFemalePercentage(m),
      };
    });
  }
);

export const getUtilization = (skilled) =>
  createSelector(
    (state) => state.meurs,
    ({ list }) => {
      const filtered = list.filter((a) => a.skilled === skilled);
      const totalHours = filtered.reduce(
        (p, c) => c.maleHour + c.femaleHour + p,
        0
      );

      const totalFemaleHours = filtered.reduce((p, c) => c.femaleHour + p, 0);

      const totalMinorityHours = filtered.reduce(
        (p, c) => getMonorityHours(c) + p,
        0
      );

      let minorityPercentage = 0;
      if (totalHours > 0) {
        minorityPercentage = (totalMinorityHours / totalHours) * 100;
      }

      minorityPercentage = minorityPercentage.toFixed(2);
      let feMalePercentage = 0;

      if (totalHours > 0) {
        feMalePercentage = (totalFemaleHours / totalHours) * 100;
      }

      feMalePercentage = feMalePercentage.toFixed(2);

      return {
        totalHours,
        totalMinorityHours,
        minorityPercentage,
        totalFemaleHours,
        feMalePercentage,
      };
    }
  );

export const getLoading = createSelector(
  (state) => state.contract.singleContractLoading,
  (loading) => loading
);

export const formateDate = (jsonDate) => {
  if (jsonDate != null && jsonDate.length > 0) {
    let dValue = new Date(jsonDate);
    return dValue.toLocaleDateString();
  } else {
    return "";
  }
};

export const getMonorityPercentage = (item) => {
  const total = getMonorityHours(item);

  const allEmployee = parseFloat(item.maleHour) + parseFloat(item.femaleHour);
  if (allEmployee > 0) {
    return ((total / allEmployee) * 100).toFixed(2);
  }
};

const getMonorityHours = (item) => {
  const rows = getRows().filter((r) => r.group);

  const total = rows.reduce(
    (p, c) => parseFloat(item[c.key1]) + parseFloat(item[c.key2]) + p,
    0
  );

  return total;
};

export const getFemalePercentage = (item) => {
  const allEmployee = parseFloat(item.maleHour) + parseFloat(item.femaleHour);

  if (allEmployee > 0) {
    return ((parseFloat(item.femaleHour) / allEmployee) * 100).toFixed(2);
  }
};

export const getMonorityHoursByCategory = (item, isMale) => {
  const rows = getRows().filter((r) => r.group);

  return rows.reduce(
    (p, c) =>
      isMale ? parseFloat(item[c.key1]) + p : parseFloat(item[c.key2]) + p,
    0
  );
};

export const getRows = () => {
  return [
    {
      label: "Hours/All Employees",
      key1: "maleHour",
      key2: "femaleHour",
    },
    {
      label: "Black",
      key1: "blackMaleHour",
      key2: "blackFemaleHour",
      group: "m",
    },
    {
      label: "Hispanic",
      key1: "hispanicMaleHour",
      key2: "hispanicFemaleHour",
      group: "m",
    },
    {
      label: "Asian",
      key1: "asianMaleHour",
      key2: "asianFemaleHour",
      group: "m",
    },
    {
      label: "Native American",
      key1: "nativeMaleHour",
      key2: "nativeFemaleHour",
      group: "m",
    },

    {
      label: "Total Minority Hours",
      key1: "totalMinorityHour",
      key2: "totalMinorityFemaleHour",
      isSummary: true,
    },
    {
      label: "Total Employees",
      key1: "totalMaleHour",
      key2: "totalFemaleHour",
      float: 0,
    },
    {
      label: "Minority Employees",
      key1: "maleMinor",
      key2: "femaleMinor",
      float: 0,
    },
  ];
};
