import { API_ROOT } from "../api_endpoints";
import { acquireTokenPopupAndCallAPI } from "../authentication";

const GOT_PINS = "GOT_PINS";
const GOT_SELECTED_PINS = "GOT_SELECTED_PINS";
const GOT_SINGLE_PIN = "GOT_SINGLE_PIN";
const GOT_PIN_DETAIL = "GOT_PIN_DETAIL";
const GOT_PACC = "GOT_PACC";
const GOT_SELECTED_PACC = "GOT_SELECTED_PACC";
const GOT_SINGLE_PACC = "GOT_SINGLE_PACC";
const GOT_CHANGEORDER = "GOT_CHANGEORDER";
const GOT_SINGLE_CHANGEORDER = "GOT_SINGLE_CHANGEORDER";
const GOT_CHANGEORDER_DETAIL = "GOT_CHANGEORDER_DETAIL";
const GOT_CHANGEORDER_DETAIL_WO = "GOT_CHANGEORDER_DETAIL_WO";
const GOT_CHANGEORDER_PAYMENT = "GOT_CHANGEORDER_PAYMENT";
const GOT_CHANGEORDER_PAYMENT_WO = "GOT_CHANGEORDER_PAYMENT_WO";
const GOT_CHANGEORDER_PAYMENT_CONTRACT = "GOT_CHANGEORDER_PAYMENT_CONTRACT";
//const GOT_ADJUSTMENT_BY_CHARGECODE = "GOT_ADJUSTMENT_BY_CHARGECODE";
const GOT_CHANGEORDER_DETAIL_ADJUST = "GOT_CHANGEORDER_DETAIL_ADJUST";
const GOT_PACC_DETAIL = "GOT_PACC_DETAIL";
const GOT_PACC_DETAIL_WO = "GOT_PACC_DETAIL_WO";
const GOT_PACC_PAYMENT = "GOT_PACC_PAYMENT";

export const getPins = contractId => async dispatch => {
  try {
    const response = await acquireTokenPopupAndCallAPI(token =>
      fetch(`${API_ROOT.pins}/${contractId}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
    );
    const pins = await response.json();

    dispatch(gotPins(pins));
  } catch (error) {
    console.error(error);
  }
};

export const getPinsByPacc = paccId => async dispatch => {
  try {
    const response = await acquireTokenPopupAndCallAPI(token =>
      fetch(`${API_ROOT.paccpins}/${paccId}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
    );
    const pins = await response.json();

    dispatch(gotSelectedPins(pins));
  } catch (error) {
    console.error(error);
  }
};

export const getSinglePin = pinId => async dispatch => {
  try {
    const response = await acquireTokenPopupAndCallAPI(token =>
      fetch(`${API_ROOT.pin}/${pinId}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
    );
    const singlePin = await response.json();
    dispatch(gotSinglePin(singlePin));
  } catch (error) {
    console.error(error);
  }
};

const gotPins = Pins => ({
  type: GOT_PINS,
  Pins
});

const gotSelectedPins = selectedPins => ({
  type: GOT_SELECTED_PINS,
  selectedPins
});

const gotSinglePin = singlePin => ({
  type: GOT_SINGLE_PIN,
  singlePin
});

//Pin Details section
export const getPinDetails = (pinId) => async dispatch => {
  try {
    const response = await acquireTokenPopupAndCallAPI(token =>
      fetch(`${API_ROOT.pinDetails}/${pinId}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
    );
    const pindetails = await response.json();
    dispatch(gotPinDetails(pindetails));
  } catch (error) {
    console.error(error);
  }
};

const gotPinDetails = PinDetails => ({
  type: GOT_PIN_DETAIL,
  PinDetails
});


export const bulkEditPinDetails = (contractId, additions, edits,) => async dispatch => {
  await acquireTokenPopupAndCallAPI(async (token) => {
    const additionReqs = additions.map(entry =>
      fetch(`${API_ROOT.pinDetail}`, {
        method: "POST",
        body: JSON.stringify(entry),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        }
      })
    );
    const editReqs = edits.map(editObj =>
      fetch(`${API_ROOT.pinDetail}/?id=${editObj.pinDetailId}`, {
        method: "PATCH",
        body: JSON.stringify(editObj.patchObj),
        headers: {
          "Content-Type": "application/json-patch+json",
          Authorization: `Bearer ${token}`,
        }
      })
    );
    try {
      await Promise.all([Promise.all(additionReqs), Promise.all(editReqs)]);
      dispatch(getPins(contractId));
      //dispatch(getPinDetails(pinId));
    } catch (error) {
      console.error(error);
    }
  });
};

export const addPin = (contractId, pinObj) => async dispatch => {
  pinObj.contractId = contractId;

  try {
    await acquireTokenPopupAndCallAPI(token =>
      fetch(`${API_ROOT.pin}`, {
        method: "POST",
        body: JSON.stringify(pinObj.editableFields),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        }
      })
    );
    dispatch(getPins(contractId));
  } catch (error) {
    console.error(error);
  }
};

export const editPin = (contractId, pinId, patchObj) => async dispatch => {
  try {
    await acquireTokenPopupAndCallAPI(token =>
      fetch(`${API_ROOT.pin}/?id=${pinId}`, {
        method: "PATCH",
        body: JSON.stringify(patchObj.patchObj),
        headers: {
          "Content-Type": "application/json-patch+json",
          Authorization: `Bearer ${token}`
        }
      })
    );
    //dispatch(getSinglePin(pinId));
    dispatch(getPins(contractId));
    dispatch(getPacc(contractId));
  } catch (error) {
    console.error(error);
  }
};

// pacc section
export const getPacc = contractId => async dispatch => {
  try {
    const response = await acquireTokenPopupAndCallAPI(token =>
      fetch(`${API_ROOT.paccs}/${contractId}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
    );
    const pacc = await response.json();

    dispatch(gotPacc(pacc));
  } catch (error) {
    console.error(error);
  }
};

export const getPaccByChangeorder = changeorderId => async dispatch => {
  try {
    const response = await acquireTokenPopupAndCallAPI(token =>
      fetch(`${API_ROOT.changeorderpacc}/${changeorderId}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
    );
    const pacc = await response.json();

    dispatch(gotSelectedPacc(pacc));
  } catch (error) {
    console.error(error);
  }
};

export const getSinglePacc = paccId => async dispatch => {
  try {
    const response = await acquireTokenPopupAndCallAPI(token =>
      fetch(`${API_ROOT.pacc}/${paccId}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
    );
    const singlePacc = await response.json();
    dispatch(gotSinglePacc(singlePacc));
  } catch (error) {
    console.error(error);
  }
};

const gotPacc = Pacc => ({
  type: GOT_PACC,
  Pacc
});

const gotSelectedPacc = selectedPacc => ({
  type: GOT_SELECTED_PACC,
  selectedPacc
});

const gotSinglePacc = singlePacc => ({
  type: GOT_SINGLE_PACC,
  singlePacc
});

export const addPacc = (contractId, paccObj) => async dispatch => {
  paccObj.contractId = contractId;
  try {
    const response = await acquireTokenPopupAndCallAPI(token =>
      //await acquireTokenPopupAndCallAPI(token =>
      fetch(`${API_ROOT.pacc}`, {
        method: "POST",
        body: JSON.stringify(paccObj.AdditionFields),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        }
      })
    );
    const newpacc = await response.json();
    return (newpacc);
    //dispatch(getPacc(contractId));
  } catch (error) {
    console.error(error);
  }
};

export const editPacc = (contractId, paccId, patchObj) => async dispatch => {
  try {
    await acquireTokenPopupAndCallAPI(token =>
      fetch(`${API_ROOT.pacc}/?id=${paccId}`, {
        method: "PATCH",
        body: JSON.stringify(patchObj.patchObj),
        headers: {
          "Content-Type": "application/json-patch+json",
          Authorization: `Bearer ${token}`
        }
      })
    );
    //dispatch(getSinglePacc(paccId));
    dispatch(getPacc(contractId));
    dispatch(getChangeorder(contractId));
  } catch (error) {
    console.error(error);
  }
};

//end pacc section

//Changeorder section
export const getChangeorder = contractId => async dispatch => {
  try {
    const response = await acquireTokenPopupAndCallAPI(token =>
      fetch(`${API_ROOT.changeorders}/${contractId}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
    );
    const changeorder = await response.json();
    dispatch(gotChangeorder(changeorder));
  } catch (error) {
    console.error(error);
  }
};

export const getSingleChangeorder = changeorderId => async dispatch => {
  try {
    const response = await acquireTokenPopupAndCallAPI(token =>
      fetch(`${API_ROOT.changeorder}/${changeorderId}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
    );
    const singleChangeorder = await response.json();
    dispatch(gotSingleChangeorder(singleChangeorder));
  } catch (error) {
    console.error(error);
  }
};

const gotChangeorder = Changeorder => ({
  type: GOT_CHANGEORDER,
  Changeorder
});

const gotSingleChangeorder = singleChangeorder => ({
  type: GOT_SINGLE_CHANGEORDER,
  singleChangeorder
});

export const addChangeorder = (contractId, changeorderObj) => async dispatch => {
  changeorderObj.contractId = contractId;

  try {
    //await acquireTokenPopupAndCallAPI(token =>
    const response = await acquireTokenPopupAndCallAPI(token =>
      fetch(`${API_ROOT.changeorder}`, {
        method: "POST",
        body: JSON.stringify(changeorderObj.editableFields),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        }
      })
    );
    //dispatch(getChangeorder(contractId));
    const newco = await response.json();
    return (newco);
  } catch (error) {
    console.error(error);
  }
};

export const editChangeorder = (contractId, changeorderId, patchObj) => async dispatch => {
  try {
    await acquireTokenPopupAndCallAPI(token =>
      fetch(`${API_ROOT.changeorder}/?id=${changeorderId}`, {
        method: "PATCH",
        body: JSON.stringify(patchObj.patchObj),
        headers: {
          "Content-Type": "application/json-patch+json",
          Authorization: `Bearer ${token}`
        }
      })
    );
    //dispatch(getSingleChangeorder(changeorderId));
    dispatch(getChangeorder(contractId));
  } catch (error) {
    console.error(error);
  }
};
//end changeorder section

//get change order payments 
export const getChangeorderPayments = (changeorderId) => async dispatch => {
  try {
    const response = await acquireTokenPopupAndCallAPI(token =>
      fetch(`${API_ROOT.changeorderPayment}/${changeorderId}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
    );
    const changeorderpayments = await response.json();
    dispatch(gotChangeorderPayments(changeorderpayments));
  } catch (error) {
    console.error(error);
  }
};

const gotChangeorderPayments = ChangeorderPayments => ({
  type: GOT_CHANGEORDER_PAYMENT,
  ChangeorderPayments
});
//

//get change order payments by WO
export const getChangeorderPaymentsbyWo = (classifiedWoId) => async dispatch => {
  try {
    const response = await acquireTokenPopupAndCallAPI(token =>
      fetch(`${API_ROOT.changeorderPaymentByClassifiedWo}/${classifiedWoId}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
    );
    const changeorderpaymentswo = await response.json();
    dispatch(gotChangeorderPaymentsWo(changeorderpaymentswo));
  } catch (error) {
    console.error(error);
  }
};

const gotChangeorderPaymentsWo = changeorderPaymentsWo => ({
  type: GOT_CHANGEORDER_PAYMENT_WO,
  changeorderPaymentsWo
});
//

//get change order payments by ContractId
export const getChangeorderPaymentsbyContractId = (ContractId) => async dispatch => {
  try {
    const response = await acquireTokenPopupAndCallAPI(token =>
      fetch(`${API_ROOT.changeorderPaymentByContractId}/${ContractId}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
    );
    const changeorderpaymentscontract = await response.json();
    dispatch(gotChangeorderPaymentsContract(changeorderpaymentscontract));
  } catch (error) {
    console.error(error);
  }
};

const gotChangeorderPaymentsContract = changeorderPaymentsContract => ({
  type: GOT_CHANGEORDER_PAYMENT_CONTRACT,
  changeorderPaymentsContract
});
//

//Changeorder Details section
export const getChangeorderDetails = (changeorderId) => async dispatch => {
  try {
    const response = await acquireTokenPopupAndCallAPI(token =>
      fetch(`${API_ROOT.changeorderDetails}/${changeorderId}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
    );
    const changeorderdetails = await response.json();
    dispatch(gotChangeorderDetails(changeorderdetails));
  } catch (error) {
    console.error(error);
  }
};


//Adjustment By Change order and Chargecode
export const getAdjustmentByChangeorderAndChargecode = (changeorderId, ChargecodeId) => async dispatch => {
  try {
    const response = await acquireTokenPopupAndCallAPI(token =>
      fetch(`${API_ROOT.AdjustmentByChargecode}/?ChangeorderId=${changeorderId}&&ChargecodeId=${ChargecodeId}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
    );
    const changeorderdetailsadjustmnet = await response.json();
    dispatch(gotChangeorderDetailsAdjust(changeorderdetailsadjustmnet));
  } catch (error) {
    console.error(error);
  }
};

const gotChangeorderDetailsAdjust = ChangeorderDetailsAdjust => ({
  type: GOT_CHANGEORDER_DETAIL_ADJUST,
  ChangeorderDetailsAdjust
});


const gotChangeorderDetails = ChangeorderDetails => ({
  type: GOT_CHANGEORDER_DETAIL,
  ChangeorderDetails
});

export const getChangeorderDetailsWO = (id) => async (dispatch) => {
  try {
    const response = await acquireTokenPopupAndCallAPI((token) =>
      fetch(`${API_ROOT.changeorderDetailWO}/${id}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
    );
    const ChangeorderDetailsWO = await response.json();
    dispatch(
      gotChangeorderDetailsWO(ChangeorderDetailsWO)
    );
  } catch (error) {
    console.error(error);
  }
};

const gotChangeorderDetailsWO = ChangeorderDetailsWO => ({
  type: GOT_CHANGEORDER_DETAIL_WO,
  ChangeorderDetailsWO
});

export const addChangeorderDetail = (contractId, changeorderId, changeorderObj) => async dispatch => {
  changeorderObj.contractId = contractId;
  changeorderObj.changeorderId = changeorderId;

  try {
    await acquireTokenPopupAndCallAPI(token =>
      fetch(`${API_ROOT.changeorderDetail}`, {
        method: "POST",
        body: JSON.stringify(changeorderObj.editableFields),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        }
      })
    );
    dispatch(getChangeorder(contractId));
    dispatch(getChangeorderDetails(changeorderId));
  } catch (error) {
    console.error(error);
  }
};

export const editChangeorderDetail = (contractId, changeorderId, changeorderdetailId, patchObj) => async dispatch => {
  try {
    await acquireTokenPopupAndCallAPI(token =>
      fetch(`${API_ROOT.changeorderDetail}/?id=${changeorderdetailId}`, {
        method: "PATCH",
        body: JSON.stringify(patchObj.patchObj),
        headers: {
          "Content-Type": "application/json-patch+json",
          Authorization: `Bearer ${token}`
        }
      })
    );
    //dispatch(getSingleChangeorder(changeorderId));
    dispatch(getChangeorder(contractId));
    dispatch(getChangeorderDetails(changeorderId));
  } catch (error) {
    console.error(error);
  }
};


export const bulkEditChangeorderDetails = (contractId, additions, edits,) => async dispatch => {
  await acquireTokenPopupAndCallAPI(async (token) => {
    const additionReqs = additions.map(entry =>
      fetch(`${API_ROOT.changeorderDetail}`, {
        method: "POST",
        body: JSON.stringify(entry),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        }
      })
    );
    const editReqs = edits.map(editObj =>
      fetch(`${API_ROOT.changeorderDetail}/?id=${editObj.changeorderDetailId}`, {
        method: "PATCH",
        body: JSON.stringify(editObj.patchObj),
        headers: {
          "Content-Type": "application/json-patch+json",
          Authorization: `Bearer ${token}`,
        }
      })
    );
    try {
      await Promise.all([Promise.all(additionReqs), Promise.all(editReqs)]);
      dispatch(getChangeorder(contractId));
      //dispatch(getChangeorderDetails(changeorderId));
    } catch (error) {
      console.error(error);
    }
  });
};
//end changeorder Details section

const initialState = {
  Pins: [],  
  PinDetails: [],
  Pacc: [],
  Changeorder: [],
  ChangeorderDetails: [],
  ChangeorderDetailsAdjust: [],
  PaccByChangeorder: [],
  singlePacc: null,
  singleChangeorder: null,

  PaccDetails: [],  
  changeorderPaymentsWo: [],
  changeorderPaymentsContract: [],
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GOT_PINS:
      const { Pins } = action;
      return { ...state, Pins };
    case GOT_SINGLE_PIN:
      const { singlePin } = action;
      return { ...state, singlePin };
      
    case GOT_PIN_DETAIL:
      const { PinDetails } = action;
      return { ...state, PinDetails };

    case GOT_PACC:
      const { Pacc } = action;
      return { ...state, Pacc };
    case GOT_SINGLE_PACC:
      const { singlePacc } = action;
      return { ...state, singlePacc };
    case GOT_SELECTED_PINS:
      const { selectedPins } = action;
      return { ...state, selectedPins };
    case GOT_SELECTED_PACC:
      const { selectedPacc } = action;
      return { ...state, selectedPacc };
    case GOT_CHANGEORDER:
      const { Changeorder } = action;
      return { ...state, Changeorder };
    case GOT_SINGLE_CHANGEORDER:
      const { singleChangeorder } = action;
      return { ...state, singleChangeorder };
    case GOT_CHANGEORDER_DETAIL:
      const { ChangeorderDetails } = action;
      return { ...state, ChangeorderDetails };
    case GOT_CHANGEORDER_DETAIL_WO:
      const { ChangeorderDetailsWO } = action;
      return { ...state, ChangeorderDetailsWO };



    case GOT_CHANGEORDER_PAYMENT:
      const { ChangeorderPayments } = action;
      return { ...state, ChangeorderPayments };

    case GOT_CHANGEORDER_DETAIL_ADJUST:
      const { ChangeorderDetailsAdjust } = action;
      return { ...state, ChangeorderDetailsAdjust };



    case GOT_PACC_DETAIL:
      const { PaccDetails } = action;
      return { ...state, PaccDetails };
    case GOT_PACC_DETAIL_WO:
      const { PaccDetailsWO } = action;
      return { ...state, PaccDetailsWO };
    case GOT_PACC_PAYMENT:
      const { PaccPayments } = action;
      return { ...state, PaccPayments };
      
    case GOT_CHANGEORDER_PAYMENT_WO:
      const { changeorderPaymentsWo } = action;
      return { ...state, changeorderPaymentsWo };
    case GOT_CHANGEORDER_PAYMENT_CONTRACT:
      const { changeorderPaymentsContract } = action;
      return { ...state, changeorderPaymentsContract };
    default:
      return state;
  }
}



//get pacc payments 
export const getPaccPayments = (paccId) => async dispatch => {
  try {
    const response = await acquireTokenPopupAndCallAPI(token =>
      fetch(`${API_ROOT.paccPayment}/${paccId}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
    );
    const paccpayments = await response.json();
    dispatch(gotPaccPayments(paccpayments));
  } catch (error) {
    console.error(error);
  }
};

const gotPaccPayments = PaccPayments => ({
  type: GOT_PACC_PAYMENT,
  PaccPayments
});
//

//Pacc Details section
export const getPaccDetails = (paccId) => async dispatch => {
  try {
    const response = await acquireTokenPopupAndCallAPI(token =>
      fetch(`${API_ROOT.paccDetails}/${paccId}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
    );
    const paccdetails = await response.json();
    dispatch(gotPaccDetails(paccdetails));
  } catch (error) {
    console.error(error);
  }
};

const gotPaccDetails = PaccDetails => ({
  type: GOT_PACC_DETAIL,
  PaccDetails
});

export const getPaccDetailsWO = (id) => async (dispatch) => {
  try {
    const response = await acquireTokenPopupAndCallAPI((token) =>
      fetch(`${API_ROOT.paccDetailWO}/${id}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
    );
    const PaccDetailsWO = await response.json();
    dispatch(
      gotPaccDetailsWO(PaccDetailsWO)
    );
  } catch (error) {
    console.error(error);
  }
};

const gotPaccDetailsWO = PaccDetailsWO => ({
  type: GOT_PACC_DETAIL_WO,
  PaccDetailsWO
});

export const addPaccDetail = (contractId, paccId, paccObj) => async dispatch => {
  paccObj.contractId = contractId;
  paccObj.paccId = paccId;

  try {
    await acquireTokenPopupAndCallAPI(token =>
      fetch(`${API_ROOT.paccDetail}`, {
        method: "POST",
        body: JSON.stringify(paccObj.editableFields),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        }
      })
    );
    dispatch(getPacc(contractId));
    dispatch(getPaccDetails(paccId));
  } catch (error) {
    console.error(error);
  }
};

export const editPaccDetail = (contractId, paccId, paccdetailId, patchObj) => async dispatch => {
  try {
    await acquireTokenPopupAndCallAPI(token =>
      fetch(`${API_ROOT.paccDetail}/?id=${paccdetailId}`, {
        method: "PATCH",
        body: JSON.stringify(patchObj.patchObj),
        headers: {
          "Content-Type": "application/json-patch+json",
          Authorization: `Bearer ${token}`
        }
      })
    );

    dispatch(getPacc(contractId));
    dispatch(getPaccDetails(paccId));
  } catch (error) {
    console.error(error);
  }
};


export const bulkEditPaccDetails = (contractId, paccId, additions, edits,) => async dispatch => {
  await acquireTokenPopupAndCallAPI(async (token) => {
    const additionReqs = additions.map(entry =>
      fetch(`${API_ROOT.paccDetail}`, {
        method: "POST",
        body: JSON.stringify(entry),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        }
      })
    );
    const editReqs = edits.map(editObj =>
      fetch(`${API_ROOT.paccDetail}/?id=${editObj.paccDetailId}`, {
        method: "PATCH",
        body: JSON.stringify(editObj.patchObj),
        headers: {
          "Content-Type": "application/json-patch+json",
          Authorization: `Bearer ${token}`,
        }
      })
    );
    try {
      const test = await Promise.all([Promise.all(additionReqs), Promise.all(editReqs)]);
     
      dispatch(getPacc(contractId));
      dispatch(getPaccDetails(paccId));
      return test.length;

    } catch (error) {
      console.error(error);
    }
  });
};
//end pacc Details section

