import { API_ROOT } from '../api_endpoints';
import { getReplacePatchObj } from '../util';
import { acquireTokenPopupAndCallAPI } from '../authentication';

const GOT_TIME_MATERIAL_ENTRIES = 'GOT_TIME_MATERIAL_ENTRIES';
const GOT_UNIT_PRICE_ENTRIES = 'GOT_UNIT_PRICE_ENTRIES';
const GOT_SINGLE_UNIT_PRICE_ENTRY = 'GOT_SINGLE_UNIT_PRICE_ENTRY';
const GOT_SINGLE_UNIT_PRICE_DETAIL_ENTRY = 'GOT_SINGLE_UNIT_PRICE_DETAIL_ENTRY';
const GOT_UNIT_PRICE_DETAIL_ENTRIES = 'GOT_UNIT_PRICE_DETAIL_ENTRIES';
const GOT_CHANGED_UNIT_PRICE_ENTRY = 'GOT_CHANGED_UNIT_PRICE_ENTRY';
const CLEAR_CHANGED_UNIT_PRICE_ENTRY = 'CLEAR_CHANGED_UNIT_PRICE_ENTRY';
const GOT_SINGLE_UNIT_PRICE_DETAIL_ENTRY_ERROR = 'GOT_SINGLE_UNIT_PRICE_DETAIL_ENTRY_ERROR';
const CLEAR_SINGLE_UNIT_PRICE_DETAIL_ENTRY_ERROR = 'CLEAR_SINGLE_UNIT_PRICE_DETAIL_ENTRY_ERROR';
const SET_UNIT_PRICE_DETAIL_API_STATUS = 'SET_UNIT_PRICE_DETAIL_API_STATUS';

const gotTimeMaterialEntries = (timeMaterialEntries) => ({
  timeMaterialEntries,
  type: GOT_TIME_MATERIAL_ENTRIES,
});

const gotUnitPriceEntries = (unitPriceEntries) => ({
  unitPriceEntries,
  type: GOT_UNIT_PRICE_ENTRIES,
});

const gotSingleUnitPriceEntry = (singleUnitPriceEntry) => ({
  singleUnitPriceEntry,
  type: GOT_SINGLE_UNIT_PRICE_ENTRY,
});

const gotSingleUnitPriceDetailEntry = (singleUnitPriceDetailEntry) => ({
  singleUnitPriceDetailEntry,
  type: GOT_SINGLE_UNIT_PRICE_DETAIL_ENTRY,
})

const gotUnitPriceDetailEntries = (unitPriceDetailEntries) => ({
  unitPriceDetailEntries,
  type: GOT_UNIT_PRICE_DETAIL_ENTRIES,
});

const gotChangedUnitPriceEntry = (changedUnitPriceEntry) => ({
  changedUnitPriceEntry,
  type: GOT_CHANGED_UNIT_PRICE_ENTRY,
});

const gotSingleUnitPriceDetailEntryError = (message) => ({
  type: GOT_SINGLE_UNIT_PRICE_DETAIL_ENTRY_ERROR,
  message,
});

export const clearSingleUnitPriceDetailEntryError = () => ({
  type: CLEAR_SINGLE_UNIT_PRICE_DETAIL_ENTRY_ERROR,
});

export const clearChangedUnitPriceEntry = () => ({
  type: CLEAR_CHANGED_UNIT_PRICE_ENTRY,
});

export const setUnitPriceDetailApiStatus = (status) => ({
  type: SET_UNIT_PRICE_DETAIL_API_STATUS,
  status
});

export const getTimeMaterialEntries = (workOrderDetailId) => async (dispatch) => {
  try {
    const response = await acquireTokenPopupAndCallAPI((token) => (
      fetch(
        `${API_ROOT.unitPriceByCategory}?code=CO&id=${workOrderDetailId}`,
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
    ));
    const timeMaterialEntries = await response.json();
    dispatch(gotTimeMaterialEntries(timeMaterialEntries));
  }
  catch (error) {
    console.error(error);
  }
};

export const addUnitPriceDetailEntry = (entryObj, workOrderDetailId, code) => (
  async (dispatch) => {
    try {
      dispatch(clearChangedUnitPriceEntry());
      dispatch(setUnitPriceDetailApiStatus('PENDING'));
      const response = await acquireTokenPopupAndCallAPI((token) => (
        fetch(
          `${API_ROOT.unitPrice}`,
          {
            method: 'POST',
            body: JSON.stringify(entryObj),
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
          }
        )
      ));
      const { status } = response;
      if (status === 400) {
        const errorMessage = await response.text();
        dispatch(gotSingleUnitPriceDetailEntryError(errorMessage));
        dispatch(setUnitPriceDetailApiStatus('ERROR'));
      }
      else {
        if (code === 'UC'){
          const unitPriceDetailObj = await response.json();
          dispatch(gotSingleUnitPriceDetailEntry(unitPriceDetailObj));
        }
        else {
          dispatch(getTimeMaterialEntries(workOrderDetailId));
        }
        dispatch(setUnitPriceDetailApiStatus('SUCCESS'));
      }
    }
    catch (error) {
      console.error(error);
    }
  }
);

export const editUnitPriceDetailEntry = (
  entryObj, unitPriceItemId, workOrderDetailId, code,
) => (
  async (dispatch) => {
    const patchObj = getReplacePatchObj(entryObj);
    try {
      dispatch(clearChangedUnitPriceEntry());
      dispatch(setUnitPriceDetailApiStatus('PENDING'));
      const response = await acquireTokenPopupAndCallAPI((token) => (
        fetch(
          `${API_ROOT.unitPrice}/?id=${unitPriceItemId}`,
          {
            method: "PATCH",
            body: JSON.stringify(patchObj),
            headers: {
              "Content-Type": "application/json-patch+json",
              Authorization: `Bearer ${token}`,
            }
          },
        )
      ));
      const { status } = response;
      if (status === 400) {
        const errorMessage = await response.text();
        dispatch(gotSingleUnitPriceDetailEntryError(errorMessage));
        dispatch(setUnitPriceDetailApiStatus('ERROR'));
      }
      else {
        if (code === 'UC'){
          const unitPriceDetailObj = await response.json();
          dispatch(gotSingleUnitPriceDetailEntry(unitPriceDetailObj));
        }
        else {
          dispatch(getTimeMaterialEntries(workOrderDetailId));
        }
        dispatch(setUnitPriceDetailApiStatus('SUCCESS'));
      }
    }
    catch (error) {
      console.error(error);
    }
  }
);

export const getUnitPriceEntries = (workOrderDetailId) => async (dispatch) => {
  try {
    const response = await acquireTokenPopupAndCallAPI((token) => (
      fetch(
        API_ROOT.unitPriceByWorkOrderDetail(workOrderDetailId),
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
    ));
    const unitPriceEntries = await response.json();
    dispatch(gotUnitPriceEntries(unitPriceEntries));
  }
  catch (error) {
    console.error(error);
  }
};

export const getSingleUnitPriceEntry = (id) => async (dispatch) => {
  try {
    const response = await acquireTokenPopupAndCallAPI((token) => (
      fetch(
        API_ROOT.unitPriceById(id),
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
    ));
    const singleUnitPriceEntry = await response.json();
    dispatch(gotSingleUnitPriceEntry(singleUnitPriceEntry));
  }
  catch (error) {
    console.error(error);
  }
};

export const getUnitPriceDetailEntriesByUnitPrice = (id) => async (dispatch) => {
  try {
    const response = await acquireTokenPopupAndCallAPI((token) => (
      fetch(
        API_ROOT.unitPriceDetailsByUnitPriceId(id),
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
    ));
    const unitPriceDetailEntries = await response.json();
    dispatch(gotUnitPriceDetailEntries(unitPriceDetailEntries));
  }
  catch (error) {
    console.error(error);
  }
};

export const addUnitPrice = (bodyObj) => async (dispatch) => {
  try {
    const response = await acquireTokenPopupAndCallAPI((token) => (
      fetch(
        API_ROOT.addUnitPrice,
        {
          method: 'POST',
          body: JSON.stringify(bodyObj),
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      )
    ));
    const newEntryObj = await response.json();
    dispatch(gotChangedUnitPriceEntry(newEntryObj));
  }
  catch (error) {
    console.error(error);
  }
};

export const editUnitPrice = (patchObj, id) => async (dispatch) => {
  try {
    const response = await acquireTokenPopupAndCallAPI((token) => (
      fetch(
        API_ROOT.editUnitPrice(id),
        {
          method: 'PATCH',
          body: JSON.stringify(patchObj),
          headers: {
            "Content-Type": "application/json-patch+json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
    ));
    const newEntryObj = await response.json();
    dispatch(gotChangedUnitPriceEntry(newEntryObj));
  }
  catch (error) {
    console.error(error);
  }
}

export const getSingleUnitPriceDetailEntry = (id) => async (dispatch) => {
  try {
    const response = await acquireTokenPopupAndCallAPI((token) => (
      fetch(
        API_ROOT.unitPriceDetailById(id),
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
    ));
    const singleUnitPriceDetailEntry = await response.json();
    dispatch(gotSingleUnitPriceDetailEntry(singleUnitPriceDetailEntry));
  }
  catch (error) {
    console.error(error);
  }
}

const initialState = {
  timeMaterialEntries: [],
  unitPriceEntries: [],
  singleUnitPriceEntry: null,
  unitPriceDetailEntries: [],
  singleUnitPriceDetailEntry: null,
  changedUnitPriceEntry: null,
  singleUnitPriceDetailEntryError: null,
  unitPriceDetailApiStatus: 'IDLE'
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GOT_TIME_MATERIAL_ENTRIES: {
      const { timeMaterialEntries } = action;
      return { ...state, timeMaterialEntries };
    }
    case GOT_UNIT_PRICE_ENTRIES: {
      const { unitPriceEntries } = action;
      return { ...state, unitPriceEntries };
    }
    case GOT_SINGLE_UNIT_PRICE_ENTRY: {
      const { singleUnitPriceEntry } = action;
      return { ...state, singleUnitPriceEntry };
    }
    case GOT_UNIT_PRICE_DETAIL_ENTRIES: {
      const { unitPriceDetailEntries } = action;
      return { ...state, unitPriceDetailEntries };
    }
    case GOT_SINGLE_UNIT_PRICE_DETAIL_ENTRY: {
      const { singleUnitPriceDetailEntry } = action;
      return { ...state, singleUnitPriceDetailEntry };
    }
    case GOT_CHANGED_UNIT_PRICE_ENTRY: {
      const { changedUnitPriceEntry } = action;
      return { ...state, changedUnitPriceEntry };
    }
    case CLEAR_CHANGED_UNIT_PRICE_ENTRY: {
      return { ...state, changedUnitPriceEntry: null };
    }
    case GOT_SINGLE_UNIT_PRICE_DETAIL_ENTRY_ERROR: {
      const { message } = action;
      return { ...state, singleUnitPriceDetailEntryError: message };
    }
    case CLEAR_SINGLE_UNIT_PRICE_DETAIL_ENTRY_ERROR: {
      return { ...state, singleUnitPriceDetailEntryError: null };
    }
    case SET_UNIT_PRICE_DETAIL_API_STATUS: {
      const { status } = action;
      return { ...state, unitPriceDetailApiStatus: status };
    }
    default:
      return state;
  }
}
