import { API_ROOT } from "../api_endpoints";
import { acquireTokenPopupAndCallAPI } from "../authentication";

const GOT_FINS = "GOT_FINS";
const GOT_SELECTED_FINS = "GOT_SELECTED_FINS";
const GOT_SINGLE_FIN = "GOT_SINGLE_FIN";
const GOT_PAFI = "GOT_PAFI";
const GOT_SELECTED_PAFI = "GOT_SELECTED_PAFI";
const GOT_SINGLE_PAFI = "GOT_SINGLE_PAFI";
const GOT_FIELDORDER = "GOT_FIELDORDER";
const GOT_SINGLE_FIELDORDER = "GOT_SINGLE_FIELDORDER";
const GOT_FIELDORDER_DETAIL = "GOT_FIELDORDER_DETAIL";
const GOT_FIELDORDER_DETAIL_WO = "GOT_CHANGEORDER_DETAIL_WO";
const GOT_FIELDORDER_PAYMENT = "GOT_FIELDORDER_PAYMENT";
const GOT_FIELDORDER_PAYMENT_WO = "GOT_FIELDORDER_PAYMENT_WO";
const GOT_FIELDORDER_PAYMENT_CONTRACT = "GOT_FIELDORDER_PAYMENT_CONTRACT";

const GOT_PAFI_DETAIL = "GOT_PAFI_DETAIL";
const GOT_PAFI_DETAIL_WO = "GOT_PAFI_DETAIL_WO";
const GOT_PAFI_PAYMENT = "GOT_PAFI_PAYMENT";

export const getFins = contractId => async dispatch => {
  try {
    const response = await acquireTokenPopupAndCallAPI(token =>
      fetch(`${API_ROOT.fins}/${contractId}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
    );
    const fins = await response.json();

    dispatch(gotFins(fins));
  } catch (error) {
    console.error(error);
  }
};

export const getFinsByPafi = pafiId => async dispatch => {
  try {
    const response = await acquireTokenPopupAndCallAPI(token =>
      fetch(`${API_ROOT.pafifins}/${pafiId}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
    );
    const fins = await response.json();

    dispatch(gotSelectedFins(fins));
  } catch (error) {
    console.error(error);
  }
};

export const getSingleFin = finId => async dispatch => {
  try {
    const response = await acquireTokenPopupAndCallAPI(token =>
      fetch(`${API_ROOT.fin}/${finId}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
    );
    const singleFin = await response.json();
    dispatch(gotSingleFin(singleFin));
  } catch (error) {
    console.error(error);
  }
};

const gotFins = Fins => ({
  type: GOT_FINS,
  Fins
});

const gotSelectedFins = selectedFins => ({
  type: GOT_SELECTED_FINS,
  selectedFins
});

const gotSingleFin = singleFin => ({
  type: GOT_SINGLE_FIN,
  singleFin
});

export const addFin = (contractId, finObj) => async dispatch => {
  finObj.contractId = contractId;

  try {
    await acquireTokenPopupAndCallAPI(token =>
      fetch(`${API_ROOT.fin}`, {
        method: "POST",
        body: JSON.stringify(finObj.editableFields),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        }
      })
    );
    dispatch(getFins(contractId));
  } catch (error) {
    console.error(error);
  }
};

export const editFin = (contractId, finId, patchObj) => async dispatch => {
  try {
    await acquireTokenPopupAndCallAPI(token =>
      fetch(`${API_ROOT.fin}/?id=${finId}`, {
        method: "PATCH",
        body: JSON.stringify(patchObj.patchObj),
        headers: {
          "Content-Type": "application/json-patch+json",
          Authorization: `Bearer ${token}`
        }
      })
    );
    //dispatch(getSingleFin(finId));
    dispatch(getFins(contractId));
    dispatch(getPafi(contractId));
  } catch (error) {
    console.error(error);
  }
};

// pafi section
export const getPafi = contractId => async dispatch => {
  try {
    const response = await acquireTokenPopupAndCallAPI(token =>
      fetch(`${API_ROOT.pafis}/${contractId}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
    );
    const pafi = await response.json();

    dispatch(gotPafi(pafi));
  } catch (error) {
    console.error(error);
  }
};

export const getPafiByFieldorder = fieldorderId => async dispatch => {
  try {
    const response = await acquireTokenPopupAndCallAPI(token =>
      fetch(`${API_ROOT.fieldorderpafi}/${fieldorderId}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
    );
    const pafi = await response.json();

    dispatch(gotSelectedPafi(pafi));
  } catch (error) {
    console.error(error);
  }
};

export const getSinglePafi = pafiId => async dispatch => {
  try {
    const response = await acquireTokenPopupAndCallAPI(token =>
      fetch(`${API_ROOT.pafi}/${pafiId}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
    );
    const singlePafi = await response.json();
    dispatch(gotSinglePafi(singlePafi));
  } catch (error) {
    console.error(error);
  }
};

const gotPafi = Pafi => ({
  type: GOT_PAFI,
  Pafi
});

const gotSelectedPafi = selectedPafi => ({
  type: GOT_SELECTED_PAFI,
  selectedPafi
});

const gotSinglePafi = singlePafi => ({
  type: GOT_SINGLE_PAFI,
  singlePafi
});

export const addPafi = (contractId, pafiObj) => async dispatch => {
  pafiObj.contractId = contractId;
  try {
    const response = await acquireTokenPopupAndCallAPI(token =>
      fetch(`${API_ROOT.pafi}`, {
        method: "POST",
        body: JSON.stringify(pafiObj.AdditionFields),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        }
      })
    );
    const newpafi = await response.json();
    return (newpafi);
    //dispatch(getPafi(contractId));
  } catch (error) {
    console.error(error);
  }
};

export const editPafi = (contractId, pafiId, patchObj) => async dispatch => {
  try {
    await acquireTokenPopupAndCallAPI(token =>
      fetch(`${API_ROOT.pafi}/?id=${pafiId}`, {
        method: "PATCH",
        body: JSON.stringify(patchObj.patchObj),
        headers: {
          "Content-Type": "application/json-patch+json",
          Authorization: `Bearer ${token}`
        }
      })
    );
    //dispatch(getSinglePafi(pafiId));
    dispatch(getPafi(contractId));
    dispatch(getFieldorder(contractId));
  } catch (error) {
    console.error(error);
  }
};

//end pafi section

//Fieldorder section
export const getFieldorder = contractId => async dispatch => {
  try {
    const response = await acquireTokenPopupAndCallAPI(token =>
      fetch(`${API_ROOT.fieldorders}/${contractId}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
    );
    const fieldorder = await response.json();
    dispatch(gotFieldorder(fieldorder));
  } catch (error) {
    console.error(error);
  }
};

export const getSingleFieldorder = fieldorderId => async dispatch => {
  try {
    const response = await acquireTokenPopupAndCallAPI(token =>
      fetch(`${API_ROOT.fieldorder}/${fieldorderId}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
    );
    const singleFieldorder = await response.json();
    dispatch(gotSingleFieldorder(singleFieldorder));
  } catch (error) {
    console.error(error);
  }
};

const gotFieldorder = Fieldorder => ({
  type: GOT_FIELDORDER,
  Fieldorder
});

const gotSingleFieldorder = singleFieldorder => ({
  type: GOT_SINGLE_FIELDORDER,
  singleFieldorder
});

export const addFieldorder = (contractId, fieldorderObj) => async dispatch => {
  fieldorderObj.contractId = contractId;

  try {
    //await acquireTokenPopupAndCallAPI(token =>
    const response = await acquireTokenPopupAndCallAPI(token =>
      fetch(`${API_ROOT.fieldorder}`, {
        method: "POST",
        body: JSON.stringify(fieldorderObj.editableFields),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        }
      })
    );
    const newco = await response.json();
    return (newco);
  } catch (error) {
    console.error(error);
  }
};

export const editFieldorder = (contractId, fieldorderId, patchObj) => async dispatch => {
  try {
    await acquireTokenPopupAndCallAPI(token =>
      fetch(`${API_ROOT.fieldorder}/?id=${fieldorderId}`, {
        method: "PATCH",
        body: JSON.stringify(patchObj.patchObj),
        headers: {
          "Content-Type": "application/json-patch+json",
          Authorization: `Bearer ${token}`
        }
      })
    );
    //dispatch(getSingleFieldorder(fieldorderId));
    dispatch(getFieldorder(contractId));
  } catch (error) {
    console.error(error);
  }
};
//end fieldorder section


//get change order payments 
export const getFieldorderPayments = (fieldorderId) => async dispatch => {
  try {
    const response = await acquireTokenPopupAndCallAPI(token =>
      fetch(`${API_ROOT.fieldorderPayment}/${fieldorderId}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
    );
    const fieldorderpayments = await response.json();
    dispatch(gotFieldorderPayments(fieldorderpayments));
  } catch (error) {
    console.error(error);
  }
};

const gotFieldorderPayments = FieldorderPayments => ({
  type: GOT_FIELDORDER_PAYMENT,
  FieldorderPayments
});
//


//get Field order payments by WO
export const getFieldorderPaymentsbyWo = (classifiedWoId) => async dispatch => {
  try {
    const response = await acquireTokenPopupAndCallAPI(token =>
      fetch(`${API_ROOT.fieldorderPaymentByClassifiedWo}/${classifiedWoId}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
    );
    const fieldorderpaymentswo = await response.json();
    dispatch(gotFieldorderPaymentsWo(fieldorderpaymentswo));
  } catch (error) {
    console.error(error);
  }
};

const gotFieldorderPaymentsWo = fieldorderPaymentsWo => ({
  type: GOT_FIELDORDER_PAYMENT_WO,
  fieldorderPaymentsWo
});
//


//get field order payments by ContractId
export const getFieldorderPaymentsbyContractId = (ContractId) => async dispatch => {
  try {
    const response = await acquireTokenPopupAndCallAPI(token =>
      fetch(`${API_ROOT.fieldorderPaymentByContractId}/${ContractId}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
    );
    const fieldorderPaymentsContract = await response.json();
    dispatch(gotFieldorderPaymentsContract(fieldorderPaymentsContract));
  } catch (error) {
    console.error(error);
  }
};

const gotFieldorderPaymentsContract = fieldorderPaymentsContract => ({
  type: GOT_FIELDORDER_PAYMENT_CONTRACT,
  fieldorderPaymentsContract
});
//

//Fieldorder Details section
export const getFieldorderDetails = (fieldorderId) => async dispatch => {
  try {
    const response = await acquireTokenPopupAndCallAPI(token =>
      fetch(`${API_ROOT.fieldorderDetails}/${fieldorderId}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
    );
    const fieldorderdetails = await response.json();
    dispatch(gotFieldorderDetails(fieldorderdetails));
  } catch (error) {
    console.error(error);
  }
};

const gotFieldorderDetails = FieldorderDetails => ({
  type: GOT_FIELDORDER_DETAIL,
  FieldorderDetails
});

export const getFieldorderDetailsWO = (id) => async (dispatch) => {
  try {
    const response = await acquireTokenPopupAndCallAPI((token) =>
      fetch(`${API_ROOT.fieldorderDetailWO}/${id}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
    );
    const FieldorderDetailsWO = await response.json();
    dispatch(
      gotFieldorderDetailsWO(FieldorderDetailsWO)
    );
  } catch (error) {
    console.error(error);
  }
};

const gotFieldorderDetailsWO = FieldorderDetailsWO => ({
  type: GOT_FIELDORDER_DETAIL_WO,
  FieldorderDetailsWO
});

export const addFieldorderDetail = (contractId, fieldorderId, fieldorderObj) => async dispatch => {
  fieldorderObj.contractId = contractId;
  fieldorderObj.fieldorderId = fieldorderId;

  try {
    await acquireTokenPopupAndCallAPI(token =>
      fetch(`${API_ROOT.fieldorderDetail}`, {
        method: "POST",
        body: JSON.stringify(fieldorderObj.editableFields),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        }
      })
    );
    dispatch(getFieldorder(contractId));
    dispatch(getFieldorderDetails(fieldorderId));
  } catch (error) {
    console.error(error);
  }
};

export const editFieldorderDetail = (contractId, fieldorderId, fieldorderdetailId, patchObj) => async dispatch => {
  try {
    await acquireTokenPopupAndCallAPI(token =>
      fetch(`${API_ROOT.fieldorderDetail}/?id=${fieldorderdetailId}`, {
        method: "PATCH",
        body: JSON.stringify(patchObj.patchObj),
        headers: {
          "Content-Type": "application/json-patch+json",
          Authorization: `Bearer ${token}`
        }
      })
    );
    //dispatch(getSingleFieldorder(fieldorderId));
    dispatch(getFieldorder(contractId));
    dispatch(getFieldorderDetails(fieldorderId));
  } catch (error) {
    console.error(error);
  }
};


export const bulkEditFieldorderDetails = (contractId, additions, edits,) => async dispatch => {
  await acquireTokenPopupAndCallAPI(async (token) => {
    const additionReqs = additions.map(entry =>
      fetch(`${API_ROOT.fieldorderDetail}`, {
        method: "POST",
        body: JSON.stringify(entry),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        }
      })
    );
    const editReqs = edits.map(editObj =>
      fetch(`${API_ROOT.fieldorderDetail}/?id=${editObj.fieldorderDetailId}`, {
        method: "PATCH",
        body: JSON.stringify(editObj.patchObj),
        headers: {
          "Content-Type": "application/json-patch+json",
          Authorization: `Bearer ${token}`,
        }
      })
    );
    try {
      await Promise.all([Promise.all(additionReqs), Promise.all(editReqs)]);
      dispatch(getFieldorder(contractId));
      //dispatch(getFieldorderDetails(fieldorderId));
    } catch (error) {
      console.error(error);
    }
  });
};
//end fieldorder Details section

const initialState = {
  Fins: [],
  Pafi: [],
  Fieldorder: [],
  FieldorderDetails: [],
  PafiByFieldorder: [],
  singlePafi: null,
  singleFieldorder: null,
  PafiDetails: [],
  fieldorderPaymentsWo: [],
  fieldorderPaymentsContract: [],
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GOT_FINS:
      const { Fins } = action;
      return { ...state, Fins };
    case GOT_SINGLE_FIN:
      const { singleFin } = action;
      return { ...state, singleFin };
    case GOT_PAFI:
      const { Pafi } = action;
      return { ...state, Pafi };
    case GOT_SINGLE_PAFI:
      const { singlePafi } = action;
      return { ...state, singlePafi };
    case GOT_SELECTED_FINS:
      const { selectedFins } = action;
      return { ...state, selectedFins };
    case GOT_SELECTED_PAFI:
      const { selectedPafi } = action;
      return { ...state, selectedPafi };
    case GOT_FIELDORDER:
      const { Fieldorder } = action;
      return { ...state, Fieldorder };
    case GOT_SINGLE_FIELDORDER:
      const { singleFieldorder } = action;
      return { ...state, singleFieldorder };
    case GOT_FIELDORDER_DETAIL:
      const { FieldorderDetails } = action;
      return { ...state, FieldorderDetails };
    case GOT_FIELDORDER_DETAIL_WO:
      const { FieldorderDetailsWO } = action;
      return { ...state, FieldorderDetailsWO };
    case GOT_FIELDORDER_PAYMENT:
      const { FieldorderPayments } = action;
      return { ...state, FieldorderPayments };

    case GOT_PAFI_DETAIL:
      const { PafiDetails } = action;
      return { ...state, PafiDetails };
    case GOT_PAFI_DETAIL_WO:
      const { PafiDetailsWO } = action;
      return { ...state, PafiDetailsWO };
    case GOT_PAFI_PAYMENT:
      const { PafiPayments } = action;
      return { ...state, PafiPayments };
    case GOT_FIELDORDER_PAYMENT_WO:
      const { fieldorderPaymentsWo } = action;
      return { ...state, fieldorderPaymentsWo };
    case GOT_FIELDORDER_PAYMENT_CONTRACT:
      const { fieldorderPaymentsContract } = action;
      return { ...state, fieldorderPaymentsContract };

    default:
      return state;
  }
}


//get pafi payments 
export const getPafiPayments = (pafiId) => async dispatch => {
  try {
    const response = await acquireTokenPopupAndCallAPI(token =>
      fetch(`${API_ROOT.pafiPayment}/${pafiId}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
    );
    const pafipayments = await response.json();
    dispatch(gotPafiPayments(pafipayments));
  } catch (error) {
    console.error(error);
  }
};

const gotPafiPayments = PafiPayments => ({
  type: GOT_PAFI_PAYMENT,
  PafiPayments
});
//

//Pafi Details section
export const getPafiDetails = (pafiId) => async dispatch => {
  try {
    const response = await acquireTokenPopupAndCallAPI(token =>
      fetch(`${API_ROOT.pafiDetails}/${pafiId}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
    );
    const pafidetails = await response.json();
    dispatch(gotPafiDetails(pafidetails));
  } catch (error) {
    console.error(error);
  }
};

const gotPafiDetails = PafiDetails => ({
  type: GOT_PAFI_DETAIL,
  PafiDetails
});

export const getPafiDetailsWO = (id) => async (dispatch) => {
  try {
    const response = await acquireTokenPopupAndCallAPI((token) =>
      fetch(`${API_ROOT.pafiDetailWO}/${id}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
    );
    const PafiDetailsWO = await response.json();
    dispatch(
      gotPafiDetailsWO(PafiDetailsWO)
    );
  } catch (error) {
    console.error(error);
  }
};

const gotPafiDetailsWO = PafiDetailsWO => ({
  type: GOT_PAFI_DETAIL_WO,
  PafiDetailsWO
});

export const addPafiDetail = (contractId, pafiId, pafiObj) => async dispatch => {
  pafiObj.contractId = contractId;
  pafiObj.pafiId = pafiId;

  try {
    await acquireTokenPopupAndCallAPI(token =>
      fetch(`${API_ROOT.pafiDetail}`, {
        method: "POST",
        body: JSON.stringify(pafiObj.editableFields),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        }
      })
    );
    dispatch(getPafi(contractId));
    dispatch(getPafiDetails(pafiId));
  } catch (error) {
    console.error(error);
  }
};

export const editPafiDetail = (contractId, pafiId, pafidetailId, patchObj) => async dispatch => {
  try {
    await acquireTokenPopupAndCallAPI(token =>
      fetch(`${API_ROOT.pafiDetail}/?id=${pafidetailId}`, {
        method: "PATCH",
        body: JSON.stringify(patchObj.patchObj),
        headers: {
          "Content-Type": "application/json-patch+json",
          Authorization: `Bearer ${token}`
        }
      })
    );

    dispatch(getPafi(contractId));
    dispatch(getPafiDetails(pafiId));
  } catch (error) {
    console.error(error);
  }
};


export const bulkEditPafiDetails = (contractId, additions, edits,) => async dispatch => {
  await acquireTokenPopupAndCallAPI(async (token) => {
    const additionReqs = additions.map(entry =>
      fetch(`${API_ROOT.pafiDetail}`, {
        method: "POST",
        body: JSON.stringify(entry),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        }
      })
    );
    const editReqs = edits.map(editObj =>
      fetch(`${API_ROOT.pafiDetail}/?id=${editObj.pafiDetailId}`, {
        method: "PATCH",
        body: JSON.stringify(editObj.patchObj),
        headers: {
          "Content-Type": "application/json-patch+json",
          Authorization: `Bearer ${token}`,
        }
      })
    );
    try {
      await Promise.all([Promise.all(additionReqs), Promise.all(editReqs)]);
      dispatch(getPafi(contractId));

    } catch (error) {
      console.error(error);
    }
  });
};
//end pafi Details section
