import { acquireTokenPopupAndCallAPI } from "../authentication";

import { Modal } from 'antd';
const initialState = {
  assignments: [],
  drafts:[],
  management:[],
  sarForm:{},
};





export const loadDraft = () => dispatch => {
  acquireTokenPopupAndCallAPI(token => {
    fetch('/api/workflow/drafts', {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then(res => {
      let jsonData = res.json();
      return jsonData;
    }).then(res => {
      dispatch({
        type: 'loadDrafts',
        payload: res.data,
      });

    });
  });
}

export const loadManagement = () => dispatch => {
  acquireTokenPopupAndCallAPI(token => {
    fetch('/api/workflow/management', {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then(res => {
      let jsonData = res.json();
      return jsonData;
    }).then(res => {
      dispatch({
        type: 'loadManagement',
        payload: res.data,
      });

    });
  });
}

export const deleteDraft = (workflowId) => dispatch => {
  acquireTokenPopupAndCallAPI(token => {
    fetch(`/api/workflow/${workflowId}`, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then(res => {
      dispatch({
        type: 'deleteDraft',
        payload: workflowId,
      });

    });
  });
}


export const loadSARForm = (workflowId) => dispatch => {
  acquireTokenPopupAndCallAPI(token => {
    fetch(`/api/sarworkflow/load/${workflowId}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then(res => {
      let jsonData = res.json();
      return jsonData;
    }).then(res => {
      dispatch({
        type: 'loadSAR',
        payload: res.data,
      });

    });
  });
}

export const initialSARForm = (formData,workflowId) => dispatch => {
  acquireTokenPopupAndCallAPI(token => {
    fetch(`/api/SARWorkflow/initial/${workflowId}`, {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body:JSON.stringify(formData),
    }).then(res => {
      let jsonData = res.json();
      return jsonData;
    }).then(jsonRes=>{
      if (jsonRes.ok)
      {
          window.location.href=`#/workflow/success/${jsonRes.data}`;
      }
      else
      {
          window.location.href=`#/workflow/fail/000`
      }
 });
});
}

const ShowSuccessInfo=()=> {
  let secondsToGo = 2;
  const modal = Modal.success({
    title: 'Submission Success',
    content: 'Workflow draft is saved successfully!',
    centered:true,
  });
  const timer = setInterval(() => {
    secondsToGo -= 1;

  }, 1000);
  setTimeout(() => {
    clearInterval(timer);
    modal.destroy();
  }, secondsToGo * 1000);
}

export const saveSARForm = (formData,workflowId) => dispatch => {
  acquireTokenPopupAndCallAPI(token => {
    fetch(`/api/SARWorkflow/savedraft/${workflowId}`, {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body:JSON.stringify(formData),
    }).then(res => {
      let jsonData = res.json();
      return jsonData;
    }).then(jsonRes=>{
      if (jsonRes.ok)
      {
          ShowSuccessInfo();
      }

 });
});
}




export const actionSARForm = (formData,workflowId,actionname) => dispatch => {
  acquireTokenPopupAndCallAPI(token => {
    fetch(`/api/SARWorkflow/${actionname}/${workflowId}`, {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body:JSON.stringify(formData),
    }).then(res => {
      return res.json();
    }).then(jsonRes=>{
         if (jsonRes.ok)
         {
             window.location.href=`#/workflow/success/${workflowId}`;
         }
         else
         {
             window.location.href=`#/workflow/fail/000`
         }
    });
  });
}

export default function reducer(state = initialState, action) {
  const { payload } = action;
  switch (action.type) {
    case 'addItems': {
      return {
        ...state,
        assignments: state.assignments.concat(payload),
      }
    }
    case 'loadItems':{
      return {
        ...state,
        assignments: payload,
      }
    }
    case 'loadDrafts':{
      return {
        ...state,
        drafts: payload,
      }
    }

    case 'loadManagement':{
      return {
        ...state,
        management: payload,
      }
    }

    case 'deleteDraft':{
      return {
        ...state,
        drafts: state.drafts.filter(obj => obj.workflowId!==payload),
      }
    }

    case 'loadSAR':{
      return {
        ...state,
        sarForm: payload,
      }
    }
    default:
      return state;
  }
}
