import { API_ROOT, referenceDataByCategory } from "../api_endpoints";
import { acquireTokenPopupAndCallAPI } from "../authentication";

const GOT_FACILITIES = "GOT_FACILITIES";

const gotFacilities = (facilities) => ({
  type: GOT_FACILITIES,
  facilities,
});

const GOT_FACILITIESADDRESSES = "GOT_FACILITIESADDRESSES";

const gotFacilitiesAddresses = (facilitiesAddresses) => ({
  type: GOT_FACILITIESADDRESSES,
  facilitiesAddresses,
});

const initialState = {
  facilities: [],
  facilitiesAddresses:[]
};

export const getFacilities = () => async (dispatch) => {
  try {
    const response = await acquireTokenPopupAndCallAPI((token) =>
      fetch(API_ROOT.facility, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
    );
    const facilities = await response.json();
    facilities.sort((a, b) => (a.name > b.name) ? 1 : -1)
    dispatch(gotFacilities(facilities));
  } catch (error) {
    console.error(error);
  }
};

export const getFacilityDetail = (id) => async (dispatch) => {
  try {
    const response = await acquireTokenPopupAndCallAPI((token) =>
      fetch(`${API_ROOT.Facility}/${id}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
    );
    const facilityDetail = await response.json();
    //dispatch(gotFacilityDetail(facilityDetail));
    console.log("facility.js facility: ", facilityDetail);
    return facilityDetail;
  } catch (error) {
    console.error(error);
  }
};

export const getFacilitiesList = () => async (dispatch) => {
  try {
    const response = await acquireTokenPopupAndCallAPI((token) =>
      fetch(API_ROOT.facility, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
    );
    const facilities = await response.json();
    return facilities;
    //dispatch(gotFacilities(facilities));
  } catch (error) {
    console.error(error);
  }
};

export const getAllFacilitiesList = () => async (dispatch) => {
  try {
    const response = await acquireTokenPopupAndCallAPI((token) =>
      fetch(API_ROOT.allFacility, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
    );
    const facilities = await response.json();
    return facilities;
    //dispatch(gotFacilities(facilities));
  } catch (error) {
    console.error(error);
  }
};

export const getAllFacilitiesAddresses = () => async (dispatch) => {
  try {
    const response = await acquireTokenPopupAndCallAPI((token) =>
      fetch(referenceDataByCategory('Facility Address'), {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
    );
    const facilitiesAddresses = await response.json();
    dispatch(gotFacilitiesAddresses(facilitiesAddresses));
    return facilitiesAddresses;
  } catch (error) {
    console.error(error);
  }
};

export const addFacility = (facilityObj) => async (dispatch) => {
  try {
    await acquireTokenPopupAndCallAPI((token) =>
      fetch(API_ROOT.facility, {
        method: "POST",
        body: JSON.stringify(facilityObj.editableFields),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
    );
    dispatch(getFacilities());
  } catch (error) {
    console.error(error);
  }
};

export const editFacility = (facilityId, edits) => async (dispatch) => {
  await acquireTokenPopupAndCallAPI(async (token) => {
    const editReqs = fetch(`${API_ROOT.facility}/?id=${edits.id}`, {
      method: "PATCH",
      body: JSON.stringify(edits.patchObj),
      headers: {
        "Content-Type": "application/json-patch+json",
        Authorization: `Bearer ${token}`,
      },
    });
    try {
      await Promise.all([editReqs]);
      //dispatch(getSingleContract(contractId));
    } catch (error) {
      console.error(error);
    }
  });
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GOT_FACILITIES: {
      const { facilities } = action;
      return { ...state, facilities };
    }
    case GOT_FACILITIESADDRESSES: {
      const { facilitiesAddresses } = action;
      return { ...state, facilitiesAddresses };
    }
    default:
      return state;
  }
}
