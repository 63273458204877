export const hyphenatedToSlashDate = (dateStr) => {
  if (dateStr === null) {
    return '--';
  }
  const components = dateStr.substring(0, 10).split('-');
  if (components.length !== 3) {
    return '--';
  }
  const [yearStr, monthStr, dayStr] = components;
  return `${monthStr}/${dayStr}/${yearStr}`;
}

//formaerly 'hypenatedToSlashDate' from CO util
export const hypenatedToSlashDate1 = dateStr => {
  if (dateStr === null || dateStr === "" || dateStr === undefined) {
    return "";
  }
  const components = dateStr.substring(0, 10).split("-");
  if (components.length !== 3) {
    return "";
  }
  const [yearStr, monthStr, dayStr] = components;
  if (yearStr === "1899") {
    return "";
  } else {
    return `${monthStr}/${dayStr}/${yearStr}`;
  }
};

export const formatHyphenatedDate = function (date1) {
  if (date1 === null || date1 === "" || date1 === undefined) {
    return '';
  }
  if (date1.indexOf("T00:00:00") === -1) {
    date1 = date1 + "T00:00:00";
  }
  var date = new Date(date1)
  /**
  const month = date.getMonth() + 1;
  const monthStr = formatTwoDigitLeadingZero(month);
  const day = date.getDate() ;
  const dayStr = formatTwoDigitLeadingZero(day);
  const year = formatFourDigitLeadingZero(date.getFullYear());
  **/
  if (date instanceof Date && !isNaN(date)) {
    return date.toISOString().substr(0, 10);
  }
}

export const formatHyphenatedDate1 = function (date1) {
  if (date1 === null || date1 === "" || date1 === undefined) {
    return '';
  }
  if (date1.toString().indexOf("T00:00:00") === -1) {
    date1 = date1 + "T00:00:00";
  }
  var date = new Date(date1)
  if (date instanceof Date && !isNaN(date)) {
    return date.toISOString().substr(0, 10);
  }
}

export const formatHyphenatedDate2 = function (date1) {
  if (date1 === null || date1 === "" || date1 === undefined) {
    return '';
  }

    var t = date1.toString().indexOf("T");
    var result = date1.toString().substring(0, t);
    date1 = result  + "T00:00:00";

  var date = new Date(date1)
  if (date instanceof Date && !isNaN(date)) {
    return date.toISOString().substr(0, 10);
  }
}

export const dateDiffDays = (date1, date2) => {
  if (date1 === null || date2 === null) {
    return '';
  }
  date1 = new Date(date1);
  date2 = new Date(date2);
  //var res = Math.abs(date1 - date2) / 1000;
  var res = (date1 - date2) / 1000;
  var days = Math.round(res / 86400);
  return days;
}

export function formatTwoDigitLeadingZero(number) {
  return number < 10
    ? `0${number}`
    : Number(number).toString();
}

export function hyphenatedDateFromObj(date) {
  const month = date.getMonth() + 1;
  const monthStr = formatTwoDigitLeadingZero(month);
  const day = date.getDate();
  const dayStr = formatTwoDigitLeadingZero(day);
  const year = date.getFullYear();
  return `${year}-${monthStr}-${dayStr}`;
}

export function isoDateStrToHyphenated(datetimeStr) {
  if (datetimeStr === null) {
    return '';
  }

  const date = new Date(datetimeStr);
  return hyphenatedDateFromObj(date);
}

export function hyphenatedDateStrToIso(str) {
  if (str.length > 0) {
    return (new Date(str)).toISOString();
  }
  return null;
}

export const addDaysToDate = (date1, days) => {
  var _tmpdate = new Date(date1);
  _tmpdate.setDate(_tmpdate.getDate() + days);
  return _tmpdate.getMonth() + 1 + '/' + _tmpdate.getDate() + "/" + _tmpdate.getFullYear();

}

export const dateGreaterThanToday = (dateStr) => {
  let istrue = false;
  if (dateStr === null) {
    return '';
  }
  var today = new Date();
  dateStr = new Date(dateStr);
  if (dateStr > today) {
    return !istrue;
  } else {
    return istrue
  }
}

export const formatAmount = (value, includeDollarSign) => {
  let num = value;
  if (num === null || num === undefined) {
    num = 0;
  }
  let commaDelimitedNumber = num.toLocaleString('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  if (commaDelimitedNumber === "-0.00") {
    commaDelimitedNumber = "0.00"
  }
  if (includeDollarSign) {
    return '$' + commaDelimitedNumber;
  }
  else {
    return commaDelimitedNumber;
  }
}

export const formatAmountTo2Decimal = (value) => {
  let num = value;
  if (num === null || num === undefined) {
    num = 0;
  }
  let Decimal2Fixed = Number(Math.round(parseFloat(num + 'e' + 2)) + 'e-' + 2)

  return Decimal2Fixed;
}


export const isoToSlashDate = (dateStr) => {
  if (dateStr === null) {
    return '--';
  }
  const dateObj = new Date(dateStr);
  const month = dateObj.getMonth() + 1;
  const day = dateObj.getDate();
  const year = dateObj.getFullYear();
  return `${month}/${day}/${year}`;
}

export const formatTrimmedText = (str) => {
  if (str === null || str === undefined || str === '') {
    return '--';
  }
  const trimmedStr = str.trim();
  if (trimmedStr === '') {
    return '--';
  }
  return trimmedStr;
}

export const generatePatchObjs = (inputObj) => (
  Object.keys(inputObj).map((fieldName) => ({
    op: 'replace',
    path: `/${fieldName}`,
    value: inputObj[fieldName],
  }))
);

export const formatPercentage = (num) => {
  if (Number.isNaN(num)) {
    return '--';
  }
  return num.toLocaleString('en-US', {
    maximumFractionDigits: 2
  });
}

export const getReplacePatchObj = (editableValues) => (
  Object.keys(editableValues).map(valueName => ({
    op: "replace",
    path: `/${valueName}`,
    value:
      editableValues[valueName] !== undefined
        ? editableValues[valueName]
        : null
  }))
)

export const filterNonCharSpacePeriod = (str) => {
  const newStrChars = [];
  for (let i = 0; i < str.length; i++) {
    const charCode = str.charCodeAt(i);
    if (
      charCode === 32 || charCode === 46 ||
      (charCode >= 48 && charCode <= 57) ||
      (charCode >= 65 && charCode <= 90) ||
      (charCode >= 97 && charCode <= 122)
    ) {
      newStrChars.push(str[i]);
    }
  }
  return newStrChars.join('');
};

export const filterNonChar = (str) => {
  const newStrChars = [];
  for (let i = 0; i < str.length; i++) {
    const charCode = str.charCodeAt(i);
    if (
      (charCode >= 48 && charCode <= 57) ||
      (charCode >= 65 && charCode <= 90) ||
      (charCode >= 97 && charCode <= 122)
    ) {
      newStrChars.push(str[i]);
    }
  }
  return newStrChars.join('');
};

export const formatTextToNumber = (str) => {
  if (str === null || str === '') {
    return 0;
  }
  let trimmedStr = str.toString().trim();
  if (trimmedStr === '') {
    return 0;
  }
  trimmedStr = trimmedStr.replace(/^[, ]+|[, ]+$|[, ]+/g, "");
  return Number(trimmedStr);
}

export const TrimText = (str) => {
  if (str === null || str === '') {
    return "";
  }
  const trimmedStr = str.trim();
  if (trimmedStr === '') {
    return "";
  }
  return trimmedStr;
}

export const filterNonNumeric = (str) => {
  const newStrChars = [];
  for (let i = 0; i < str.length; i++) {
    const charCode = str.charCodeAt(i);
    if (charCode >= 48 && charCode <= 57) {
      newStrChars.push(str[i]);
    }
  }
  return newStrChars.join('');
}

export const formatHyphenatedPhoneNum = (str) => {
  const num = Number.parseInt(str);
  if (Number.isNaN(num)) {
    return '';
  }
  const intStr = num.toString();
  const newStrChars = [];
  for (let i = 0; i < 3 && i < intStr.length; i++) {
    newStrChars.push(intStr[i]);
  }
  if (intStr.length > 3) {
    newStrChars.push('-');
  }
  for (let i = 3; i < 6 && i < intStr.length; i++) {
    newStrChars.push(intStr[i]);
  }
  if (intStr.length > 6) {
    newStrChars.push('-');
  }
  for (let i = 6; i < intStr.length; i++) {
    newStrChars.push(intStr[i]);
  }
  return newStrChars.join('');
}

export const stateAbbreviations = {
  'AL': 'Alabama',
  'AK': 'Alaska',
  'AZ': 'Arizona',
  'AR': 'Arkansas',
  'CA': 'California',
  'CO': 'Colorado',
  'CT': 'Connecticut',
  'DE': 'Delaware',
  'FL': 'Florida',
  'GA': 'Georgia',
  'HI': 'Hawaii',
  'ID': 'Idaho',
  'IL': 'Illinois',
  'IN': 'Indiana',
  'IA': 'Iowa',
  'KS': 'Kansas',
  'KY': 'Kentucky',
  'LA': 'Louisiana',
  'ME': 'Maine',
  'MD': 'Maryland',
  'MA': 'Massachusetts',
  'MI': 'Michigan',
  'MN': 'Minnesota',
  'MS': 'Mississippi',
  'MO': 'Missouri',
  'MT': 'Montana',
  'NE': 'Nebraska',
  'NV': 'Nevada',
  'NH': 'New Hampshire',
  'NJ': 'New Jersey',
  'NM': 'New Mexico',
  'NY': 'New York',
  'NC': 'North Carolina',
  'ND': 'North Dakota',
  'OH': 'Ohio',
  'OK': 'Oklahoma',
  'OR': 'Oregon',
  'PA': 'Pennsylvania',
  'QC': 'Quebec, Canada',
  'RI': 'Rhode Island',
  'SC': 'South Carolina',
  'SD': 'South Dakota',
  'TN': 'Tennessee',
  'TX': 'Texas',
  'UT': 'Utah',
  'VT': 'Vermont',
  'VA': 'Virginia',
  'WA': 'Washington',
  'WV': 'West Virginia',
  'WI': 'Wisconsin',
  'WY': 'Wyoming',
  'DC': 'District of Columbia',
  'AS': 'American Samoa',
  'GU': 'Guam',
  'MP': 'Northern Mariana Islands',
  'PR': 'Puerto Rico',
  'UM': 'United States Minor Outlying Islands',
  'VI': 'U.S. Virgin Islands'
};

export const convertType = TypeStr => {
  if (TypeStr.trim() === "N") {
    return "Net Cost";
  } else if (TypeStr.trim() === "NC") {
    return "Clause";
  } else {
    return TypeStr;
  }
};

//Legacy named as 'convertType' from PACC util
export const convertPaccType = TypeStr => {
  if (TypeStr.trim() === "J") {
    return "Adjustment PACC";
  } else if (TypeStr.trim() === "C") {
    return "Field Change";
  } else if (TypeStr.trim() === "C1") {
    return "Field Conditions - Relocation of unidentified utilities";
  } else if (TypeStr.trim() === "C2") {
    return "Field Conditions - Removal of unidentified utilities";
  } else if (TypeStr.trim() === "C3") {
    return "Field Conditions - Geotechnical Impacts";
  } else if (TypeStr.trim() === "C4") {
    return "Field Conditions - Other";


  } else if (TypeStr.trim() === "DC") {
    return "Design Change";
  } else if (TypeStr.trim() === "DO") {
    return "Design Omit";
  } else if (TypeStr.trim() === "DE") {
    return "Design Error";
  } else if (TypeStr.trim() === "L1") {
    return "Executive Director";
  } else if (TypeStr.trim() === "L2") {
    return "Line Director";
  } else if (TypeStr.trim() === "L3") {
    return "Facility Manager";
  } else if (TypeStr.trim() === "L4") {
    return "Tenant";
  } else if (TypeStr.trim() === "L5") {
    return "Outside Stakeholder";
  } else if (TypeStr.trim() === "L6") {
    return "Facility Operations";
  } else if (TypeStr.trim() === "L7") {
    return "Public Events";
  } else if (TypeStr.trim() === "L") {
    return "Line";
  } else if (TypeStr.trim() === "F") {
    return "Disputed Exwk";
  }
  // else {
  //   return "PACC";
  // }
};

export const convertPinType = TypeStr => {
  let _TypeStr = TypeStr === null || TypeStr === "" || TypeStr === undefined ? "" : TypeStr.toString();
  if (_TypeStr.trim() === "L") {
    return "Line";
  } else if (_TypeStr.trim() === "C") {
    return "Field Change";
  } else if (_TypeStr.trim() === "C1") {
    return "Field Conditions - Relocation of unidentified utilities";
  } else if (_TypeStr.trim() === "C2") {
    return "Field Conditions - Removal of unidentified utilities";
  } else if (_TypeStr.trim() === "C3") {
    return "Field Conditions - Geotechnical Impacts";
  } else if (_TypeStr.trim() === "C4") {
    return "Field Conditions - Other";
  } else if (_TypeStr.trim() === "DC") {
    return "Design Change";
  } else if (_TypeStr.trim() === "DO") {
    return "Design Omit";
  } else if (_TypeStr.trim() === "DE") {
    return "Design Error";
  } else if (_TypeStr.trim() === "L1") {
    return "Executive Director";
  } else if (_TypeStr.trim() === "L2") {
    return "Line Director";
  } else if (_TypeStr.trim() === "L3") {
    return "Facility Manager";
  } else if (_TypeStr.trim() === "L4") {
    return "Tenant";
  } else if (_TypeStr.trim() === "L5") {
    return "Outside Stakeholder";
  } else if (_TypeStr.trim() === "L6") {
    return "Facility Operations";
  } else if (_TypeStr.trim() === "L7") {
    return "Public Events";
  } else if (_TypeStr.trim() === "F") {
    return "Disputed Exwk";
  } else {
    return _TypeStr;
  }
};

export const OrigType = OrigTypeStr => {
  if (OrigTypeStr.trim() === "C") {
    return "Construction";
  } else if (OrigTypeStr.trim() === "D") {
    return "Design";
  } else if (OrigTypeStr.trim() === "L") {
    return "Line Department";
  } else {
    return OrigTypeStr;
  }
};

export const desiType = TypeStr => {
  if (TypeStr.trim() === "A") {
    return "Lump Sum";
  } else if (TypeStr.trim() === "B") {
    return "Time & Material";
  } else if (TypeStr.trim() === "C") {
    return "Unit Price";
  } else if (TypeStr.trim() === "CR") {
    return "Credit";
  } else if (TypeStr.trim() === "D") {
    return "Lump Sum, Time & Material";
  } else if (TypeStr.trim() === "E") {
    return "Lump Sum, Unit Price";
  } else if (TypeStr.trim() === "F") {
    return "Time & Material, Unit Price";
  } else if (TypeStr.trim() === "G") {
    return "Lump Sum, Time & Material, Unit Price";
  } else if (TypeStr.trim() === "I") {
    return "Lump Sum and Unit Price";
  } else if (TypeStr.trim() === "H") {
    return "No Cost";
  } else {
    return TypeStr;
  }
};

//OrigType from CO util
export const OrigTypeCO = OrigTypeStr => {
  if (OrigTypeStr.trim() === "C") {
    return "Construction";
  } else if (OrigTypeStr.trim() === "C1") {
    return "Field Conditions - Relocation of unidentified utilities";
  } else if (OrigTypeStr.trim() === "C2") {
    return "Field Conditions - Removal of unidentified utilities";
  } else if (OrigTypeStr.trim() === "C3") {
    return "Field Conditions - Geotechnical Impacts";
  } else if (OrigTypeStr.trim() === "C4") {
    return "Field Conditions - Other";
  } else if (OrigTypeStr.trim() === "DC") {
    return "Design Change";
  } else if (OrigTypeStr.trim() === "DO") {
    return "Design Omit";
  } else if (OrigTypeStr.trim() === "DE") {
    return "Design Error";
  } else if (OrigTypeStr.trim() === "L1") {
    return "Executive Director";
  } else if (OrigTypeStr.trim() === "L2") {
    return "Line Director";
  } else if (OrigTypeStr.trim() === "L3") {
    return "Facility Manager";
  } else if (OrigTypeStr.trim() === "L4") {
    return "Tenant";
  } else if (OrigTypeStr.trim() === "L5") {
    return "Outside Stakeholder";
  } else if (OrigTypeStr.trim() === "L6") {
    return "Facility Operations";
  } else if (OrigTypeStr.trim() === "L7") {
    return "Public Events";
  } else if (OrigTypeStr.trim() === "L") {
    return "Line Department";
  } else {
    return OrigTypeStr;
  }
};

//desiType from CO util
export const desiTypeCO = TypeStr => {
  if (TypeStr.trim() === "A") {
    return "Lump Sum";
  } else if (TypeStr.trim() === "B") {
    return "Time & Material";
  } else if (TypeStr.trim() === "C") {
    return "Unit Price";
  } else if (TypeStr.trim() === "CR") {
    return "Credit";
  } else if (TypeStr.trim() === "D") {
    return "Lump Sum, Time & Material";
  } else if (TypeStr.trim() === "E") {
    return "Lump Sum, Unit Price";
  } else if (TypeStr.trim() === "F") {
    return "Time & Material, Unit Price";
  } else if (TypeStr.trim() === "I") {
    return "Lump Sum and Unit Price";
  } else if (TypeStr.trim() === "G") {
    return "Lump Sum, Time & Material, Unit Price";
  } else if (TypeStr.trim() === "H") {
    return "No Cost";
  } else {
    return TypeStr;
  }
};
//convertType from pin util
export const convertTypePin = TypeStr => {
  if (TypeStr.trim() === "L") {
    return "Line";
  } else if (TypeStr.trim() === "C") {
    return "Field Change";
  } else if (TypeStr.trim() === "C1") {
    return "Field Conditions - Relocation of unidentified utilities";
  } else if (TypeStr.trim() === "C2") {
    return "Field Conditions - Removal of unidentified utilities";
  } else if (TypeStr.trim() === "C3") {
    return "Field Conditions - Geotechnical Impacts";
  } else if (TypeStr.trim() === "C4") {
    return "Field Conditions - Other";
  } else if (TypeStr.trim() === "DC") {
    return "Design Change";
  } else if (TypeStr.trim() === "DO") {
    return "Design Omit";
  } else if (TypeStr.trim() === "DE") {
    return "Design Error";
  } else if (TypeStr.trim() === "L1") {
    return "Executive Director";
  } else if (TypeStr.trim() === "L2") {
    return "Line Director";
  } else if (TypeStr.trim() === "L3") {
    return "Facility Manager";
  } else if (TypeStr.trim() === "L4") {
    return "Tenant";
  } else if (TypeStr.trim() === "L5") {
    return "Outside Stakeholder";
  } else if (TypeStr.trim() === "L6") {
    return "Facility Operations";
  } else if (TypeStr.trim() === "L7") {
    return "Public Events";
  } else if (TypeStr.trim() === "F") {
    return "Disputed Exwk";
  } else {
    return TypeStr;
  }
};
//convertType from fin util
export const convertTypeFin = TypeStr => {
  if (TypeStr.trim() === "D") {
    return "Design";
  } else if (TypeStr.trim() === "L") {
    return "Line";
  } else if (TypeStr.trim() === "L1") {
    return "Executive Director";
  } else if (TypeStr.trim() === "L2") {
    return "Line Director";
  } else if (TypeStr.trim() === "L3") {
    return "Facility Manager";
  } else if (TypeStr.trim() === "L4") {
    return "Tenant";
  } else if (TypeStr.trim() === "L5") {
    return "Outside Stakeholder";
  } else if (TypeStr.trim() === "L6") {
    return "Facility Operations";
  } else if (TypeStr.trim() === "L7") {
    return "Public Events";
  } else if (TypeStr.trim() === "C") {
    return "Field Change";
  } else if (TypeStr.trim() === "C1") {
    return "Field Conditions - Relocation of unidentified utilities";
  } else if (TypeStr.trim() === "C2") {
    return "Field Conditions - Removal of unidentified utilities";
  } else if (TypeStr.trim() === "C3") {
    return "Field Conditions - Geotechnical Impacts";
  } else if (TypeStr.trim() === "C4") {
    return "Field Conditions - Other";
  } else if (TypeStr.trim() === "DO") {
    return "Design Omit";
  } else if (TypeStr.trim() === "F") {
    return "Disputed Exwk";
  } else if (TypeStr.trim() === "DE") {
    return "Design Error";
  } else {
    return TypeStr;
  }
};
//From FI util
export const convertTypeFI = TypeStr => {
  if (TypeStr.trim() === "J") {
    return "Adjustment PAFI";
  } else {
    return "PAFI";
  }
};
//From FI util
export const OrigTypeFI = OrigTypeStr => {
  if (OrigTypeStr.trim() === "C") {
    return "Construction";
  } else if (OrigTypeStr.trim() === "C1") {
    return "Field Conditions - Relocation of unidentified utilities";
  } else if (OrigTypeStr.trim() === "C2") {
    return "Field Conditions - Removal of unidentified utilities";
  } else if (OrigTypeStr.trim() === "C3") {
    return "Field Conditions - Geotechnical Impacts";
  } else if (OrigTypeStr.trim() === "C4") {
    return "Field Conditions - Other";
  } else if (OrigTypeStr.trim() === "D") {
    return "Design";
  } else if (OrigTypeStr.trim() === "DO") {
    return "Design Omit";
  } else if (OrigTypeStr.trim() === "DE") {
    return "Design Error";
  } else if (OrigTypeStr.trim() === "L1") {
    return "Executive Director";
  } else if (OrigTypeStr.trim() === "L2") {
    return "Line Director";
  } else if (OrigTypeStr.trim() === "L3") {
    return "Facility Manager";
  } else if (OrigTypeStr.trim() === "L4") {
    return "Tenant";
  } else if (OrigTypeStr.trim() === "L5") {
    return "Outside Stakeholder";
  } else if (OrigTypeStr.trim() === "L6") {
    return "Facility Operations";
  } else if (OrigTypeStr.trim() === "L7") {
    return "Public Events";
  } else if (OrigTypeStr.trim() === "L") {
    return "Line Department";
  } else {
    return OrigTypeStr;
  }
};
//From FI util
export const desiTypeFI = TypeStr => {
  if (TypeStr.trim() === "A") {
    return "Lump Sum";
  } else if (TypeStr.trim() === "B") {
    return "Time & Material";
  } else if (TypeStr.trim() === "C") {
    return "Unit Price";
  } else if (TypeStr.trim() === "CR") {
    return "Credit";
  } else if (TypeStr.trim() === "D") {
    return "Lump Sum, Time & Material";
  } else if (TypeStr.trim() === "E") {
    return "Lump Sum, Unit Price";
  } else if (TypeStr.trim() === "F") {
    return "Time & Material, Unit Price";
  } else if (TypeStr.trim() === "G") {
    return "Lump Sum, Time & Material, Unit Price";
  } else if (TypeStr.trim() === "I") {
    return "Lump Sum and Unit Price";
  } else if (TypeStr.trim() === "H") {
    return "No Cost";
  } else {
    return TypeStr;
  }
};
//PAFI util
export const convertFinTypePAFI = TypeStr => {
  let _TypeStr = TypeStr === null || TypeStr === "" || TypeStr === undefined ? "" : TypeStr.toString();
  if (_TypeStr.trim() === "D") {
    return "Design";
  } else if (_TypeStr.trim() === "C1") {
    return "Field Conditions - Relocation of unidentified utilities";
  } else if (_TypeStr.trim() === "C2") {
    return "Field Conditions - Removal of unidentified utilities";
  } else if (_TypeStr.trim() === "C3") {
    return "Field Conditions - Geotechnical Impacts";
  } else if (_TypeStr.trim() === "C4") {
    return "Field Conditions - Other";
  } else if (_TypeStr.trim() === "L") {
    return "Line";
  } else if (_TypeStr.trim() === "L1") {
    return "Executive Director";
  } else if (_TypeStr.trim() === "L2") {
    return "Line Director";
  } else if (_TypeStr.trim() === "L3") {
    return "Facility Manager";
  } else if (_TypeStr.trim() === "L4") {
    return "Tenant";
  } else if (_TypeStr.trim() === "L5") {
    return "Outside Stakeholder";
  } else if (_TypeStr.trim() === "L6") {
    return "Facility Operations";
  } else if (_TypeStr.trim() === "L7") {
    return "Public Events";
  } else if (_TypeStr.trim() === "C") {
    return "Field Change";
  } else if (_TypeStr.trim() === "DO") {
    return "Design Omit";
  } else if (_TypeStr.trim() === "F") {
    return "Disputed Exwk";
  } else if (_TypeStr.trim() === "DE") {
    return "Design Error";
  } else {
    return _TypeStr;
  }
};
//PAFI util
export const OrigTypePAFI = OrigTypeStr => {
  if (OrigTypeStr.trim() === "C") {
    return "Construction";
  } else if (OrigTypeStr.trim() === "D") {
    return "Design";
  } else if (OrigTypeStr.trim() === "L") {
    return "Line Department";
  } else {
    return OrigTypeStr;
  }
};
//PAFI util
export const desiTypePAFI = TypeStr => {
  if (TypeStr.trim() === "A") {
    return "Lump Sum";
  } else if (TypeStr.trim() === "B") {
    return "Time & Material";
  } else if (TypeStr.trim() === "C") {
    return "Unit Price";
  } else if (TypeStr.trim() === "CR") {
    return "Credit";
  } else if (TypeStr.trim() === "D") {
    return "Lump Sum, Time & Material";
  } else if (TypeStr.trim() === "E") {
    return "Lump Sum, Unit Price";
  } else if (TypeStr.trim() === "F") {
    return "Time & Material, Unit Price";
  } else if (TypeStr.trim() === "G") {
    return "Lump Sum, Time & Material, Unit Price";
  } else if (TypeStr.trim() === "I") {
    return "Lump Sum and Unit Price";
  } else if (TypeStr.trim() === "H") {
    return "No Cost";
  } else {
    return TypeStr;
  }
};
//Classified
export const desiTypeClfd = TypeStr => {
  if (TypeStr.trim() === "A") {
    return "Lump Sum";
  } else if (TypeStr.trim() === "B") {
    return "Time & Material";
  } else if (TypeStr.trim() === "C") {
    return "Unit Price";
  } else if (TypeStr.trim() === "CR") {
    return "Credit";
  } else if (TypeStr.trim() === "D") {
    return "Lump Sum, Time & Material";
  } else if (TypeStr.trim() === "E") {
    return "Lump Sum, Unit Price";
  } else if (TypeStr.trim() === "F") {
    return "Time & Material, Unit Price";
  } else if (TypeStr.trim() === "I") {
    return "Lump Sum and Unit Price";
  } else if (TypeStr.trim() === "G") {
    return "Lump Sum, Time & Material, Unit Price";
  } else if (TypeStr.trim() === "H") {
    return "No Cost";
  } else {
    return TypeStr;
  }
};
//Classified
export const OrigTypeClfd = OrigTypeStr => {
  if (OrigTypeStr.trim() === "C") {
    return "Construction";
  } else if (OrigTypeStr.trim() === "D") {
    return "Design";
  } else if (OrigTypeStr.trim() === "L") {
    return "Line Department";
  } else {
    return OrigTypeStr;
  }
};
//Classified
export const convertTypeClfd = TypeStr => {
  if (TypeStr.trim() === "N") {
    return "Net Cost";
  } else if (TypeStr.trim() === "NC") {
    return "Clause";
  } else {
    return TypeStr;
  }
};
//Audit
export const convertAuditType = TypeStr => {
  let _TypeStr = TypeStr === null || TypeStr === "" || TypeStr === undefined ? "" : TypeStr.toString();
  if (_TypeStr.trim() === "I") {
    return "INTERIM";
  } else if (_TypeStr.trim() === "F") {
    return "FINAL";
  } else {
    return _TypeStr;
  }
};
