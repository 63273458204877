import { createStore, combineReducers, applyMiddleware } from "redux";
import thunkMiddleware from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";

import contract from "./contract";
import facility from "./facility";
import vendor from "./vendor";
import closeDelay from "./close-delay";
import workOrder from "./work-order";
import unitPrice from "./unit-price";
import changeorder from "./changeorder";
import setAside from "./set-aside";
import extrawork from "./extrawork";
import extraworkwip from "./extraworkwip";
import spread from "./spread";
import recycle from "./recycle";
import workflow from "./workflow";
import user from "./user";
import fo from "./fo";
import meur from "./meur";
import general from "./general";
import api from "./api";
import fieldOrder from "./field-order";
import fieldorderwip from "./fieldorderwip";
import payment from "./payment";
import goals from "./goals";
import category from "./category";
import ethnicity from "./ethnicity";

const reducer = combineReducers({
  contract,
  facility,
  vendor,
  closeDelay,
  workOrder,
  unitPrice,
  changeorder,
  setAside,
  extrawork,
  extraworkwip,
  spread,
  recycle,
  workflow,
  user,
  fo,
  meurs: meur,
  general,
  fieldOrder,
  fieldorderwip,
  payment,
  goals,
  category,
  ethnicity
});
const middleware = composeWithDevTools(applyMiddleware(thunkMiddleware, api));
const store = createStore(reducer, middleware);

export default store;
