import * as Msal from '@azure/msal-browser';

const msalConfig = {
  auth: {
    clientId: 'e53b162d-f309-4f9f-a108-49a7fdf95f6e', //This is your client ID
    authority: "https://login.microsoftonline.com/c4484a25-d0d8-4ad0-916a-a889b6aa7a02", //This is your tenant info
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: true
  }
};

export const loginScopes = {
  scopes: ["api://44712413-cec5-405e-959e-798e50e6bcf6/Data.All", "User.Read", "Directory.Read.All"]
};

const WINTRAK_SCOPE = "api://44712413-cec5-405e-959e-798e50e6bcf6/Data.All";
const DIRECTORY_SCOPE = "Directory.Read.All";

const myMSALObj = new Msal.PublicClientApplication(msalConfig);
export default myMSALObj;

function requiresInteraction(errorCode) {
  if (!errorCode || !errorCode.length) {
    return false;
  }
  return (
    errorCode === "consent_required" ||
    errorCode === "interaction_required" ||
    errorCode === "login_required"
  );
}

// Deprecated, older function for wrapping token refresh logic for use
// when calling an API. The function fetchWithToken may be easier to use.
export async function acquireTokenPopupAndCallAPI(apiCallWithTokenFnAsync) {
  const accounts = myMSALObj.getAllAccounts();
  if (accounts.length === 0) {
    return null;
  }
  const account = accounts[0];
  const requestWithAccount = { scopes: [WINTRAK_SCOPE], account };
  let tokenResponse;
  try {
    // try and get the token silently in the background
    tokenResponse = await myMSALObj.acquireTokenSilent(requestWithAccount);
  } catch (error) {
    console.error(error);
    // if the silent request failed, it might be because the user
    // needs to request one interactively via a pop-up or redirect
    if (requiresInteraction(error.errorCode)) {
      try {
        // try and get the token with an interactive pop-up window
        tokenResponse = await myMSALObj.acquireTokenPopup(requestWithAccount);
      } catch (error) {
        console.error(error);
        return null;
      }
    }
  }
  try {
    const token = tokenResponse.accessToken;
    return await apiCallWithTokenFnAsync(token);
  }
  catch (error) {
    console.error(error);
  }
}

/**
 * Acquires a bearer token using MSAL.
 * @returns {Promise<string|null>} A Promise with the token or null if
 * the token could not be obtained.
 */
export async function acquireToken(scopes) {
  const accounts = myMSALObj.getAllAccounts();
  if (accounts.length === 0) {
    return null;
  }
  const account = accounts[0];
  const requestWithAccount = { scopes: scopes || [WINTRAK_SCOPE], account };
  let tokenResponse;
  try {
    // try and get the token silently in the background
    tokenResponse = await myMSALObj.acquireTokenSilent(requestWithAccount);
  } catch (error) {
    console.error(error);
    // if the silent request failed, it might be because the user
    // needs to request one interactively via a pop-up or redirect
    if (requiresInteraction(error.errorCode)) {
      try {
        // try and get the token with an interactive pop-up window
        tokenResponse = await myMSALObj.acquireTokenPopup(requestWithAccount);
      } catch (error) {
        console.error(error);
        return null;
      }
    }
  }
  return tokenResponse.accessToken;
}

/**
 * Acquires a bearer token using MSAL and makes a Fetch API call using the token.
 * @param input The Request object.
 * @param init
 * @returns {Promise<Response>} A Promise with the Response object from the Fetch API.
 */
export async function fetchWithToken(input, init) {
  const token = await acquireToken();
  const request = new Request(input, init);
  request.headers.append('Authorization', `Bearer ${token}`);
  return fetch(request);
}

// Same purpose as fetchWithToken, but specifically for use with MS Graph APIs for reading
// Azure AD information
export async function fetchGraphWithToken(input, init) {
  const token = await acquireToken([DIRECTORY_SCOPE]);
  const request = new Request(input, init);
  request.headers.append('Authorization', `Bearer ${token}`);
  return fetch(request);
}
