import { API_ROOT } from '../api_endpoints';
import { acquireTokenPopupAndCallAPI, fetchWithToken } from '../authentication';
import { getReportServerUrlUrl } from '../api_endpoints';

const GOT_ME = 'GOT_ME';
const GOT_REPORT_SERVER_URL = 'GOT_REPORT_SERVER_URL';

const gotMe = (data) => {
  if (data === null || typeof data === 'string') {
    return {
      type: GOT_ME,
      me: data
    };
  }
  const {
    aggregatedPermissions, adminUserRole, adminUserFacility,
    adacountId, fullName,
  } = data;
  const permissions = aggregatedPermissions.reduce(
    (permissionObj, { permissionItemID, highestPrivilege }) => {
      permissionObj[permissionItemID] = highestPrivilege;
      return permissionObj;
    },
    {},
  );
  const roles = adminUserRole.reduce(
    (rolesObj, { role }) => {
      const { roleId, roleName } = role;
      rolesObj[roleId] = roleName;
      return rolesObj;
    },
    {},
  );
  const facilities = adminUserFacility.reduce(
    (facilitiesObj, { facility }) => {
      const { id, name } = facility;
      facilitiesObj[id] = name;
      return facilitiesObj;
    },
    {},
  )
  return {
    type: GOT_ME,
    me: {
      adacountId,
      fullName,
      permissions,
      roles,
      facilities,
    },
  };
};

const gotReportServerUrl = (url) => ({
  type: GOT_REPORT_SERVER_URL,
  url
});

export const getMe = () => async (dispatch) => {
  try {
    const response = await acquireTokenPopupAndCallAPI((token) => (
      fetch(
        API_ROOT.me,
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
    ));
    if (response.ok) {
      const meObj = await response.json();
      dispatch(gotMe(meObj));
    }
    else if (response.status === 404) {
      dispatch(gotMe('NO_ACCOUNT'));
    }
    else {
      dispatch(gotMe('ERROR'));
    }
  }
  catch (error) {
    console.error(error);
  }
};

export const getReportServerUrl = () => async (dispatch) => {
  const response = await fetchWithToken(getReportServerUrlUrl);
  const data = await response.json();
  const url = data['ReportServerURL'] + '?/' + data['ReportFolder'] + '/';
  dispatch(gotReportServerUrl(url));
}

const initialState = {
  me: null,
  reportServerUrl: ''
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GOT_ME: {
      const { me } = action;
      return { ...state, me }
    }
    case GOT_REPORT_SERVER_URL: {
      const { url } = action;
      return { ...state, reportServerUrl: url }
    }
    default:
      return state;
  }
};
