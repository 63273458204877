import "../mock/pacclist.js";
//import axios from 'axios';

const initialState = {
  label: "Change Order",
  changeorders: [
    {
      ID: 1111,
      ItemName: "CO1"
    },
    {
      ID: 2222,
      ItemName: "CO2"
    }
  ]
};

export const addNew = payload => dispatch => {
  /* axios.get('/api/PACCList').then((res)=>{ */
  /*      console.log(res);
      dispatch({
        type:'AddNew',
        payload:res.data.items,
      });
    }); */
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case "AddNew": {
      const { payload } = action;
      //console.log(state.changeorders);
      return {
        ...state,
        changeorders: state.changeorders.concat(payload)
      };
    }
    default:
      return state;
  }
}
