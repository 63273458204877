import { referenceDataByCategory } from '../api_endpoints';
import { acquireTokenPopupAndCallAPI } from '../authentication';

const GOT_CATEGORIES = 'GOT_CATEGORIES';

const gotCategories = (categories) => ({
  type: GOT_CATEGORIES,
  categories
});

export const getCategories = () => async (dispatch) => {
  try {
    const response = await acquireTokenPopupAndCallAPI((token) => (
      fetch(
        `${referenceDataByCategory('Work Category')}`,
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
    ));
    const categories = await response.json();
    dispatch(gotCategories(categories));
  }
  catch (error) {
    console.error(error);
  }
};


const initialState = {
  categories: [],
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GOT_CATEGORIES: {
      const { categories } = action;
      return { ...state, categories };
    }
    default:
      return state;
  }
}
