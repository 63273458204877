import { API_ROOT } from "../api_endpoints";
import { acquireTokenPopupAndCallAPI } from "../authentication";

const GOT_SPREADS = "GOT_SPREADS";
const GOT_SPREAD_DETAILS = "GOT_SPREADS_DETAILS";
const GOT_SPREAD_WIPCCLINE = "GOT_SPREAD_WIPCCLINE";
const GOT_SPREAD_WOCCMAPPING = "GOT_SPREAD_WOCCMAPPING";

export const getSpreads = contractId => async dispatch => {
  try {
    const response = await acquireTokenPopupAndCallAPI(token =>
      fetch(`${API_ROOT.spreads}/${contractId}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
    );
    const spreads = await response.json();

    dispatch(gotSpreads(spreads));
  } catch (error) {
    console.error(error);
  }
};

const gotSpreads = Spreads => ({
  type: GOT_SPREADS,
  Spreads
});

export const addSpread = (SpreadObj) => async dispatch => {
  try {
    const response = await acquireTokenPopupAndCallAPI(token =>
      fetch(`${API_ROOT.spread}`, {
        method: "POST",
        body: JSON.stringify(SpreadObj),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        }
      })
    );
    
    const newspread = await response.json();
    dispatch(getSpreads(SpreadObj.contractId));
    return (newspread);
    //dispatch(getSpreads(SpreadObj.contractId));
  } catch (error) {
    console.error(error);
  }
};

export const addSpreadForYear = (SpreadObj, moyr) => async dispatch => {
  try {
    SpreadObj.moyr = moyr;
    const response = await acquireTokenPopupAndCallAPI(token =>
      fetch(`${API_ROOT.spread}`, {
        method: "POST",
        body: JSON.stringify(SpreadObj),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        }
      })
    );
    
    const newspread = await response.json();
    dispatch(getSpreads(SpreadObj.contractId));
    return (newspread);
    //dispatch(getSpreads(SpreadObj.contractId));
  } catch (error) {
    console.error(error);
  }
};

export const editSpread = (SpreadId, patchObj) => async dispatch => {
  try {
    await acquireTokenPopupAndCallAPI(token =>
      fetch(`${API_ROOT.spread}`, {
        method: "PUT",
        body: JSON.stringify(patchObj),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        }
      })
    );
    dispatch(getSpreads(patchObj.contractId));
    dispatch(getSpreadDetails(patchObj.contractId));
  } catch (error) {
    console.error(error);
  }
};

export const getSpreadDetails = contractId => async dispatch => {
  try {
    const response = await acquireTokenPopupAndCallAPI(token =>
      fetch(`${API_ROOT.spreaddetails}/${contractId}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
    );
    const spreaddetails = await response.json();

    dispatch(gotSpreadDetails(spreaddetails));    
    dispatch(getSpreads(contractId));
  } catch (error) {
    console.error(error);
  }
};

const gotSpreadDetails = SpreadDetails => ({
  type: GOT_SPREAD_DETAILS,
  SpreadDetails
});

export const getCutOffDateByContract = (id) => async (dispatch) => {
  try {
    const response = await acquireTokenPopupAndCallAPI((token) =>
      fetch(`${API_ROOT.spreadCutoffDate}/${id}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
    );
    const result = await response.json();
    return result;
  } catch (error) {
    console.error(error);
  }
};

export const addSpreadDetail = (SpreadDetailObj, contractId) => async dispatch => {
  try {
    await acquireTokenPopupAndCallAPI(token =>
      fetch(`${API_ROOT.spreaddetail}`, {
        method: "POST",
        body: JSON.stringify(SpreadDetailObj),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        }
      })
    );
    dispatch(getSpreads(contractId));
    dispatch(getSpreadDetails(contractId));    
    dispatch(getSpreadWIPCCLine(contractId)); 
    dispatch(getSpreadWOCCMapping(contractId)); 
  } catch (error) {
    console.error(error);
  }
};

export const editSpreadDetail = (patchObj, contractId) => async dispatch => {
  try {
    await acquireTokenPopupAndCallAPI(token =>
      fetch(`${API_ROOT.spreaddetail}`, {
        method: "PUT",
        body: JSON.stringify(patchObj),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        }
      })
    );
    dispatch(getSpreads(contractId));
    dispatch(getSpreadDetails(contractId));
  } catch (error) {
    console.error(error);
  }
};

// export const bulkEditSpreadDetail = (edits) => async (dispatch) => {
//   await acquireTokenPopupAndCallAPI(async (token) => {

//     const editReqs = edits.map((editObj) =>
//       fetch(`${API_ROOT.spreaddetail}`, {
//         method: "PATCH",
//         body: JSON.stringify(editObj.patchObj),
//         headers: {
//           "Content-Type": "application/json-patch+json",
//           Authorization: `Bearer ${token}`,
//         },
//       })
//     );
//     try {
//       await Promise.all([Promise.all(editReqs)]);

//       dispatch(getSpreadDetails(patchObj.contractId));
//       dispatch(getSpreads(patchObj.contractId));
//     } catch (error) {
//       console.error(error);
//     }
//   });
// };

export const getSpreadWIPCCLine = contractId => async dispatch => {
  try {
    const response = await acquireTokenPopupAndCallAPI(token =>
      fetch(`${API_ROOT.spreadwipcclineitems}/${contractId}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
    );
    const SpreadWIPCCLine = await response.json();

    dispatch(gotSpreadWIPCCLine(SpreadWIPCCLine));    
    dispatch(getSpreads(contractId));
  } catch (error) {
    console.error(error);
  }
};

const gotSpreadWIPCCLine = SpreadWIPCCLine => ({
  type: GOT_SPREAD_WIPCCLINE,
  SpreadWIPCCLine
});

export const getSpreadWOCCMapping = contractId => async dispatch => {
  try {
    const response = await acquireTokenPopupAndCallAPI(token =>
      fetch(`${API_ROOT.spreadwoccmapping}/${contractId}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
    );
    const SpreadWOCCMapping = await response.json();

    dispatch(gotSpreadWOCCMapping(SpreadWOCCMapping));
    dispatch(getSpreads(contractId));
  } catch (error) {
    console.error(error);
  }
};

const gotSpreadWOCCMapping = SpreadWOCCMapping => ({
  type: GOT_SPREAD_WOCCMAPPING,
  SpreadWOCCMapping
});

const initialState = {
  Spreads: [],
  SpreadDetails: [],
  SpreadWIPCCLine: [],
  SpreadWOCCMapping: [],
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GOT_SPREADS:
      const { Spreads } = action;
      return { ...state, Spreads };
    case GOT_SPREAD_DETAILS:
      const { SpreadDetails } = action;
      return { ...state, SpreadDetails };
    case GOT_SPREAD_WIPCCLINE:
      const { SpreadWIPCCLine } = action;
      return { ...state, SpreadWIPCCLine };
    case GOT_SPREAD_WOCCMAPPING:
      const { SpreadWOCCMapping } = action;
      return { ...state, SpreadWOCCMapping };


    default:
      return state;
  }
}
