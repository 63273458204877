import { API_ROOT } from "../api_endpoints";
import { acquireTokenPopupAndCallAPI } from "../authentication";
import { getSingleContract } from "./contract";

const GOT_FIELDORDERWIPS = "GOT_FIELDORDERWIPS";

const gotFieldOrderWIPs = FieldOrderWIPs => ({
  type: GOT_FIELDORDERWIPS,
  FieldOrderWIPs
});

export const getFieldOrderWIPs = contractId => async dispatch => {
  try {
    const response = await acquireTokenPopupAndCallAPI(token =>
      fetch(`${API_ROOT.fieldorderwips}/${contractId}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
    );
    const fieldorderwips = await response.json();

    dispatch(gotFieldOrderWIPs(fieldorderwips));
  } catch (error) {
    console.error(error);
  }
};

export const addFieldOrderWIP = (FieldOrderWIPObj) => async dispatch => {
  try {
    await acquireTokenPopupAndCallAPI(token =>
      fetch(`${API_ROOT.fieldorderwip}`, {
        method: "POST",
        body: JSON.stringify(FieldOrderWIPObj),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        }
      })
    );
    dispatch(getFieldOrderWIPs(FieldOrderWIPObj.contractId));
    dispatch(getSingleContract(FieldOrderWIPObj.contractId));
  } catch (error) {
    console.error(error);
  }
};

export const editFieldOrderWIP = (FieldOrderWIPId, patchObj) => async dispatch => {
  try {
    await acquireTokenPopupAndCallAPI(token =>
      fetch(`${API_ROOT.fieldorderwip}`, {
        method: "PUT",
        body: JSON.stringify(patchObj),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        }
      })
    );
    dispatch(getFieldOrderWIPs(patchObj.contractId));
    dispatch(getSingleContract(patchObj.contractId));
  } catch (error) {
    console.error(error);
  }
};

const initialState = {
  FieldOrderWIPs: [],
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GOT_FIELDORDERWIPS:
      const { FieldOrderWIPs } = action;
      return { ...state, FieldOrderWIPs };
    default:
      return state;
  }
}
