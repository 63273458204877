import { API_ROOT } from '../api_endpoints';
import { acquireTokenPopupAndCallAPI } from '../authentication';

const GOT_WORK_ORDERS = 'GOT_WORK_ORDERS';
const GOT_SINGLE_WORK_ORDER = 'GOT_SINGLE_WORK_ORDER';
const GOT_SINGLE_WORK_ORDER_PAID = 'GOT_SINGLE_WORK_ORDER_PAID';
const GOT_ACTVITY_TASKS = 'GOT_ACTVITY_TASKS';
const GOT_SINGLE_ACTIVITY_TASK = 'GOT_SINGLE_ACTIVITY_TASK';
const GOT_SINGLE_ACTIVITY_TASK_ERROR = 'GOT_SINGLE_ACTIVITY_TASK_ERROR';
const CLEAR_SINGLE_ACTIVITY_TASK_ERROR = 'CLEAR_SINGLE_ACTIVITY_TASK_ERROR';
const CLEAR_ACTIVITY_TASKS = 'CLEAR_ACTIVITY_TASKS';

const gotWorkOrders = workOrders => ({
  type: GOT_WORK_ORDERS,
  workOrders
});

const gotSingleWorkOrder = singleWorkOrder => ({
  type: GOT_SINGLE_WORK_ORDER,
  singleWorkOrder
});

const gotSingleWorkOrderPaid = singleWorkOrderPaid => ({
  type: GOT_SINGLE_WORK_ORDER_PAID,
  singleWorkOrderPaid
});

const gotActivityTasks = activityTasks => ({
  type: GOT_ACTVITY_TASKS,
  activityTasks
});

const gotSingleActivityTask = singleActivityTask => ({
  type: GOT_SINGLE_ACTIVITY_TASK,
  singleActivityTask
});

const gotSingleActivityTaskError = (message) => ({
  type: GOT_SINGLE_ACTIVITY_TASK_ERROR,
  message,
});

export const clearSingleActivityTaskError = () => ({
  type: CLEAR_SINGLE_ACTIVITY_TASK_ERROR,
})

export const clearActivityTasks = () => ({
  type: CLEAR_ACTIVITY_TASKS
});

export const getWorkOrders = contractId => async dispatch => {
  try {
    const response = await acquireTokenPopupAndCallAPI((token) => (
      fetch(
        `${API_ROOT.workOrders}/ByContract/${contractId}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
    ));
    const workOrders = await response.json();
    dispatch(gotWorkOrders(workOrders.sort()));
  } catch (error) {
    console.error(error);
  }
};

export const getSingleWorkOrder = workOrderId => async dispatch => {
  try {
    const response = await acquireTokenPopupAndCallAPI((token) => (
      fetch(`${API_ROOT.workOrders}/${workOrderId}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
    ))
    const singleWorkOrder = await response.json();
    dispatch(gotSingleWorkOrder(singleWorkOrder));
  } catch (error) {
    console.error(error);
  }
};

export const getSingleWorkOrderPaid = workOrderId => async dispatch => {
  try {
    const response = await acquireTokenPopupAndCallAPI((token) => (
      fetch(`${API_ROOT.WorkOrderDetailsPaid}/${workOrderId}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
    ))
    const singleWorkOrderPaid = await response.json();
    dispatch(gotSingleWorkOrderPaid(singleWorkOrderPaid));
  } catch (error) {
    console.error(error);
  }
};

export const editWorkOrder = (workOrderId, patchObj) => async dispatch => {
  try {
    await acquireTokenPopupAndCallAPI((token) => (
      fetch(`${API_ROOT.workOrders}/?id=${workOrderId}`, {
        method: "PATCH",
        body: JSON.stringify(patchObj),
        headers: {
          'Content-Type': 'application/json-patch+json',
          Authorization: `Bearer ${token}`,
        }
      })
    ));
    dispatch(getSingleWorkOrder(workOrderId));
  } catch (error) {
    console.error(error);
  }
};

export const getActivityTasks = workOrderId => async dispatch => {
  try {
    const response = await acquireTokenPopupAndCallAPI((token) => (
      fetch(
        `${API_ROOT.activityTasks}/ByWorkorder/${workOrderId}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
    ));
    const activityTasks = await response.json();
    dispatch(gotActivityTasks(activityTasks));
  } catch (error) {
    console.error(error);
  }
};

export const getSingleActivityTask = activityTaskId => async dispatch => {
  try {
    const response = await acquireTokenPopupAndCallAPI((token) => (
      fetch(
        `${API_ROOT.activityTasks}/${activityTaskId}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
    ));
    const singleActivityTask = await response.json();
    dispatch(gotSingleActivityTask(singleActivityTask));
  } catch (error) {
    console.error(error);
  }
};

export const editActivityTask = (taskId, patchObj) => async dispatch => {
  try {
    dispatch(clearSingleActivityTaskError());
    const response = await acquireTokenPopupAndCallAPI((token) => (
      fetch(`${API_ROOT.activityTasks}/?id=${taskId}`, {
        method: 'PATCH',
        body: JSON.stringify(patchObj),
        headers: {
          'Content-Type': 'application/json-patch+json',
          Authorization: `Bearer ${token}`,
        }
      })
    ));
    const { status } = response;
    if (status === 400) {
      const errorMsg = await response.text();
      dispatch(gotSingleActivityTaskError(errorMsg));
    }
    else {
      dispatch(getSingleActivityTask(taskId));
    }
  } catch (error) {
    console.error(error);
  }
};

export const addWorkOrder = (contractId, workOrderObj) => async dispatch => {
  workOrderObj.contractId = contractId;
  try {
    const response = await acquireTokenPopupAndCallAPI((token) => (
      fetch(`${API_ROOT.workOrders}`, {
        method: 'POST',
        body: JSON.stringify(workOrderObj),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        }
      })
    ));
    
    dispatch(getWorkOrders(contractId));
    const newWorkOrder = await response.json();//For 1776
    return (newWorkOrder); 
  } catch (error) {
    console.error(error);
  }
};

export const addActivityTask = (
  workOrderId,
  activityTaskObj
) => async dispatch => {
  activityTaskObj.workOrderId = workOrderId;
  try {
    dispatch(clearSingleActivityTaskError());
    const response = await acquireTokenPopupAndCallAPI((token) => (
      fetch(`${API_ROOT.activityTasks}`, {
        method: "POST",
        body: JSON.stringify(activityTaskObj),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        }
      })
    ));
    const { status } = response;
    if (status === 400) {
      const errorMsg = await response.text();
      dispatch(gotSingleActivityTaskError(errorMsg));
    }
    else {
      dispatch(getActivityTasks(workOrderId));
    }
  } catch (error) {
    console.error(error);
  }
};

const initialState = {
  workOrders: [],
  singleWorkOrder: null,
  singleWorkOrderPaid: null,
  activityTasks: [],
  singleActivityTask: null,
  singleActivityTaskError: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GOT_WORK_ORDERS: {
      const { workOrders } = action;
      return { ...state, workOrders };
    }
    case GOT_SINGLE_WORK_ORDER: {
      const { singleWorkOrder } = action;
      return { ...state, singleWorkOrder };
    }
    case GOT_SINGLE_WORK_ORDER_PAID: {
      const { singleWorkOrderPaid } = action;
      return { ...state, singleWorkOrderPaid };
    }
    case GOT_ACTVITY_TASKS: {
      const { activityTasks } = action;
      return { ...state, activityTasks };
    }
    case GOT_SINGLE_ACTIVITY_TASK: {
      const { singleActivityTask } = action;
      return { ...state, singleActivityTask };
    }
    case GOT_SINGLE_ACTIVITY_TASK_ERROR: {
      const { message } = action;
      return { ...state, singleActivityTaskError: message };
    }
    case CLEAR_SINGLE_ACTIVITY_TASK_ERROR: {
      return { ...state, singleActivityTaskError: null };
    }
    case CLEAR_ACTIVITY_TASKS: {
      return { ...state, activityTasks: [] };
    }
    default:
      return state;
  }
}
