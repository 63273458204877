import { referenceDataByCategory } from '../api_endpoints';
import { acquireTokenPopupAndCallAPI } from '../authentication';

const GOT_CODES = 'GOT_CODES';

const gotCodes = (codes) => ({
  type: GOT_CODES,
  codes
});

export const getCodes = () => async (dispatch) => {
  try {
    const response = await acquireTokenPopupAndCallAPI((token) => (
      fetch(
        `${referenceDataByCategory('Close & Delay Code')}`,
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
    ));
    const codes = await response.json();
    dispatch(gotCodes(codes));
  }
  catch (error) {
    console.error(error);
  }
};


const initialState = {
  codes: [],
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GOT_CODES: {
      const { codes } = action;
      return { ...state, codes };
    }
    default:
      return state;
  }
}
